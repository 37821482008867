import { Component, OnInit, ViewChild } from '@angular/core';

import { hasValidAzureToken, setAWSAssertion, setAWSSession, clearAllAWSSessions } from 'src/utils/storageHelper'
import { TabInfo, VMListTabInfoAzure, VMListTabInfoAWS } from 'src/app/common-components/tabs/TabInfo';
import { AwsApiService } from 'src/app/aws/aws-api/aws-api.service';
import { getAvailableAccountsAndRoles, AWSAccountInfo, AWSAccountCredentials, AWSRoleInfo } from 'src/app/aws/aws-auth/aws-auth';
import { environment } from 'src/environments/environment'
import { CloseTabEvent } from 'src/app/events';

@Component({
  selector: 'app-dashboard-page',
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.css']
})
export class DashboardPageComponent implements OnInit {
  @ViewChild('tabGroup') public tabGroup;  

  vmTabs: TabInfo[] = []

  constructor(private awsAPI: AwsApiService) { }

  ngOnInit() {
    this.loadAzureTabs();
    this.loadAWSTabs();
  }

  onCloseTab(evt: CloseTabEvent) {
    this.vmTabs = this.vmTabs.filter(t => t != evt.tab.tabInfo);

    if (evt.tab.active) {
      setTimeout(() => {
        if (this.tabGroup)
          this.tabGroup.selectFirstTab();
      }, 10)
    }
  }

  loadAzureTabs() {
    if (!hasValidAzureToken())
      return;

    // Prod Tab
    if (environment.Azure.PROD_SUB_IDS.length > 0) {
      this.vmTabs.push(new VMListTabInfoAzure("Azure Production", environment.Azure.PROD_SUB_IDS));
    }

    // DevTest Tab
    if (environment.Azure.DEV_SUB_IDS.length > 0) {
      this.vmTabs.push(new VMListTabInfoAzure(environment.EnvName == "sandbox" ? "Azure Sandbox" : "Azure DevTest", environment.Azure.DEV_SUB_IDS));
    }
  }

  async loadAWSTabs() {
    if (environment.AWS.DEV_CREDS)
      this.loadDevAWSTabs();
    else
      this.loadSSOAWSTabs();    
  }

  async loadSSOAWSTabs() {
    var errorMessage;
    var includeLoginLink = false;
    var awsVMs = [];
    try {
      var assertion = await this.awsAPI.getSAMLAssertion();
      setAWSAssertion(assertion)
      var accountInfoList = getAvailableAccountsAndRoles(assertion);
      if (accountInfoList && accountInfoList.length > 0) {
        accountInfoList.forEach(info => {
          awsVMs.push(new VMListTabInfoAWS(`AWS - ${info.accountName}`, info));
        });
      } else {
        console.error("Error getting SSO assertion.");
      }
    }
    catch (error) {
      errorMessage = error.status == "sessionError" || error.status == "networkError" ? error.message : "Error: Could not get virtual machines";
      if (error.status == "sessionError")
        includeLoginLink = true;
      console.error(error.message)
    }

    if (awsVMs.length > 0) {
      this.vmTabs = this.vmTabs.concat(awsVMs);
    } else {
      // Clear any existing sessions
      clearAllAWSSessions();
      this.vmTabs.push(new VMListTabInfoAWS("AWS", null, errorMessage, includeLoginLink));
    }
  }

  async loadDevAWSTabs() {

    clearAllAWSSessions();

    var accountID = "987654";
    var accountInfo = new AWSAccountInfo(
        accountID,
        "Dev Account",
        [
          new AWSRoleInfo(
            "",
            "",
            "Developer"
          )
        ]
    );

    var creds = new AWSAccountCredentials(accountID, accountInfo.roles[0], {
      AccessKeyId: environment.AWS.DEV_CREDS["accessKeyId"],
      SecretAccessKey: environment.AWS.DEV_CREDS["secretAccessKey"],
    }, new Date("12/12/3000"));

    setAWSSession(creds);

    this.vmTabs.push(new VMListTabInfoAWS("AWS - Dev", accountInfo));
  }
}
