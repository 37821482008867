import { Input, EventEmitter, Component, ViewChild } from '@angular/core';

import { AzureApiService } from 'src/app/azure/azure-api/azure-api.service';
import { AwsApiService } from 'src/app/aws/aws-api/aws-api.service';
import { StargateApiService } from 'src/app/stargate-api/stargate-api.service';
import { AlertBroadcastService } from 'src/app/common-components/alert-modal/alert-broadcast.service';
import { VMStatusUpdateEvent } from 'src/app/events';
import { searchForValue } from 'src/utils/misc';
import { stopAWSVMAndPerformAction } from 'src/app/aws/aws-mic/aws-utils';
import { stopAzureVMAndPerformAction } from 'src/app/azure/azure-misc/azure-utils';
import { CloudType, VMState } from 'src/app/constants';

@Component({
  selector: 'app-create-snapshot',
  templateUrl: './create-snapshot.component.html',
  styleUrls: ['./create-snapshot.component.css']
})
export class CreateSnapshotComponent {
  @Input() onStatusChanged: EventEmitter<VMStatusUpdateEvent>;
  @Input() requestMonitorStatusChange: EventEmitter<VMStatusUpdateEvent>; 
  @ViewChild('content') public contentModal;
  selectedDisks: any[] = [];
  cloudType: CloudType;
  vmState: VMState;
  initialValidation: boolean = true;
  isLoading: boolean = false;
  creatingSnapshots: boolean = false;
  powerOff: boolean = true;
  vmID: string;
  maxSnapshotsPerDisk: number = 3;

  // Random ID for radio button groups
  componentID: number = Math.floor(Math.random() * 99999);

  // Azure
  subscriptionID: string;
  resourceGroup: string;
  vmName: string;
  location: string;

  // AWS
  awsAccountID: string;

  // Snapshots from EBS volumes will perpetuate the volume's tags, and this is the list of things we want to keep
  allowedTags: string[] = ["ApplicationName", "Business", "CostCenter", "DRTier", "Environment", "PrimaryContact", "RBACTeam", "Role", "Sector","Workgroup","Name"];

  constructor(
    private azureService: AzureApiService,
    private awsAPI: AwsApiService,
    private stargateAPI: StargateApiService,
    private alertBroadcast: AlertBroadcastService) { }

  showAzure(selectedDisks: any[], vmState: VMState, vmID: string, subscriptionID: string, resourceGroup: string, vmName: string, location: string) {
    this.cloudType = CloudType.AZURE;
    this.vmState = vmState;
    this.vmID = vmID;
    this.selectedDisks = this.cloneDisks(selectedDisks);
    this.subscriptionID = subscriptionID;
    this.resourceGroup = resourceGroup;
    this.vmName = vmName;
    this.location = location;

    this.getNumSnapshotsForAzureDisks();
    this.initForm();
  }

  showAWS(selectedDisks: any[], vmState: VMState, awsAccountID: string, vmID: string) {
    this.cloudType = CloudType.AWS;
    this.vmState = vmState;
    this.selectedDisks = this.cloneDisks(selectedDisks);
    this.awsAccountID = awsAccountID;
    this.vmID = vmID;

    this.getNumSnapshotsForAWSDisks();
    this.initForm();   
  }

  cloneDisks(disks: any[]) {
    if (!disks)
      return [];
    return JSON.parse(JSON.stringify(disks));
  }

  getNumSnapshotsForAWSDisks() {
    this.isLoading = true;
    var counts = {};
    this.awsAPI.getSnapshots(this.awsAccountID, this.selectedDisks.map(disk => disk.id))
      .then(resp => {
        if (resp && resp["Snapshots"]) {
          resp["Snapshots"].forEach(snapshot => {
            if (!counts[snapshot.VolumeId])
              counts[snapshot.VolumeId] = 0;
            counts[snapshot.VolumeId]++;
          })
        }        

        this.selectedDisks.forEach(disk => {
          disk.snapshotCount = counts[disk.id] != null ? counts[disk.id] : 0;
          disk.maxSnapshots = disk.snapshotCount >= this.maxSnapshotsPerDisk;
        });        

        this.isLoading = false;
      });
  }

  getNumSnapshotsForAzureDisks() {
    this.isLoading = true;
    var counts = {};
    this.azureService.getSnapshots(this.subscriptionID, this.resourceGroup)
      .subscribe(resp => {
        if (resp && resp["value"]) {
          resp["value"].forEach(snapshot => {
            var diskId = (searchForValue(snapshot, ["properties", "creationData", "sourceResourceId"]) || "").toLowerCase();
            if (!counts[diskId])
              counts[diskId] = 0;
            counts[diskId]++;
          })
        }

        this.selectedDisks.forEach(disk => {
          var diskId = disk.id.toLowerCase();
          disk.snapshotCount = counts[diskId] != null ? counts[diskId] : 0;
          disk.maxSnapshots = disk.snapshotCount >= this.maxSnapshotsPerDisk;
        });

        this.isLoading = false;
      });
  }

  // Return true if at least 1 disk is not at the max snapshot limit
  hasValidDisks() {
    var validDisk = false;
    this.selectedDisks.forEach(disk => {
      if (!disk.maxSnapshots)
        validDisk = true;
    });
    return validDisk;
  }

  initForm() {
    this.powerOff = true;
    this.initialValidation = true;

    this.stargateAPI.getMaxSnapshotsPerDisk(this.cloudType, this.awsAccountID)
      .subscribe(resp => {
        this.maxSnapshotsPerDisk = resp && resp["maxSnapshotsPerDisk"] ? resp["maxSnapshotsPerDisk"] : 3;
      });

    this.contentModal.show();
  }

  isVMRunning() {
    return this.vmState == VMState.RUNNING;
  }

  validDescriptions() {
    this.initialValidation = false;
    var isValid = true;
    var patt = new RegExp("[^A-Za-z0-9]");

    var unique = true;
    var names= {};

    this.selectedDisks.forEach(disk => {
      if (!disk.maxSnapshots && (!disk.description || disk.description.length > 60 || patt.test(disk.description))) {
        isValid = false;
        disk.isValid = false;        
      } else {
        disk.isValid = true;
      }

      if (disk.isValid) {
        if (names[disk.description]) {
          unique = false;
          isValid = false;
          disk.isValid = false;
        } else {
          names[disk.description] = true;
        }
      }
    })

    if (!unique)
      this.alertBroadcast.broadcastFail("Error: Snapshot descriptions must be unique.");
    
    return isValid;
  }

  createSnapshot() {
    if (this.validDescriptions())
      this.createSnapshotConfirm();
  }

  createSnapshotConfirm() {
    var diskNames = this.selectedDisks.map(d => d.name);
    if (this.cloudType == 0) {
      this.alertBroadcast.broadcastConfirm(`Are you sure that you want to create a snapshot for following disk(s)?: ${diskNames.join(", ")}`, () => {
        this.createAzureSnapshot();
      });
    }
    else if (this.cloudType ==1) {
      this.alertBroadcast.broadcastConfirm(`Are you sure that you want to create a snapshot for the following EBS volume(s)?: ${diskNames.join(", ")}`, () => {
        this.createAWSSnapshot();
      });
    }
  }

  async createAzureSnapshot() {
    this.isLoading = true;
    this.creatingSnapshots = true;
    try {
      var validDisks = this.selectedDisks.filter(d => !d.maxSnapshots);
      var snapshotAction = async () => {
        await Promise.all(validDisks.map(d => this.createSnapshotForAzureDisk(d)));
      };

      if (this.powerOff && this.isVMRunning()) {
        await stopAzureVMAndPerformAction(this.azureService, this.vmID, this.subscriptionID, this.resourceGroup, 
          this.vmName, this.vmState, this.onStatusChanged, this.requestMonitorStatusChange, snapshotAction);
      } else {
        await snapshotAction();
      }

      this.isLoading = false;
      this.creatingSnapshots = false;
      this.contentModal.hide();
      this.alertBroadcast.broadcastSuccess(`Success! Snapshot(s) have been created in Resource Group ${this.resourceGroup}. Please allow for a few minutes for it to be created by Azure.`);
    }
    catch (err) {
      console.error(err);
      this.alertBroadcast.broadcastFail(`Something went wrong: ${err.message}`);
    }
    finally {
      this.isLoading = false;
    }
  }

  createSnapshotForAzureDisk(disk: any, ) {
    return this.azureService.createSnapshot(this.subscriptionID, this.resourceGroup, disk.name, this.location, `${disk.description}_${this.createDateString()}`).toPromise();
  }

  async createAWSSnapshot() {
    this.isLoading = true;
    this.creatingSnapshots = true;
    try {
      var validDisks = this.selectedDisks.filter(d => !d.maxSnapshots);
      var snapshotAction = async () => {
        await Promise.all(validDisks.map(d => this.createSnapshotForAWSDisk(d)))
      };

      if (this.powerOff && this.isVMRunning()) {
        await stopAWSVMAndPerformAction(this.awsAPI, this.awsAccountID, this.vmID, this.vmState, this.onStatusChanged, this.requestMonitorStatusChange, snapshotAction);
      } else {
        await snapshotAction();
      }

      this.isLoading = false;
      this.creatingSnapshots = false;
      this.contentModal.hide();
      this.alertBroadcast.broadcastSuccess(`Success! You have created snapshots for these EBS volumes: ${this.selectedDisks.map(d => d.name).join(", ")}`);
    }
    catch (err) {
      console.error(err);
      this.alertBroadcast.broadcastFail(`Something went wrong: ${err.message}`);
    }
    finally {
      this.isLoading = false;
    }
  }

  createSnapshotForAWSDisk(disk: any) {
    return new Promise(async (resolve, reject) => {
      try {
        var getVolumesResponse = await this.awsAPI.getVolumes(this.awsAccountID, [disk.id]);
        var volumeTags = searchForValue(getVolumesResponse, ["0", "Tags"]);
        if (volumeTags) {
          volumeTags = volumeTags.filter(tag => this.allowedTags.indexOf(tag.Key) != -1);
        }
        
        volumeTags.push({Key: "DeploymentMethod", Value: "Stargate"})
        var nameTag = volumeTags.find(tag => tag.Key == "Name");
        nameTag.Value = nameTag.Value + '_' + getVolumesResponse[0].Attachments[0].Device

        await this.awsAPI.createSnapshot(this.awsAccountID, disk.id, volumeTags, disk.description);
        resolve()
      }
      catch (e) {
        console.error(e)
        reject(e)
      }
    });
  }

  createDateString() {
    var zone = new Date().toLocaleTimeString('en-us',{timeZoneName:'short'}).split(' ')[2];
    var d = new Date();
    var datestring = ("0" + d.getMinutes()).slice(-2)+ "-" + ("0" + d.getHours()).slice(-2) + "_" +  ("0"+(d.getMonth()+1)).slice(-2) + "-" + ("0" + d.getDate()).slice(-2) + "-" + 
    d.getFullYear().toString().substr(2,2) + "_" + zone;
    return datestring;
  }  
}
