/**
 * The AlertBroadcastService is used to display the alert dialog.
 * The application only has 1 instance of the alert dialog and it lives in 
 * the root app.component. That component is listening for broadcasts from
 * somewhere else in the application that tells it to show the dialog. This
 * allows any component or service to show an alert without having to have
 * a specific reference to the alert dialog UI element.
 */

import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AlertBroadcastService {
  observable: Observable<AlertEvent>;
  observer: Observer<AlertEvent>;

  constructor() {
    this.observable = Observable.create(observer => {
      this.observer = observer;
    });
  }

  broadcast(event: AlertEvent) {
    this.observer.next(event);
  }

  broadcastSuccess(message: string, onClose: any = null) { this.broadcast(AlertEvent.Success(message, onClose)); }
  broadcastFail(message: string, onClose: any = null) { this.broadcast(AlertEvent.Fail(message, onClose)); }
  broadcastInfo(message: string, onClose: any = null) { this.broadcast(AlertEvent.Info(message, onClose)); }
  broadcastWarning(message: string, onClose: any = null) { this.broadcast(AlertEvent.Warning(message, onClose)); }
  broadcastConfirm(message: string, onClose: any = null) { this.broadcast(AlertEvent.Confirm(message, onClose)); }
  broadcastWithList(message: string, list: string[], alertType: AlertType, onClose: any = null) { this.broadcast(AlertEvent.MessageList(message, list, alertType, onClose)); };

  subscribe(callback: any) {
    this.observable.subscribe((event => callback(event)))
  }
}

export enum AlertType {
  SUCCESS,
  FAIL,
  INFO,
  WARNING,
  CONFIRM
}

export class AlertEvent {
  alertType: AlertType;
  message: string;
  list: string[];
  onClose: any;

  private constructor(alertType: AlertType, message: string, onClose: any = null) {
    this.alertType = alertType;
    this.message = message;
    this.onClose = onClose;
  }

  static Success(message: string, onClose: any = null) { return new AlertEvent(AlertType.SUCCESS, message, onClose); }
  static Fail(message: string, onClose: any = null)  { return new AlertEvent(AlertType.FAIL, message, onClose); }
  static Info(message: string, onClose: any = null)  { return new AlertEvent(AlertType.INFO, message, onClose); }
  static Warning(message: string, onClose: any = null) { return new AlertEvent(AlertType.WARNING, message, onClose); }
  static Confirm(message: string, onClose: any = null)  { return new AlertEvent(AlertType.CONFIRM, message, onClose); }

  static MessageList(message: string, list: string[], alertType: AlertType, onClose: any = null) {
    var alertEvent = new AlertEvent(alertType, message, onClose);
    alertEvent.list = list;
    return alertEvent;
  }
}