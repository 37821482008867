import { Output, Input, Component, ViewChild, EventEmitter } from '@angular/core';

import { VMPermissions } from 'src/app/dashboard/VMPermissions';
import { AddScheduleEvent } from 'src/app/events';

@Component({
  selector: 'app-add-schedule',
  templateUrl: './add-schedule.component.html',
  styleUrls: ['./add-schedule.component.css']
})
export class AddScheduleComponent {
  @Output() scheduleAdded = new EventEmitter<AddScheduleEvent>();
  @Input() allowedActions: VMPermissions;

  @ViewChild('timePicker') public timePicker;
  @ViewChild('validator') public validator;
  validationText: string;
  scheduleAction: string = "";

  public requiredItemsHtml: string = "";

  daysOfWeek: object[] = [
    { label: "Mon", value: 0, isSelected: false },
    { label: "Tues", value: 1, isSelected: false },
    { label: "Wed", value: 2, isSelected: false },
    { label: "Thurs", value: 3, isSelected: false },
    { label: "Fri", value: 4, isSelected: false },
    { label: "Sat", value: 5, isSelected: false },
    { label: "Sun", value: 6, isSelected: false }
  ]

  addSchedule() {
    var selectedDays = this.getSelectedDays();    

    if (this.isValidSelection(selectedDays)) {
      var schedule = selectedDays.map(dayOfWeek => {
        return {
          timeReadable: {
            day: this.getDayString(dayOfWeek),
            ...this.timePicker.getReadable()
          },
          timeUtc: this.getTimeUTC(dayOfWeek),
          action: this.scheduleAction
        }
      })

      this.reset();
      this.scheduleAdded.emit(new AddScheduleEvent(schedule));
    }    
  }

  getTimeUTC(dayOfWeek: number) {
    var utcTime = this.timePicker.getUTCTime();

    //if our utc time adjustment pushes us out of bounds for how we count weeks, reset the counter
    var utcDay = dayOfWeek + utcTime.dayOffset
    if(utcDay > 6){
      utcDay = 0
    }

    return {
      totalMinutes: utcTime.totalMinutes,
      dayOfWeek: utcDay
    }
  }

  getSelectedDays() {
    var selectedDays = [];

    this.daysOfWeek.forEach(day => {
      if (day["isSelected"])
        selectedDays.push(day["value"]);
    });

    return selectedDays;
  }

  isValidSelection(selectedDays: object[]) {
    var valid: boolean = true;
    this.requiredItemsHtml = "";
    
    if (selectedDays.length == 0) {
      this.requiredItemsHtml += "<li>Day(s) Of Week</li>";
      valid = false;
    }
    if (!this.scheduleAction) {
      this.requiredItemsHtml += "<li>Actions</li>";
      valid = false;
    }

    if (valid)
      this.validator.hide();
    else
      this.validator.show();
    return valid;
  }

  reset() {
    this.daysOfWeek.forEach(day => day["isSelected"] = false);
    this.timePicker.reset();
    this.scheduleAction = "";
    this.validator.hide();
  }

  private getDayString(day: number) {
    switch (day) {
      case 0:
        return "Monday";
      case 1:
        return "Tuesday";
      case 2:
        return "Wednesday";
      case 3:
        return "Thursday";
      case 4:
        return "Friday";
      case 5:
        return "Saturday";
      case 6:
        return "Sunday";
    }
    return "";
  }
}
