import { Component, ViewChild, Input, EventEmitter } from '@angular/core';

import { AzureApiService } from 'src/app/azure/azure-api/azure-api.service';
import { AwsApiService } from 'src/app/aws/aws-api/aws-api.service';
import { VMStatusUpdateEvent, ReloadVMEvent } from 'src/app/events';
import { AlertBroadcastService, AlertType } from 'src/app/common-components/alert-modal/alert-broadcast.service';
import { stopAWSVMAndPerformAction } from 'src/app/aws/aws-mic/aws-utils';
import { searchForValue, formatDate } from 'src/utils/misc';
import { CloudType, VMState } from 'src/app/constants';

@Component({
  selector: 'app-view-snapshots',
  templateUrl: './view-snapshots.component.html',
  styleUrls: ['./view-snapshots.component.css']
})
export class ViewSnapshotsComponent {
  @ViewChild('content') public contentModal;
  @Input() onStatusChanged: EventEmitter<VMStatusUpdateEvent>;
  @Input() requestMonitorStatusChange: EventEmitter<VMStatusUpdateEvent>; 
  @Input() onReloadVMEvent = new EventEmitter<ReloadVMEvent>();

  disk: any;
  isLoading: boolean = true;
  restoringSnapshot: boolean = false;
  cloudType: CloudType;
  snapshots: any[] = [];
  diskList: any[] = [];
  vmState: VMState;

  // Azure
  subscriptionId: string;
  resourceGroup: string;
  vmName: string;

  // AWS
  accountID: string;
  volumeId: string;
  vmID: string;
  availabilityZone: string;

  constructor( 
    private azureService: AzureApiService,
    private awsAPI: AwsApiService,
    private alertBroadcast: AlertBroadcastService 
  ) { }

  showAzure(disk: any, subscriptionID: string, resourceGroup: string, diskList: any, vmName: string, vmState: VMState) {
    this.cloudType = CloudType.AZURE;
    this.disk = disk;
    this.subscriptionId = subscriptionID;
    this.resourceGroup = resourceGroup;
    this.snapshots = [];
    this.diskList = diskList;
    this.vmName = vmName;
    this.vmState = vmState;

    this.getAzureSnapShots();
    this.contentModal.show();
  }

  showAWS(disk: any, accountID: string, diskList: any, vmID: string, availabilityZone, vmState: VMState) {
    this.cloudType = CloudType.AWS;
    this.disk = disk;
    this.accountID = accountID;
    this.snapshots = [];
    this.diskList = diskList.filter(d => d.State != "completed");
    this.vmID = vmID;
    this.availabilityZone = availabilityZone;
    this.vmState = vmState;

    this.getAWSSnapshots();
    this.contentModal.show();
  }
  
  getAzureSnapShots() {
    this.azureService.getSnapshots(this.subscriptionId, this.resourceGroup)
      .subscribe(resp => {
        if (resp["value"]) {
          resp["value"].forEach(snapshot => {
            var diskName = searchForValue(snapshot, ["properties", "creationData"]);
            diskName = diskName['sourceResourceId'];
            diskName = diskName.split('disks/')[1];
            if(diskName == this.disk['name']) {
              this.snapshots.push({
                id: snapshot.id,
                name: snapshot.name,
                createdDate: formatDate(searchForValue(snapshot, ["properties", "timeCreated"])),
                location: snapshot.location,
                diskName: diskName
              })
            }
          });
        }
        this.isLoading = false;
      });
  }

  async getAWSSnapshots() {
    this.volumeId = this.disk['id'];
    var viewAWSSnapshotsResponse = await this.awsAPI.getSnapshots(this.accountID, [this.volumeId]);
    if (viewAWSSnapshotsResponse != undefined) {
      viewAWSSnapshotsResponse['Snapshots'].forEach(snapshot => {
        if (snapshot.State == "completed") {
          this.snapshots.push({
            id: snapshot.SnapshotId,
            name: snapshot.Description,
            createdDate: formatDate(snapshot.StartTime),
            tags: snapshot.Tags
          })
        }
      });
      this.isLoading = false;
    }
  }

  async deleteAzureSnapshot(snapshot: any) {
    try {
      await this.azureService.deleteSnapshot(this.subscriptionId, this.resourceGroup, snapshot['name']).toPromise();
      this.snapshots = this.snapshots.filter(s => snapshot != s);
      this.alertBroadcast.broadcastSuccess(`Success! You've deleted snapshot ${snapshot['name']}`);
    }
    catch (err) {
      console.error(err);
      this.alertBroadcast.broadcastFail(`Something went wrong: ${err.message}`);
    }
  }

  async deleteAWSSnapshot(snapshot: any) {
    try {
      await this.awsAPI.deleteSnapshot(this.accountID, snapshot['id']);
      this.snapshots = this.snapshots.filter(s => snapshot != s);
      this.alertBroadcast.broadcastSuccess(`Success! You've deleted snapshot ${snapshot['name']}`, () => {});
    }
    catch (err) {
      console.error(err);
      this.alertBroadcast.broadcastFail(`Something went wrong: ${err.message}`);
    }
  }

  deleteSnapshot(snapshot: any) {
    if (this.cloudType == CloudType.AZURE) {
      this.alertBroadcast.broadcastConfirm(`Are you sure you want to delete snapshot "${snapshot.name}"?`, () => {
        this.deleteAzureSnapshot(snapshot);
      });
    }
    else if (this.cloudType == CloudType.AWS) {
      this.alertBroadcast.broadcastConfirm(`Are you sure you want to delete snapshot "${snapshot.name}"?`, () => {
        this.deleteAWSSnapshot(snapshot);
      });
    }
  }

  async restoreAzureSnapshot(snapshot: any) {
    var diskNameToCreate = this.createDateString();
    this.isLoading = true;
    this.restoringSnapshot = true;

    try {
      var createDataDiskResp = await this.azureService.createDataDiskFromSnapshot(this.subscriptionId, this.resourceGroup, snapshot['name'], diskNameToCreate, snapshot['location']).toPromise();
      if (createDataDiskResp['status'] == 202) {
        var getVMResp = await this.azureService.getVM(this.subscriptionId, this.resourceGroup, this.vmName).toPromise();
        var dataDisksJson = getVMResp['properties']['storageProfile']['dataDisks'];
        var diskToRemove = this.azureGetDiskToRemove(dataDisksJson, this.disk['name']);
        var lun = diskToRemove['lun'];
        dataDisksJson = dataDisksJson.filter(d => d != diskToRemove);

        var detachDiskResp = await this.azureService.detachDisk(this.subscriptionId, this.resourceGroup, this.vmName, dataDisksJson).toPromise();
        if (detachDiskResp['status'] == 200) {
          await this.attachAzureDisk(diskNameToCreate, lun);
        }

        var promises = this.snapshots.map(s => this.deleteAzureSnapshots(s['name']));
        await Promise.all(promises);

        this.onReloadVMEvent.emit(new ReloadVMEvent(this.vmID));

        this.alertBroadcast.broadcastSuccess(`Success! You've restored disk ${this.disk['name']}`, () => {
          this.contentModal.hide();
        });
      }
      else {
        this.alertBroadcast.broadcastFail(`Something went wrong. Nothing has been created.`);
      }
    }
    catch (err) {
      console.error(err);
      this.alertBroadcast.broadcastFail(`Something went wrong. Nothing has been created. ${err.message}`);
    }
    finally {
      this.isLoading = false;
      this.restoringSnapshot = false;
    }
  }

  deleteAzureSnapshots(i) {
    return this.azureService.deleteSnapshot(this.subscriptionId, this.resourceGroup, i).toPromise();
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async attachAzureDisk(diskNameToCreate: string, lun: string) {
    var success = false;
    await this.delay(30000);
    // attach data disk
    if (lun != '' && lun != undefined) {
      var resp = await this.azureService.attachDisk(this.subscriptionId, this.resourceGroup, this.vmName, lun, diskNameToCreate).toPromise();
      if (resp['status'] == 200) {
        success = true;
        await this.deleteAzureDisk();
      }
    }
    // attach os disk
    else {
      var resp = await this.azureService.attachOSDisk(this.subscriptionId, this.resourceGroup, this.vmName, diskNameToCreate).toPromise();
      if (resp['status'] == 200) {
        success = true;
        await this.deleteAzureDisk();
      }
    }

    if (!success)
      this.alertBroadcast.broadcastFail(`Something went wrong. Disk ${diskNameToCreate} has not been restored.`);
  }

  async deleteAzureDisk() {
    // don't await on a fixed delay number - we can't ensure the disk will be deleted after a minute
    // like what if we had a huge disk to delete or the API becomes slow?
    await this.delay(60000);
    // check to see if osdisk is still attached to VM before deleting 
    // we can asynchronous delete the disk if its a data volume/mount, but if its an OS disk, we can't do it
    var vmResponse = await this.azureService.getVM(this.subscriptionId, this.resourceGroup, this.vmName).toPromise();
    var osDiskName = (vmResponse != undefined) ? vmResponse["properties"]["storageProfile"]["osDisk"]["name"] : null
    // if the osDisk needs to be deleted off of this existing VM
    if (osDiskName != null && osDiskName == this.disk['name']) {
      // run exponential backoff until the error goes away
      var maxRetries = 5;
      var retryAttempt = 0;
      while (retryAttempt < maxRetries) {
        try {
          var deleteResponse = await this.azureService.deleteDisk(this.subscriptionId, this.resourceGroup, this.disk['name']).toPromise();
          if (deleteResponse['status'] >= 200 && deleteResponse['status'] < 300) {
            this.alertBroadcast.broadcastSuccess(`Deletion of azure disk ${this.disk['name']} has succeeded!`);
            break;
          }
        } catch (error) {
          retryAttempt++;
          await this.delay(Math.pow(2, retryAttempt) * 1000);
        }
        this.alertBroadcast.broadcastFail(`Deletion of azure disk ${this.disk['name']} has failed!`)
      }
    } else { // if this is a normal data mount, we don't have to delay on anything
      // run the response normally as we should
      await this.azureService.deleteDisk(this.subscriptionId, this.resourceGroup, this.disk['name']).toPromise();
      this.alertBroadcast.broadcastSuccess(`Deletion of azure disk ${this.disk['name']} has succeeded!`);
    }
  }

  createDateString() {
    var zone = new Date().toLocaleTimeString('en-us',{timeZoneName:'short'}).split(' ')[2];
    var d = new Date();
    var datestring = ("0" + d.getMinutes()).slice(-2)+ "-" + ("0" + d.getHours()).slice(-2) + "_" +  ("0"+(d.getMonth()+1)).slice(-2) + "-" + ("0" + d.getDate()).slice(-2) + "-" + 
    d.getFullYear().toString().substr(2,2) + "_" + zone;
    return datestring;
  }

  azureGetDiskToRemove(dataDiskJson: any, diskName: string) {
    var diskToRemove: any[] = [];
    for(var i = 0; i < dataDiskJson.length; i++) {
      if (dataDiskJson[i].name == diskName) {
        diskToRemove = dataDiskJson[i];
        break;
      }
    }
    return diskToRemove;
  }

  async restoreAWSSnapshot(snapshot: any, keepSnaps: boolean) {
    this.isLoading = true;
    this.restoringSnapshot = true;

    var snapTags: any = [];

    try {
      //get the associated volumes tags so we can apply them to the snap
      var volInfo = await this.awsAPI.getVolumes(this.accountID,[this.disk.id])

      volInfo[0].Tags.forEach(tag => {
        if (!tag.Key.includes("aws:")){
          snapTags.push(tag);
      } 
      });

      var restoreAction = async () => {
        var createVolumeResponse = await this.awsAPI.createVolume(this.accountID, this.availabilityZone, snapshot['id'], snapTags);
        await this.awsAPI.detachVolume(this.accountID, this.volumeId);

        var volumeToDelete = this.volumeId;
        this.volumeId = createVolumeResponse['VolumeId'];

        await this.delay(30000);
        await this.awsAPI.attachVolume(this.accountID, this.disk['name'], this.vmID, this.volumeId);

        await this.delay(30000);

        //delete old volume and snaps if we don't specify to keep
        if(keepSnaps == false)
          await this.awsAPI.deleteVolume(this.accountID, volumeToDelete);        
      };

      await stopAWSVMAndPerformAction(this.awsAPI, this.accountID, this.vmID, this.vmState, this.onStatusChanged, this.requestMonitorStatusChange, restoreAction);

      if(keepSnaps == false){
        var promises = this.snapshots.map(s => {

          //only delete snapshots created by stargate in this mass delete
          var deploymentMethod = s.tags.find(t => t.Key == "DeploymentMethod");

          if (deploymentMethod != null && deploymentMethod.Value == "Stargate"){
            this.deleteAWSSnapshots(s['id'])
          }
        });
        await Promise.all(promises);
      }

      this.onReloadVMEvent.emit(new ReloadVMEvent(this.vmID));

      this.alertBroadcast.broadcastSuccess(`Success! You've restored disk ${this.disk['name']}`, () => {
        this.contentModal.hide();
      });
    }
    catch (err) {
      console.error(err);
      this.alertBroadcast.broadcastFail(`Something went wrong: ${err.message}`);
    }
    finally {
      this.isLoading = false;
      this.restoringSnapshot = false;
    }
  }

  deleteAWSSnapshots(i) {
    return this.awsAPI.deleteSnapshot(this.accountID, i);
  }

  restoreSnapshot(snapshot: any) {
    if (this.cloudType == CloudType.AZURE) {
      this.alertBroadcast.broadcastConfirm(`Are you sure you want to restore snapshot "${snapshot.name}"?`, () => {
        this.restoreAzureSnapshot(snapshot);
      });
    }
    else if (this.cloudType == CloudType.AWS) {
      //build a list of snapshots that were created in stargate, we use the first index of the list to show the relevant section of the modal
      var effectedSnapshots = ["snapshots",`${this.vmState}`]

      this.snapshots.map(s=>{
        var deploymentMethod = s.tags.find(t => t.Key == "DeploymentMethod")
        if(deploymentMethod != null && deploymentMethod.Value == "Stargate")
          effectedSnapshots.push(s.name)
      });

      this.alertBroadcast.broadcastWithList(`Are you sure you want to restore snapshot "${snapshot.name}"?`,effectedSnapshots,AlertType.CONFIRM, (keepSnaps:boolean = false) => {
        //console.log("Keeping old snapshots: " + keepSnaps)
        this.restoreAWSSnapshot(snapshot, keepSnaps);
      });
    }
  }
}
