import { Output, Component, ViewChild, EventEmitter } from '@angular/core';

import { AwsApiService } from 'src/app/aws/aws-api/aws-api.service';

import { AWSAccountInfo, AWSRoleInfo, setTempCredentialsFromRole } from 'src/app/aws/aws-auth/aws-auth';
import { AWSRoleSelectedEvent } from 'src/app/events';

@Component({
  selector: 'app-role-picker-modal',
  templateUrl: './role-picker-modal.component.html',
  styleUrls: ['./role-picker-modal.component.css']
})
export class RolePickerModalComponent {
  @Output() roleChanged = new EventEmitter<AWSRoleSelectedEvent>();
  @ViewChild('content') public contentModal;

  accountID: string;
  accountName: string;
  roles: AWSRoleInfo[];
  initialRole: AWSRoleInfo;
  currentRole: AWSRoleInfo;

  constructor(private awsAPI: AwsApiService) { }

  show(accountInfo: AWSAccountInfo, currentAWSRole: AWSRoleInfo) {
    this.accountID = accountInfo.accountID;
    this.accountName = accountInfo.accountName;
    this.roles = accountInfo.roles;
    this.initialRole = currentAWSRole;
    this.currentRole = currentAWSRole;
    this.contentModal.show();
  }

  onRoleSelected(role: AWSRoleInfo) {
    this.currentRole = role;
  }

  async onSaveRole() {
    if (this.currentRole == this.initialRole) {
      this.contentModal.hide();
      return;
    }
    else {
      try {
        var success = await setTempCredentialsFromRole(this.awsAPI, this.accountID, this.currentRole);
        if (success)
          this.roleChanged.emit(new AWSRoleSelectedEvent(this.currentRole));
      } catch(e) { console.error(e) }
      finally { this.contentModal.hide(); }
    }
  }
}
