/**
 * Handles all authentication with Azure. This is an Angular
 * component that displays the Login/Logout button on
 * the top navigation bar.
 */

import { Component, OnInit } from '@angular/core';

import { hasValidAzureToken , setAzureToken, clearAzureSession } from 'src/utils/storageHelper';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-azure-auth',
  templateUrl: './azure-auth.component.html',
  styleUrls: ['./azure-auth.component.css']
})
export class AzureAuthComponent implements OnInit {

  isLoggedIn: boolean;

  constructor() { }

  ngOnInit() {
    this.getAuthState();
  }

  onAuthenticateClicked() {
    if (this.isLoggedIn)
      this.logout();
    else
      this.login();
  }

  // Redirect to the login url. Microsoft's login page will redirect back
  // to the application and include the user's token in the url hash after
  // a successful login.
  login() {
    location.href = `${environment.Azure.AUTH_ENDPOINT}/${environment.Azure.TENANT_ID}/oauth2/authorize?client_id=${environment.Azure.APP_CLIENT_ID}&response_type=token&redirect_uri=${encodeURIComponent(environment.Azure.REDIRECT_URL)}&response_mode=fragment&resource=https%3A%2F%2Fmanagement.azure.com`;
  }

  // Redirect to the logout url. After a successful logout, Microsoft's logout
  // page will redirect back to the application.
  logout() {
    clearAzureSession();
    location.href = `${environment.Azure.AUTH_ENDPOINT}/${environment.Azure.TENANT_ID}/oauth2/logout?post_logout_redirect_uri=${encodeURIComponent(environment.Azure.REDIRECT_URL)}`;
  }  

  // Retrieve the users authentication token from the url hash
  getAuthState() {
    var params = this.getHashParams();
    if (params && params["access_token"] && params["token_type"] === "Bearer") {
      setAzureToken(params["access_token"], params["expires_in"])      
    }

    this.isLoggedIn = hasValidAzureToken();
    if (!this.isLoggedIn)
      clearAzureSession();
  }

  // Parse the URL hash and then clear it
  getHashParams() {
    var params = {};
    if (location.hash) {
      var hash = location.hash.substring(1);
      hash.split('&').map(hk => { 
        let temp = hk.split('='); 
          params[temp[0]] = temp[1] 
      });
      this.clearHash();
    }
    return params;
  }

  // Remove the hash from the URL so that the user's
  // authentication token isn't visible
  clearHash() {
    // remove fragment as much as it can go without adding an entry in browser history:
    location.replace("#");

    // slice off the remaining '#' in HTML5:    
    if (typeof history.replaceState == 'function') {
      history.replaceState({}, '', location.href.slice(0, -1));
    }    
  }
}
