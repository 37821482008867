export class VMPermissions {
    start: boolean;
    shutdown: boolean;
    restart: boolean;
    stop: boolean;
    resize: boolean;
    snapshots: boolean;

    private constructor(start: boolean, shutdown: boolean, restart: boolean, stop: boolean, resize: boolean, snapshots: boolean) {
        this.start = start;
        this.shutdown = shutdown;
        this.restart = restart;
        this.stop = stop;
        this.resize = resize;
        this.snapshots = snapshots;
    }

    public static init(allow: boolean) {
        return new VMPermissions(allow, allow, allow, allow, allow, allow);
    }
}