/**
 * UI element that collapses it children and contains a title.
 */
import { Component, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'app-collapsible-div',
  templateUrl: './collapsible-div.component.html',
  styleUrls: ['./collapsible-div.component.css']
})
export class CollapsibleDivComponent {
  @ViewChild('content') public content;
  @Input() iconType: any;
  @Input() label: string;
  @Input() title: string;
  @Input() isCollapsed: boolean = false;
  @Input() includePadding: boolean = false;

  constructor() { }

  toggle() {
    this.isCollapsed = !this.isCollapsed;
    this.content.toggle();
  }
}
