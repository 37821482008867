import { Component, OnInit } from '@angular/core';
import * as moment from "moment-timezone"

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.css']
})
export class TimePickerComponent implements OnInit {

  hours: string[] = [];
  minutes: string[] = [];
  timezones: string[] = [];

  selectedHour: string;
  selectedMinute: string;
  selectedAMPM: string = "AM";
  selectedTimezone: string;

  constructor() { }

  ngOnInit() {
    this.generateAvailableHours();
    this.generateAvailableMinutes();
    this.generateAvailableTimezones();

    // Initialize selections to first option
    this.reset();
  }

  generateAvailableHours() {
    for (var i = 1; i <= 12; i++)
      this.hours.push(i < 10 ? `0${i}` : i.toString());
  }

  generateAvailableMinutes() {
    for (var i = 0; i <= 55; i += 5)
      this.minutes.push(i < 10 ? `0${i}` : i.toString());
  }

  generateAvailableTimezones() {
    this.timezones = ["US/Pacific", "US/Mountain", "US/Central", "US/Eastern"]
  }

  reset() {
    this.selectedHour = this.hours[0];
    this.selectedMinute = this.minutes[0];
    this.selectedTimezone = this.timezones[0];
    this.selectedAMPM = "AM";
  }

  getReadable() {
    return {
      timeOfDay: `${this.selectedHour}:${this.selectedMinute} ${this.selectedAMPM}`,
      timeZone: this.selectedTimezone
    }
  }

  getUTCTime() {
    var time = moment.tz(`${this.selectedHour}:${this.selectedMinute} ${this.selectedAMPM}`, "hh:mm A", this.selectedTimezone);
    var startDay = time.day();
    var utcTime = time.utc();

    return {
      totalMinutes: utcTime.hour() * 60 + utcTime.minute(),
      dayOffset: utcTime.day() - startDay
    }
  }
}
