import {  EventEmitter} from '@angular/core';

import { VMDetailsBase } from './vm-details-base';
import { AzureApiService } from 'src/app/azure/azure-api/azure-api.service';
import { AlertBroadcastService } from 'src/app/common-components/alert-modal/alert-broadcast.service';
import { VMDetailsTabInfo, VMVetworkTabInfo, VMSecurityGroupsTabInfo } from 'src/app/common-components/tabs/TabInfo';
import { VMStatusUpdateEvent, AzureOpenDialogEvent, AzureRequestMonitorStatusEvent } from 'src/app/events';
import { getVMPermissions } from 'src/app/azure/azure-misc/azure-utils';
import { VMStatus } from 'src/app/dashboard/VMStatus';
import { CloudType, VMState } from 'src/app/constants';
import { searchForValue } from 'src/utils/misc';

export class VMDetailsAzure extends VMDetailsBase {
    azureAPI: AzureApiService
    openDialogEvent: AzureOpenDialogEvent;

    constructor(azureAPI: AzureApiService, alertBroadcast: AlertBroadcastService, openDialogEvent: AzureOpenDialogEvent, onStatusChanged: EventEmitter<VMStatusUpdateEvent>,  requestMonitorStatusChange: EventEmitter<VMStatusUpdateEvent>) {
        super(openDialogEvent.vmID, openDialogEvent.vmName, openDialogEvent.vmSize, CloudType.AZURE, alertBroadcast, onStatusChanged, requestMonitorStatusChange);

        this.azureAPI = azureAPI;
        this.openDialogEvent = openDialogEvent;  
        this.cloudType = CloudType.AZURE;  
    }

    async loadDetails() {
        this.vmDetails = await this.azureAPI.getVMDetails(this.openDialogEvent.subscriptionID, this.openDialogEvent.resourceGroup, this.openDialogEvent.vmName).toPromise()
        this.allowedActions = await getVMPermissions(this.azureAPI, this.openDialogEvent.subscriptionID, this.openDialogEvent.resourceGroup, this.openDialogEvent.vmName);
       
        this.updateTabs();
        
        // Wait for status change and update UI
        if (this.vmState == VMState.PENDING) {
            this.requestMonitorStatusChange.emit(new AzureRequestMonitorStatusEvent(
                VMStatus.init(this.vmID, VMState.PENDING, this.vmSize), 
                this.getSubscriptionID(),
                this.getResourceGroup(),
                this.getVMName()));
        }
    }   

    updateTabs() {
        this.vmInfo = new VMDetailsTabInfo(
            "VM Details",
            this.cloudType,
            this.vmDetails,
            this.vmSize
        );
        this.updateNetworkTabs(this.vmDetails);
        this.updateSecurityGroupTab(this.vmDetails);

        var vmStatus = VMStatus.initFromFullAzureDetails(this.vmDetails);
        this.onStatusChanged.emit(new VMStatusUpdateEvent(vmStatus));
        this.vmState = vmStatus.vmState;
    }
    
    private updateNetworkTabs(vmDetails: object) {
        var networkInterfaces = searchForValue(vmDetails, ["properties", "networkProfile", "networkInterfaces"]);
        if (networkInterfaces) {
            var nicList = networkInterfaces.map(nic => nic.id);            
            if (nicList) {
                this.networkInterfacesTabs = nicList.map((nic, i) => {
                    return new VMVetworkTabInfo(
                        "Network" + (nicList.length > 1 ? " " + (i + 1) : ""),
                        this.cloudType,
                        nic
                    );
                });
            }
        } 
    }

    private updateSecurityGroupTab(vmDetails: object) {
        var networkInterfaces = searchForValue(vmDetails, ["properties", "networkProfile", "networkInterfaces"]);
        if (networkInterfaces) {
            var nicList = networkInterfaces.map(nic => nic.id);
            this.securityGroupsTab = new VMSecurityGroupsTabInfo(
                `Security Groups`,
                this.cloudType,
                nicList
            )
        }
    }

    isAzure() { return true; }
    
    startVM() { this.performAction("startVM", "start"); }
    stopVM() { this.performAction("stopVM", "shutdown"); }
    deallocateVM() { this.performAction("deallocateVM", "stop"); }
    restartVM() { this.performAction("restartVM", "restart"); }

    performAction(func: string, action: string) {
        if (!this.allowedActions[action])
            return;

        this.alertBroadcast.broadcastConfirm(`Are you sure that you want to ${action} "${this.openDialogEvent.vmName}"?`, () => {
            this.azureAPI[func](this.openDialogEvent.subscriptionID, this.openDialogEvent.resourceGroup, this.openDialogEvent.vmName)
            .subscribe(resp => {
            if (resp != null) {  

                var asyncUrl = resp.headers.get('Azure-AsyncOperation')
                var retrySeconds = resp.headers.get('Retry-After') || 5

                if (asyncUrl) {
                    this.requestMonitorStatusChange.emit(new AzureRequestMonitorStatusEvent(
                        VMStatus.init(this.vmID, VMState.PENDING, this.vmSize), 
                        this.getSubscriptionID(),
                        this.getResourceGroup(),
                        this.getVMName(),
                        asyncUrl,
                        retrySeconds));
                }          
            }
            });
        })
    }

    getSubscriptionID() { return this.openDialogEvent.subscriptionID; }
    getResourceGroup() { return this.openDialogEvent.resourceGroup; }
    getLocaction() { return this.openDialogEvent.location; }
}