export function searchForValue(baseObj: object, properties: any[]) {
    var value = null;
    properties.forEach((prop, i) => {
        if (baseObj == null)
            return;

        if (i == properties.length - 1) {
            value = baseObj[prop];
            return;
        }

        baseObj = baseObj[prop];
    })
    return value;
}

export function formatDate(isoStr: string) {
    if (isoStr) {
      var date = new Date(isoStr);
      return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;   
    }
    return null;
  }

export function extractValueFromUrl(url: string, label: string) {
    var regExp = new RegExp(`${label}\/(.*?(?:(?!\/).)*)`);
    var matches = regExp.exec(url)
    if (matches.length >= 2)
        return matches[1].toLowerCase();
    return "";
}

export function parseAssertionFromResponse(response: string) {
    var regExp = new RegExp(`name="SAMLResponse" value="(.*?(?:(?!").)*)`);
    var matches = regExp.exec(response);
    if (matches && matches.length >= 2)
        return matches[1];
    return null;
}


export function unsubscribePendingVM(vmID: string, pendingVMs: any) {
    if (pendingVMs[vmID]) {
        pendingVMs[vmID].unsubscribe();
        delete pendingVMs[vmID];
    }
}

export function downloadJSONFile(content: any, fileName: string) {
    var a = document.createElement("a");
    var file = new Blob([JSON.stringify(content, null, "\t")], { type: "application/json" });
    a.href = URL.createObjectURL(file);
    a.download = fileName;
    a.click();
}

export function importJSONFile(fileUploadEvent: any) {
    return new Promise((resolve, reject) => {
        try {
            var fileList = fileUploadEvent.target.files;
            if (fileList && fileList.length > 0) {
                var file = fileList[0];  // Only take first file
                if (file.type == "application/json") {
                    var fr = new FileReader();
                    fr.onload = () => {
                        var result = fr.result.toString();
                        if (result) {
                            var encodedParts = result.split(",");
                            if (encodedParts && encodedParts.length >= 2) {
                                resolve(JSON.parse(atob(encodedParts[1])));
                                return;
                            }
                        }
                        reject({ message: "Error: Could not import file." });
                    };
                    fr.readAsDataURL(file);        
                } else 
                reject({ message: "Error: Invalid File Type." });
            } else
            reject({ message: "Error: Could not import file." });
        } catch (e) {
            reject(e);
        }
    });    
}
