import { Component, OnInit, ViewChild } from '@angular/core';

import { AlertBroadcastService } from 'src/app/common-components/alert-modal/alert-broadcast.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  @ViewChild('alertModal') public alertModal;
  title = 'stargate';

  constructor(private alertBroadcast: AlertBroadcastService) { }

  ngOnInit() {
    this.alertBroadcast.subscribe(event => this.alertModal.show(event) );
  }
}
