// Base class definition of a schedule
// The schedules have uniform properties - but functionality could be expanded in the future
// Design pattern is supposed to mimick vm-details, but for schedules.

import { EventEmitter } from "@angular/core";
import { TabInfo, VMScheduleDetailsTabInfo, VMScheduleEntries } from "src/app/common-components/tabs/TabInfo";
import { AlertBroadcastService } from "src/app/common-components/alert-modal/alert-broadcast.service";
import { CloudType } from "src/app/constants";
import { ScheduleOpenDialogEvent } from "src/app/events";
import { StargateApiService } from "src/app/stargate-api/stargate-api.service";

export class SchedDetailsBase {
    alertBroadcast: AlertBroadcastService;
    // status change events don't seem to exist here because schedules 
    // either exist or they're deleted
    stargateAPI: StargateApiService
    schedInfo: VMScheduleDetailsTabInfo;
    openDialogEvents: ScheduleOpenDialogEvent;

    // reuse member variables from deprec-sched-grid-items
    scheduleID: string;
    vmId: string;
    cloudType: CloudType;
    vmDetails: any;

    // // don't need to initialize schedInfo - it changes dynamically anyway
    // constructor(scheduleID: string, vmId: string, cloudType: CloudType, vmDetails: any, alertBroadcast: AlertBroadcastService) {
    //     this.scheduleID = scheduleID;
    //     this.vmId = vmId;
    //     this.cloudType = cloudType;
    //     this.vmDetails = vmDetails;
    //     this.alertBroadcast = alertBroadcast;
    // }
    constructor(stargateAPI: StargateApiService, alertBroadcast: AlertBroadcastService, openDialogEvents: ScheduleOpenDialogEvent) {
        this.alertBroadcast = alertBroadcast;
        this.openDialogEvents = openDialogEvents;
        
        this.scheduleID = openDialogEvents.scheduleID;
        this.vmId = openDialogEvents.vmId;
        this.cloudType = openDialogEvents.cloudType;
        this.vmDetails = openDialogEvents.vmDetails;

        this.stargateAPI = stargateAPI;
        
    }
    
    async loadDetails() {
        this.scheduleID = this.openDialogEvents.scheduleID
        this.vmId = this.openDialogEvents.vmId;
        this.cloudType = this.openDialogEvents.cloudType;
        this.vmDetails = this.openDialogEvents.vmDetails;
        this.updateTabs();
    }
    
    updateTabs() {
        this.schedInfo = new VMScheduleDetailsTabInfo(
            'Schedule Details',
            this.scheduleID,
            this.vmId,
            this.cloudType,
            this.vmDetails
        );
        // don't exactly need to notify updated states - docs will always be the same
    }

    getScheduleInfoTab() {
        return this.schedInfo;
    }

    getScheduleID() {return this.scheduleID;}
    getVmID() {return this.vmId;}
    getCloudType() {return this.cloudType;}
    getVmDetails() {return this.vmDetails;}


    async deleteSchedule(vmId: string, cloudType: CloudType, awsAccountID: string) {
        this.stargateAPI.forceDeleteScheduleDoc(vmId, cloudType, awsAccountID).subscribe(resp => {
            this.alertBroadcast.broadcastSuccess("Schedule successfully deleted!");
        },
        error => {
            // console.log(`deleteSchedule on ${this.scheduleID} has failed!\nError message:\n${error.message}`);
            this.alertBroadcast.broadcastFail(`Schedule failed to delete!`);
        });

    }
}
