import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { searchForValue, extractValueFromUrl } from 'src/utils/misc';
import { CloudType } from 'src/app/constants';

@Component({
  selector: 'app-search-sched',
  templateUrl: './search-sched.component.html',
  styleUrls: ['./search-sched.component.less']
})
export class SearchSchedComponent implements OnInit {
  // separate component that inherits the behavior of the search component
  // problem is that component works only for vmList -  need to make stripped down version

  @Output() onSearchScheds = new EventEmitter<any[]>();
  @Input() deprecatedSchedules: any[];
  // unknown if we need this - but schedule schemas differ in the mongoDB
  // @Input() cloudType: CloudType;
  
  searchSchedComponentId: number =- 1;
  searchText: string = "";
  searchType: string = "scheduleId";
  
  constructor() { 
    this.searchSchedComponentId = Math.floor((Math.random() * 9999999) + 1);
  }

  ngOnInit() {
    this.clear();
  }

  clear() {
    this.searchText = "";
    this.searchType = "scheduleId";
    this.onSearchScheds.emit(this.deprecatedSchedules);
  }

  onKeydown(event) {
    if (event.key === "Enter") {
      this.onSearchPressed();
    }
  }

  onSearchPressed() {
    if (this.deprecatedSchedules && this.searchText) {
      this.onSearchScheds.emit(this.getSearchResults());
    } else {
      this.onSearchScheds.emit(this.deprecatedSchedules);
    }
  }

  // only include 3 cases for searches based on mongoDB attributes for now
  // schedule-id - universal for 2 cloudTypes
  // VM name - only available for azure, we can probably say VM name/instanceid - backend in the future should accomodate this 
  // instance id - universal for both, but azure has the longest IDs
  getSearchResults() : any[] {
    switch (this.searchType) {
      case "scheduleId" :
        return this.searchScheduleId(this.searchText.toLowerCase());
      case "vmName" :
        return this.searchVmName(this.searchText.toLowerCase());
      case "instanceId" :
        return this.searchInstanceId(this.searchText.toLowerCase());
    }
    return [];
  }

  // search methods
  // unlike the search component, we already have the data as an input so we don't need to make library calls
  searchScheduleId(scheduleID: string) {
    return this.deprecatedSchedules.filter(sched => {
      return sched._id.toLowerCase().indexOf(scheduleID) != -1;
    });
  }

  // Azure Only - unless we add as it a property for AWS VMs in the mongoDB
  searchVmName(VMName: string) {
    return this.deprecatedSchedules.filter(sched => {
      if (sched.cloudType == CloudType.AZURE) {
        return sched.vmDetails.vmName.toLowerCase().indexOf(VMName) != -1;
      }
    });
  }

  searchInstanceId(instanceID: string) {
    return this.deprecatedSchedules.filter(sched => {
      return sched.instanceId.toLowerCase().indexOf(instanceID) != -1;
    });
  }

}
