import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { AzureApiService } from 'src/app/azure/azure-api/azure-api.service';
import { AzureOpenDialogEvent, AWSOpenDialogEvent, VMStatusUpdateEvent, AzureRequestMonitorStatusEvent, AWSRequestMonitorStatusEvent } from 'src/app/events';
import { AWSAccountInfo } from 'src/app/aws/aws-auth/aws-auth';
import { searchForValue, extractValueFromUrl } from 'src/utils/misc'
import { CloudType, VMState } from 'src/app/constants';
import { VMStatus } from 'src/app/dashboard/VMStatus';

@Component({
  selector: 'app-vm-grid-item',
  templateUrl: './vm-grid-item.component.html',
  styleUrls: ['./vm-grid-item.component.css']
})
export class VmGridItemComponent implements OnInit {
  @Input() vmInfo: any;
  @Input() awsAccountInfo: AWSAccountInfo;
  @Input() cloudType: CloudType;
  @Input() healthStatus: any;
  @Output() openDialogAzure = new EventEmitter<AzureOpenDialogEvent>();
  @Output() openDialogAWS = new EventEmitter<AWSOpenDialogEvent>();
  @Output() onStatusChanged = new EventEmitter<VMStatusUpdateEvent>();
  @Output() requestMonitorStatusChange = new EventEmitter<VMStatusUpdateEvent>();

  vmID: string;
  name: string;
  resourceGroup: string;
  subscriptionId: string;
  location: string;
  imageType: string;
  osType: string;
  vmSize: string;

  statusColor: string;

  constructor(
    private azureAPI: AzureApiService) {}

  ngOnInit() {
    if (this.cloudType == CloudType.AZURE)
      this.initializeForAzure();    
    else if (this.cloudType == CloudType.AWS)
      this.initializeForAWS();
  }

  onRecieveNewStatus(vmStatus: VMStatus) {
    this.vmInfo.vmStatus = vmStatus;
    this.updateStatusColorForVMState(vmStatus.vmState);

    if (vmStatus.vmSize != null)
      this.vmSize = vmStatus.vmSize;
  }

  updateStatusColorForVMState(vmState: VMState) {
    switch(vmState)
    {
      case VMState.RUNNING:
        this.statusColor = "darkgreen";
        break;

      case VMState.PENDING:
        this.statusColor = "orange";
        break;      
      
      case VMState.STOPPED:
        this.statusColor = "darkred";
        break;

      case VMState.DEALLOCATED:
        this.statusColor = "#926e6e";
        break;
    }
  }

  viewDetails() {
    if (this.cloudType == CloudType.AZURE)
      this.openDialogAzure.emit(new AzureOpenDialogEvent(this.vmID, this.name, this.resourceGroup, this.subscriptionId, this.vmSize, this.location));
    else if (this.cloudType == CloudType.AWS)
      this.openDialogAWS.emit(new AWSOpenDialogEvent(this.vmID, this.name, this.vmSize));
  }

  /************** AZURE **************/
  initializeForAzure() {
    this.updateAzureProperties();

    if (!this.vmInfo.vmStatus) {
      // Set Status
      this.azureAPI.getVMInstanceView(this.subscriptionId, this.resourceGroup, this.name)
        .subscribe(instanceView => {

          this.vmInfo.vmStatus = VMStatus.initFromAzureInstanceView(this.vmID, instanceView, this.vmSize);
          if (this.vmInfo.vmStatus.vmState == VMState.PENDING) // Wait for status change and update UI
            this.requestMonitorStatusChange.emit(new AzureRequestMonitorStatusEvent(this.vmInfo.vmStatus, this.subscriptionId, this.resourceGroup, this.name));
          else
            this.onStatusChanged.emit(new VMStatusUpdateEvent(this.vmInfo.vmStatus));
        });
    } else {
      this.updateStatusColorForVMState(this.vmInfo.vmStatus.vmState);
      this.vmSize = this.vmInfo.vmStatus.vmSize;
    }
  }

  updateAzureProperties() {
    this.vmID = (this.vmInfo["id"] || "").toLowerCase();
    this.name = this.vmInfo["name"];
    this.resourceGroup = extractValueFromUrl(this.vmInfo["id"], "resourceGroups");
    this.subscriptionId = extractValueFromUrl(this.vmInfo["id"], "subscriptions");
    this.location = this.vmInfo["location"];

    this.vmSize = searchForValue(this.vmInfo, ["properties", "hardwareProfile", "vmSize"]);
    this.osType = searchForValue(this.vmInfo, ["properties", "storageProfile", "osDisk", "osType"]);
    this.imageType = searchForValue(this.vmInfo, ["properties", "storageProfile", "imageReference", "sku"]); 
  }

  /************** AWS **************/
  async initializeForAWS() {
    this.updateAWSProperties();

    // Wait for next verification loop
    await Promise.resolve(null);

    this.vmInfo.vmStatus = VMStatus.initFromAWSDetails(this.vmInfo);
    if (this.vmInfo.vmStatus.vmState == VMState.PENDING) // Wait for status change and update UI
      this.requestMonitorStatusChange.emit(new AWSRequestMonitorStatusEvent(this.vmInfo.vmStatus, this.awsAccountInfo.accountID));
    else
      this.onStatusChanged.emit(new VMStatusUpdateEvent(this.vmInfo.vmStatus));
  }

  updateAWSProperties() {
    this.vmID = this.vmInfo["InstanceId"];
    var nameTag = this.vmInfo["Tags"].find(t => t.Key === "Name");
    this.name = nameTag ? nameTag.Value : "<No Name>";

    this.imageType = this.vmInfo["ImageId"];
    this.osType = this.vmInfo["Architecture"];
    this.vmSize = this.vmInfo["InstanceType"];
    this.location = searchForValue(this.vmInfo, ["Placement", "AvailabilityZone"]);
  }
}
