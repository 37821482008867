import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { AzureApiService } from 'src/app/azure/azure-api/azure-api.service';
import { AwsApiService } from 'src/app/aws/aws-api/aws-api.service';
import { VMSecurityGroupsTabInfo} from 'src/app/common-components/tabs/TabInfo';
import { searchForValue } from 'src/utils/misc';
import { CloudType } from 'src/app/constants';

@Component({
  selector: 'app-security-groups-tab',
  templateUrl: './security-groups-tab.component.html',
  styleUrls: ['./security-groups-tab.component.css']
})
export class SecurityGroupsTabComponent implements OnChanges {
  @Input() tabInfo: VMSecurityGroupsTabInfo;

  isLoading: boolean = true;
  securityGroups: object[] = [];
  cloudType: CloudType;

  constructor(
    private azureAPI: AzureApiService,
    private awsAPI: AwsApiService) { }

  ngOnChanges(changes: SimpleChanges) {
    this.securityGroups = [];
    if (this.tabInfo.cloudType == CloudType.AZURE) {
      this.getAzureSecurityGroups(this.tabInfo.securityGroups);
    } else if (this.tabInfo.cloudType == CloudType.AWS) {
      this.getAWSSecurityGroups(this.tabInfo.securityGroups);
    }
  }

  /************** AZURE **************/
  async getAzureSecurityGroups(nicList: string[]) {
    try {
      this.isLoading = true;
      var promises = nicList.map(nicUrl => this.getAzuzePropertyForUrl(nicUrl));
      var formattedSGs = await Promise.all(promises);
      this.securityGroups = formattedSGs.filter(sg => sg != null);
    }
    catch (e) { console.error(e) }
    finally { this.isLoading = false; }
  }

  getAzuzePropertyForUrl(nicUrl) {
    return new Promise((resolve, reject) => {
      this.azureAPI.getPropertyForIdUrl(nicUrl, "2018-08-01", "networkSecurityGroup")
        .subscribe(networkDetails => {
          resolve(this.formatAzureSecurtiyGroup(networkDetails))
        })
    })
  }

  formatAzureSecurtiyGroup(networkDetails) {
    var rawSecurityGroup = searchForValue(networkDetails, ["properties", "networkSecurityGroup"]);
    if (rawSecurityGroup) {
      var rawSecurityRules = searchForValue(rawSecurityGroup, ["properties", "defaultSecurityRules"]);
      var securityRules = rawSecurityRules.map(rule => {
        return {
          name: rule.name,
          direction: this.getAzureSecurityGroupProperty(rule, ["properties", "direction"]),
          priority: this.getAzureSecurityGroupProperty(rule, ["properties", "priority"]),
          protocol: this.getAzureSecurityGroupProperty(rule, ["properties", "protocol"]),
          source: this.getAzureSecurityGroupProperty(rule, ["properties", "sourceAddressPrefix"]),
          sourcePortRange: this.getAzureSecurityGroupProperty(rule, ["properties", "sourcePortRange"]),
          destination: this.getAzureSecurityGroupProperty(rule, ["properties", "destinationAddressPrefix"]),
          destinationPortRange: this.getAzureSecurityGroupProperty(rule, ["properties", "destinationPortRange"]),
          action: this.getAzureSecurityGroupProperty(rule, ["properties", "access"])
        }
      })
      return {
        name: rawSecurityGroup.name,
        rules: securityRules
      }
    }
    
    return null;
  }

  getAzureSecurityGroupProperty(rule: object, properties: string[]) {
    var value = searchForValue(rule, properties);
    return value == "*" ? "Any" : value;
  }

  
  /************** AWS **************/
  async getAWSSecurityGroups(securityGroupsIds: string[]) {
    try {
      this.isLoading = true;
      var rawSecurityGroups = await this.awsAPI.getSecurityGroups(this.tabInfo.awsAccountID, securityGroupsIds);
      if (rawSecurityGroups) {
        var formattedSecurityGroups = rawSecurityGroups.map(sg => this.formatAWSecurityGroup(sg));
        this.securityGroups = formattedSecurityGroups.filter(sg => sg != null);
      }
    }
    catch (e) { console.error(e) }
    finally { this.isLoading = false; }
  }

  formatAWSecurityGroup(rawSecurityGroup: object) {
    if (rawSecurityGroup) {
      var rules = [];

      this.formatIPPermissions(rawSecurityGroup["IpPermissions"], "Inbound", rules);
      this.formatIPPermissions(rawSecurityGroup["IpPermissionsEgress"], "Outbound", rules);

      return {
        name: rawSecurityGroup["Description"],
        rules: rules
      }
    }
    return null;
  }

  formatIPPermissions(ipPermissions: object[], direction: string, rules: object[]) { 
    var number = 0;   
    ipPermissions.forEach(permission => {
        permission["IpRanges"].forEach(range => {
          number++;
          var label = `${direction} ${number}`
          rules.push(this.formatIPPermissionsForRange(range, permission, "CidrIp", label));
        });

        permission["Ipv6Ranges"].forEach(range => {
          number++;
          var label = `${direction} ${number}`
          rules.push(this.formatIPPermissionsForRange(range, permission, "CidrIpv6", label));
        });
    })
  }

  formatIPPermissionsForRange(ipRange: object, permission: object, ipType: string, label: string) {
    return {
      name: label,
      protocol: permission["IpProtocol"] != -1 ? permission["IpProtocol"] : "Any",
      portRange: this.getAWSPortRange(permission["FromPort"], permission["ToPort"]),
      source: ipRange[ipType],
      description: ipRange["Description"]
    };
  }

  getAWSPortRange(fromPort: string, toPort: string) {
    if (!toPort && !fromPort) return "Any"
    if (!toPort && fromPort) return fromPort;
    if (toPort && !fromPort) return toPort;
    if (toPort == fromPort) return toPort;    
    return `${fromPort} - ${toPort}`    
  }
}
