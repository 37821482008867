import { EventEmitter } from '@angular/core';
import { interval } from 'rxjs';

import { AwsApiService } from 'src/app/aws/aws-api/aws-api.service';
import { environment } from 'src/environments/environment';
import { getAWSSession } from 'src/utils/storageHelper';
import { AWSStatusCodes } from 'src/app/constants';
import { unsubscribePendingVM, searchForValue } from 'src/utils/misc';
import { VMPermissions } from 'src/app/dashboard/VMPermissions';
import { VMStatusUpdateEvent, AWSRequestMonitorStatusEvent} from 'src/app/events';
import { VMStatus } from 'src/app/dashboard/VMStatus';
import { VMState } from 'src/app/constants';

// Continually calls the AWS API to get the VM details on a set interval
// to check if it's status changes from pending to a final state. This
// is used when a VM is starting, stopping, or resizing to help the application
// display the correct status.
export function monitorAWSStatusChange(awsAPI: AwsApiService, accountID: string, instanceID: string, pendingVMs: any, retryInterval: number = 10):Promise<object> {
    return new Promise<object>((resolve, reject) => {
        pendingVMs[instanceID] = interval(1000 * retryInterval)
            .subscribe(() => {
                awsAPI.getVMDetails(accountID, instanceID)
                    .then(details => {
                        if (details.State.Code == AWSStatusCodes.Running || details.State.Code == AWSStatusCodes.Stopped || details.State.Code == AWSStatusCodes.Terminated) {
                            unsubscribePendingVM(instanceID, pendingVMs);
                            resolve(details);
                        }
                    })
                    .catch(e => {
                        unsubscribePendingVM(instanceID, pendingVMs);
                        reject(e);
                    })
            })
    });
}

export async function stopAWSVMAndPerformAction(awsAPI: AwsApiService, accountID: string, instanceID: string, vmState: VMState, 
        onStatusChanged: EventEmitter<VMStatusUpdateEvent>, requestMonitorStatusChange: EventEmitter<VMStatusUpdateEvent>, asyncAction: any) {
    var originalState = vmState;
    var vmStatus: VMStatus =  null;

    if (vmState == VMState.RUNNING) {
        // Notify UI of pending state
        onStatusChanged.emit(new VMStatusUpdateEvent(VMStatus.init(instanceID, VMState.PENDING)));
        // Stop VM
        await awsAPI.stopVM(accountID, instanceID);
        // Get new VM details and state
        var details = await monitorAWSStatusChange(awsAPI, accountID, instanceID, {});
        vmStatus = VMStatus.initFromAWSDetails(details);
        // Notify UI of new state
        onStatusChanged.emit(new VMStatusUpdateEvent(vmStatus));
    }

    await asyncAction();

    // Power on VM if it was originally running
    if (originalState == VMState.RUNNING && vmStatus != null) {
        // Start VM
        awsAPI.startVM(accountID, instanceID);
        vmStatus.updateState(VMState.PENDING);
        // Request main page to monitor the status change
        requestMonitorStatusChange.emit(new AWSRequestMonitorStatusEvent(vmStatus, accountID));
    }
}

export async function getVMPermissions(awsAPI: AwsApiService, accountID: string, instanceId: string, volumes: any[]) {
    var permissions = VMPermissions.init(false);    

    try {
        var responses = await Promise.all([
            awsAPI.startVM(accountID, instanceId, true),
            awsAPI.stopVM(accountID, instanceId, true),
            awsAPI.resizeVM(accountID, instanceId, "t2.micro", true)
        ]);

        setPermissionForType(responses[0], "start", permissions);
        setPermissionForType(responses[1], "stop", permissions);
        setPermissionForType(responses[2], "resize", permissions);

        await updateSnapshotPermissions(awsAPI, accountID, volumes, permissions, instanceId);
    }
    catch (e) {
        console.error(e)
    }

    return permissions;
}

function setPermissionForType(response: any, type: string, permissions: VMPermissions) {
    if (response != "UnauthorizedOperation")
        permissions[type] = true;
}

async function updateSnapshotPermissions(awsAPI: AwsApiService, accountID: string, volumes: any[], permissions: VMPermissions, instanceId: string) {
    if (!volumes || volumes.length == 0)
        return;

    //grab instance tags when we check permissions
    var instance = await awsAPI.getVMDetails(accountID,instanceId)

    var tags: any = [];
    instance.Tags.forEach(tag => {
        if (!tag.Key.includes("aws:")){
            tags.push(tag);
        }
    });

    var responses = await Promise.all(
        volumes.map(vol => {
            return awsAPI.createSnapshot(accountID, vol.id, tags, null, true);
        })
    );

    if (responses) {
        responses.forEach((resp, i) => {
            if (resp != "UnauthorizedOperation") {
                volumes[i].snapshotAllowed = true;
                permissions.snapshots = true;
            }
        });
    }
}

// Gets VM permissions based on the team name and team role within the AWS role that the
// user is using. Note: This is currently not ready to be used until the EC2 instance tagging
// is complete.
export function getVMPermissionsForSession(accountID: string) {
    if (!environment.AWS.FILTER_ON_TEAM_NAME)
        return VMPermissions.init(true);
    var session = getAWSSession(accountID);
    var roleName = searchForValue(session, ["roleInfo", "roleName"]);
    if (roleName) {
        var parts = roleName.split('-');
        if (parts && parts.length > 0) {
            var teamRole = parts[parts.length-1];
            if (teamRole == "AppManager" || (teamRole == "AppDeveloper" && !environment.production))
                return VMPermissions.init(true);
        }
    }    
    return VMPermissions.init(false);
}