import {  EventEmitter} from '@angular/core';

import { TabInfo, VMDetailsTabInfo } from 'src/app/common-components/tabs/TabInfo';
import { AlertBroadcastService } from 'src/app/common-components/alert-modal/alert-broadcast.service';
import { BaseEvent, VMStatusUpdateEvent } from 'src/app/events';
import { VMPermissions } from 'src/app/dashboard/VMPermissions';
import { VMState, CloudType } from 'src/app/constants';
import { VMStatus } from 'src/app/dashboard/VMStatus';

export class VMDetailsBase {
    alertBroadcast: AlertBroadcastService;
    onStatusChanged = new EventEmitter<VMStatusUpdateEvent>();
    requestMonitorStatusChange = new EventEmitter<VMStatusUpdateEvent>()

    vmInfo: VMDetailsTabInfo;
    networkInterfacesTabs: TabInfo[] = [];
    securityGroupsTab: TabInfo;

    vmDetails: any;
    vmID: string;
    vmName: string;
    vmState: VMState;
    vmSize: string;
    cloudType: CloudType;

    allowedActions: VMPermissions;

    constructor(vmID: string, vmName: string, vmSize: string, cloudType: CloudType, alertBroadcast: AlertBroadcastService, onStatusChanged: EventEmitter<VMStatusUpdateEvent>, requestMonitorStatusChange: EventEmitter<VMStatusUpdateEvent>) {
        this.vmID = vmID;
        this.vmName = vmName;
        this.vmSize = vmSize;
        this.cloudType = cloudType;
        this.alertBroadcast = alertBroadcast;
        this.onStatusChanged = onStatusChanged;
        this.requestMonitorStatusChange = requestMonitorStatusChange;
    }
    
    updateStatus(vmStatus: VMStatus) {
        this.vmState = vmStatus.vmState;
        if (vmStatus.vmSize != null)
            this.vmSize = vmStatus.vmSize;
        this.vmInfo = new VMDetailsTabInfo(
            "VM Details",
            this.cloudType,
            this.vmDetails,
            this.vmSize,
            this.vmInfo ? this.vmInfo.awsAccountID : null
        );
    }

    async loadDetails() {}
    
    getVmInfoTab() { return this.vmInfo; }
    getNetworkInterfaceTabs() { return this.networkInterfacesTabs; }
    getSecurityGroupsTab() { return this.securityGroupsTab; }
    getAllowedActions() { return this.allowedActions; }
    getVMName() { return this.vmName ;}
    getVMState() { return this.vmState; }
    isPending() { return this.vmState == VMState.PENDING; }
    isRunning() { return this.vmState == VMState.RUNNING; }
    isAzure() { return true; }

    // Actions
    startVM() {}
    stopVM() {}
    deallocateVM() {}
    restartVM() {}
    onResizeVM(evt: BaseEvent) {}
}