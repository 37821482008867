import { Component, OnInit, Input } from '@angular/core';

/**
 * Instance of a tab. A tab contains a title which is displayed
 * in the tab group and dynamic content to render when the
 * tab is selected. Data can be passed to the tab content
 * through the extendable TabInfo object.
 */
import { TabInfo } from '../TabInfo';

@Component({
  selector: 'app-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.css']
})
export class TabComponent implements OnInit  {
  @Input() active = false;
  @Input() public tabInfo: TabInfo;

  title: string;

  ngOnInit() {
    if (this.tabInfo) {
      this.title = this.tabInfo.title;
    }
  }
}
