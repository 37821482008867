/**
 * Handles all authentication with AWS
 */

import { parse } from 'elementtree';

import { AwsApiService } from 'src/app/aws/aws-api/aws-api.service';
import { getAWSAssertion, setAWSSession, clearAWSSessionForAccount } from 'src/utils/storageHelper'
import { environment } from 'src/environments/environment';
import { searchForValue } from 'src/utils/misc';

// Checks the accounts that are enabled in the environment config, and then
// returns a of the roles that the user has available to assume for each account.
export function getAvailableAccountsAndRoles(assertion: string): AWSAccountInfo[] {
    var roles = getRolesFromAssertion(assertion);
    var accounts = Object.keys(environment.AWS.ACCOUNTS);    
    var groupedRoles: AWSAccountInfo[] = [];   
    
    accounts.forEach(accountID => {
        var rolesForAccount = roles.filter(role => role.roleARN.indexOf(accountID) != -1);
        if (rolesForAccount && rolesForAccount.length > 0) {
            groupedRoles.push(new AWSAccountInfo(
                accountID,
                environment.AWS.ACCOUNTS[accountID].name,
                rolesForAccount.map(role => new AWSRoleInfo(
                    role.principalARN,
                    role.roleARN,
                    role.roleARN.substring(role.roleARN.lastIndexOf("/") + 1)
                ))
            ));
        }
    });

    return groupedRoles;
}

// Calls the AWS API service to get the temporary credentials for a specific
// role and then saves it to temporary storage. Returns a true promise if it was successful
export function setTempCredentialsFromRole(awsAPI: AwsApiService, accountID: string, role: AWSRoleInfo): Promise<boolean> {
    return new Promise((resolve, reject) => {
        // Clear existing session
        clearAWSSessionForAccount(accountID);

        var assertion = getAWSAssertion();
        if (assertion) {
            awsAPI.getCredentialsForRole(accountID, role.principalARN, role.roleARN, assertion)
                .then(tempCredentials => {
                    setAWSSession(new AWSAccountCredentials(accountID, role, tempCredentials["Credentials"], searchForValue(tempCredentials, ["Credentials", "Expiration"])));
                    resolve(true);
                }).catch(e => reject(e));                
        } else
            resolve(false);
    });
}

// Parses the assertion string and returns all roles that are available
// for the current user to assume
function getRolesFromAssertion(assertion: string) {
    var saml = atob(assertion);
    var roles = []
    var xml = parse(saml);
    var attributes = xml.findall('./saml2:Assertion/saml2:AttributeStatement/saml2:Attribute/[@Name="https://aws.amazon.com/SAML/Attributes/Role"]/saml2:AttributeValue')
    attributes.forEach(attr => {
      var arns = attr.text.split(",");
      if (arns && arns.length == 2) {
        roles.push({
          principalARN: arns[0],
          roleARN: arns[1]
        })
      }
    });
    return roles;
}

// Class that contains all roles availble for a given account
export class AWSAccountInfo {
    accountID: string;
    accountName: string;
    roles: AWSRoleInfo[]

    constructor(accountID: string, accountName: string, roles: AWSRoleInfo[]) {
        this.accountID = accountID;
        this.accountName = accountName;
        this.roles = roles;
    }
}

// Class that contains all information for a given role
export class AWSRoleInfo {
    principalARN: string;
    roleARN: string;
    roleName: string;

    constructor(principalARN: string, roleARN: string, roleName: string) {
        this.principalARN = principalARN;
        this.roleARN = roleARN;
        this.roleName = roleName;
    }
}

// Class that contains temporary credentials and its associated 
// role information. This object is serialized and saved in local storage
// for each account that the user is accessing.
export class AWSAccountCredentials {
    accountID: string;
    roleInfo: AWSRoleInfo;
    credentials: object;
    expirationDate: string;

    constructor(accountID: string, roleInfo: AWSRoleInfo, credentials: object, expirationDate: Date) { 
        this.accountID = accountID;
        this.roleInfo = roleInfo;
        this.credentials = this.formatCredentials(credentials);
        this.expirationDate = expirationDate.toISOString();
    }

    // Formats credentials from the version returned by the AssumeRole API
    // to the version required by the SDK
    private formatCredentials(creds) {
        return {
            "accessKeyId": creds.AccessKeyId,
            "secretAccessKey": creds.SecretAccessKey,
            "sessionToken": creds.SessionToken,
            "region": "us-west-2"
        }
    }
}