import { Component, OnInit, Host } from '@angular/core';

import { TabComponent } from 'src/app/common-components/tabs/tab/tab.component';
import { VMVetworkTabInfo } from 'src/app/common-components/tabs/TabInfo';
import { searchForValue, extractValueFromUrl } from 'src/utils/misc';
import { AzureApiService } from 'src/app/azure/azure-api/azure-api.service';
import { AwsApiService } from 'src/app/aws/aws-api/aws-api.service';
import { CloudType } from 'src/app/constants';

@Component({
  selector: 'app-network-info-tab',
  templateUrl: './network-info-tab.component.html',
  styleUrls: ['./network-info-tab.component.css']
})
export class NetworkInfoTabComponent implements OnInit {
  tabWrapper: TabComponent;

  properties: any = [];
  isLoading: boolean = false;

  networkName: string;
  ipConfigurations: any[];

  constructor(
      @Host() parent: TabComponent,
      private azureAPI: AzureApiService,
      private awsAPI: AwsApiService) {
    this.tabWrapper = parent;
  }

  ngOnInit() {
      var tabInfo = this.tabWrapper.tabInfo as VMVetworkTabInfo;
      if (tabInfo.cloudType == CloudType.AZURE)
        this.getAzureNetworkInfo(tabInfo);
      else if (tabInfo.cloudType == CloudType.AWS)
        this.getAWSNetworkInfo(tabInfo);      
  }

  /************** AZURE **************/
  getAzureNetworkInfo(tabInfo: VMVetworkTabInfo) {
    this.isLoading = true;    
    var idString = tabInfo["networkInfo"] as string;
    this.azureAPI.getPropertyForIdUrl(idString, "2018-08-01", "ipConfigurations/subnet,ipConfigurations/publicIPAddress")
      .subscribe(networkDetails => {
        this.isLoading = false;
        this.ipConfigurations = this.getAzureIpConfigurations(networkDetails);
      });
  }
  
  getAzureIpConfigurations(networkDetails) {
    var ipConfigurations = searchForValue(networkDetails, ["properties", "ipConfigurations"]);
    if (ipConfigurations) {
      return ipConfigurations.map(nic => {
        this.networkName = networkDetails.name;
        var subnetIdUrl = searchForValue(nic, ["properties", "subnet", "id"]);
        var properties = [
          { label: "Virtual Network", value:  extractValueFromUrl(subnetIdUrl, "virtualNetworks") }, // Pull vnet name out of subnet id url
          { label: "Subnet Name", value: searchForValue(nic, ["properties", "subnet", "name"]) },
          { label: "Subnet Address", value: searchForValue(nic, ["properties", "subnet", "properties", "addressPrefix"]) },
          { label: "Private IP", value: searchForValue(nic, ["properties", "privateIPAddress"]) },
          { label: "Private IP Allocation Method", value: searchForValue(nic, ["properties", "privateIPAllocationMethod"]) },
          { label: "Public IP", value: searchForValue(nic, ["properties", "publicIPAddress", "properties", "ipAddress"]) },
          { label: "Public IP Allocation Method", value: searchForValue(nic, ["properties", "publicIPAddress", "properties", "publicIPAllocationMethod"]) }          
        ];

        return {
          name: nic.name,
          properties
        };
      })
    }
    return null;
  }

   /************** AWS **************/
   async getAWSNetworkInfo(tabInfo: VMVetworkTabInfo) {
     try {
      this.isLoading = true;
      var vpcDetails = await this.awsAPI.getVpc(tabInfo.awsAccountID, tabInfo.networkInfo.VpcId);
      var subnetDetails = await this.awsAPI.getSubnet(tabInfo.awsAccountID, tabInfo.networkInfo.SubnetId);
      this.ipConfigurations = this.getAWSIpConfigurations(tabInfo.networkInfo, vpcDetails, subnetDetails)
     }
     catch (e) { console.error(e) }
     finally { this.isLoading = false; }
   }

   getAWSIpConfigurations(networkDetails, vpcDetails, subnetDetails) {
    var properties = []
    properties.push({ label: "Public IP", value: searchForValue(networkDetails, ["Association", "PublicIp"]) });
    properties.push({ label: "Public DNS Name", value: searchForValue(networkDetails, ["Association", "PublicDnsName"]) });
    networkDetails.PrivateIpAddresses.forEach((privateIP, i) => {
      var number = networkDetails.PrivateIpAddresses.length > 1 ? " " + (i + 1) : "";
      properties.push({ label: `Private IP${number}`, value: privateIP.PrivateIpAddress });
      properties.push({ label: `Private DNS Name${number}`, value: privateIP.PrivateDnsName });
      if (networkDetails.PrivateIpAddresses.length > 1)
        properties.push({ label: `Private IP ${number} Is Primary`, value: privateIP.Primary });
    })

    if (vpcDetails) {
      var name = vpcDetails.Tags.find(tag => tag.Key == "Name");
      if (name)
        properties.push({ label: "VPC Name", value: name.Value });
      properties.push({ label: "VPC ID", value: vpcDetails.VpcId });
    }
    
    if (subnetDetails) {
      var name = subnetDetails.Tags.find(tag => tag.Key == "Name");
      if (name)
        properties.push({ label: "Subnet Name", value: name.Value });
      properties.push({ label: "Subnet ID", value: subnetDetails.SubnetId });
      properties.push({ label: "Subnet Address", value: subnetDetails.CidrBlock });
      properties.push({ label: "Subnet Available IP Addresses", value: subnetDetails.AvailableIpAddressCount });
      properties.push({ label: "Subnet Availability Zone", value: subnetDetails.AvailabilityZone });
      properties.push({ label: "Assign Ipv6 Address On Creation", value: subnetDetails.AssignIpv6AddressOnCreation });
      properties.push({ label: "Map Public IP On Launch", value: subnetDetails.MapPublicIpOnLaunch });
    }

    return [{
      name: networkDetails.Description,
      properties
    }]
   }

}
