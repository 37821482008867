import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { VMDetailsTabInfo} from 'src/app/common-components/tabs/TabInfo';
import { AwsApiService } from 'src/app/aws/aws-api/aws-api.service';
import { searchForValue, extractValueFromUrl } from 'src/utils/misc';
import { CloudType } from 'src/app/constants';

@Component({
  selector: 'app-vm-info-tab',
  templateUrl: './vm-info-tab.component.html',
  styleUrls: ['./vm-info-tab.component.css']
})
export class VmInfoTabComponent implements OnChanges {
  @Input() tabInfo: VMDetailsTabInfo;

  properties: any[] = [];  
  dataDisks: any[] = [];
  isLoading: boolean = false;

  constructor(private awsAPI: AwsApiService) { }

  // Called when dialog is shown and tab loads
  ngOnChanges(changes: SimpleChanges) {
    this.properties = []
    if (this.tabInfo.cloudType == CloudType.AZURE) {
      this.getAzureProperties(this.tabInfo.vmDetails);
    } else if (this.tabInfo.cloudType == CloudType.AWS) {
      this.getAWSProperties(this.tabInfo.vmDetails);
    }
  }

  /************** AZURE **************/
  getAzureProperties(vmDetails: object) {
    this.properties.push({ label: "Resource Group", value: extractValueFromUrl(vmDetails["id"], "resourceGroups") });
    this.properties.push({ label: "Location", value: vmDetails["location"] });
    this.properties.push({ label: "VM Size", value: this.tabInfo.vmSize });  

    var properties = searchForValue(vmDetails, ["properties"]);
    if (properties) {       
      this.properties.push({ label: "OS", value: searchForValue(properties, ["storageProfile", "osDisk", "osType"]) });
      this.properties.push({ label: "SKU", value: searchForValue(properties, ["storageProfile", "imageReference", "sku"]) });

      var instanceView = searchForValue(properties, ["instanceView"]);
      if (instanceView) {
        this.properties.push({ label: "OS Name", value: searchForValue(instanceView, ["osName"]) });
        this.properties.push({ label: "OS Version", value: searchForValue(instanceView, ["osVersion"]) });
      }

      this.getAzureDataDisks(properties);
    }
  }

  getAzureDataDisks(properties: object) {
    var dataDisks = searchForValue(properties, ["storageProfile", "dataDisks"]);
    if (dataDisks) {
      this.dataDisks = dataDisks.map(disk => {
        return {
          name: disk.name,
          properties: [
            { label: "Disk Size", value: `${disk.diskSizeGB} GB` },
            { label: "Create Option", value: disk.createOption },
            { label: "Caching", value: disk.caching },
            { label: "Storage Account Type", value: searchForValue(disk, ["managedDisk", "storageAccountType"]) },
            { label: "Lun", value: disk.lun }
          ]
        }
      });
    }
  }

  /************** AWS **************/
  getAWSProperties(vmDetails: any) {
    this.properties.push({ label: "Instance Id", value: vmDetails.InstanceId });
    this.properties.push({ label: "Location", value: searchForValue(vmDetails, ["Placement", "AvailabilityZone"]) });
    this.properties.push({ label: "VM Size", value: this.tabInfo.vmSize });
    this.properties.push({ label: "Image Id", value: vmDetails.ImageId });
    this.properties.push({ label: "Architecture", value: vmDetails.Architecture });
    this.properties.push({ label: "Key Name", value: vmDetails.KeyName});

    vmDetails.Tags.forEach(tag => {
      if (tag.Key != "Name")
        this.properties.push({ label: `Tag - ${tag.Key}`, value: tag.Value });
    });

    this.getAWSDataDisks(vmDetails);
  }

  getAWSDataDisks(vmDetails: any) {
    if (this.tabInfo.awsAccountID != null && vmDetails && vmDetails.BlockDeviceMappings) {
      var volumeIds = vmDetails.BlockDeviceMappings.map(block => searchForValue(block, ["Ebs", "VolumeId"]));
      if (volumeIds) {
        this.awsAPI.getVolumes(this.tabInfo.awsAccountID, volumeIds)
          .then(volumes => {
            this.dataDisks = volumes.map(volume => {
              var name = volume.Tags.find(tag => tag.Key == "Name")
              return {                
                name: name ? name.Value : volume.VolumeId,
                properties: [
                  { label: "Volume ID", value: volume.VolumeId },
                  { label: "Disk Size", value: `${volume.Size} GB` },
                  { label: "Iops", value: volume.Iops },
                  { label: "Encrypted", value: volume.Encrypted },
                  { label: "Availability Zone", value: volume.AvailabilityZone },
                  { label: "State", value: volume.State },
                  { label: "VolumeType", value: volume.VolumeType }
                ]
              }
            });
          })
          .catch(e => console.error(e));
      }
    }
  }
}
