import { Component, OnInit, Input, ViewChild, HostListener } from '@angular/core';

import { VMStatus } from 'src/app/dashboard/VMStatus';
import { searchForValue, formatDate } from 'src/utils/misc';
import { VMState, CloudType } from 'src/app/constants';

@Component({
  selector: 'app-vm-health-status',
  templateUrl: './vm-health-status.component.html',
  styleUrls: ['./vm-health-status.component.css']
})
export class VmHealthStatusComponent implements OnInit {
  @ViewChild('popup') public popup;
  @Input() healthStatus: any;
  @Input() vmStatus: VMStatus;
  @Input() cloudType: CloudType;

  isShowing: boolean = false;

  constructor() { }

  ngOnInit() {}

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    if (this.isShowing) {
      this.popup.hide();
      this.isShowing = false;
    }
  }

  showPopup(event: any) {
    event.stopPropagation();
    if (this.isShowing)
      this.popup.hide();
    else
      this.popup.show();
    this.isShowing = !this.isShowing;
  }

  hasError() {
    if (this.vmStatus && this.vmStatus.vmState == VMState.RUNNING) {
      if (this.cloudType == CloudType.AZURE) {
        var state = (searchForValue(this.healthStatus, ["properties", "availabilityState"]) || "").toLowerCase();
        return state != "available";
      } else if (this.cloudType == CloudType.AWS) {
        var instanceStatus = (searchForValue(this.healthStatus, ["InstanceStatus", "Status"]) || "").toLowerCase();
        var systemStatus = (searchForValue(this.healthStatus, ["SystemStatus", "Status"]) || "").toLowerCase();
        return instanceStatus != "ok" || systemStatus != "ok";
      }
    }
    return false;
  }

  title() {
    if (this.healthStatus) {
      if (this.cloudType == CloudType.AZURE)
        return "Status: " + searchForValue(this.healthStatus, ["properties", "availabilityState"]);
      else if (this.cloudType == CloudType.AWS) {
        var instanceStatus = (searchForValue(this.healthStatus, ["InstanceStatus", "Status"]) || "").toLowerCase();
        var systemStatus = (searchForValue(this.healthStatus, ["SystemStatus", "Status"]) || "").toLowerCase();
        return `Status: ${instanceStatus != "ok" ? instanceStatus : systemStatus != "ok" ? systemStatus : "ok"}`
      }
    }
    return "";
  }

  bodyHtml() {
    if (this.healthStatus) {
      if (this.cloudType == CloudType.AZURE)
        return this.getHtmlBodyForAzure();
      else if (this.cloudType == CloudType.AWS)
        return this.getHtmlBodyForAWS();
    }
    return null;    
  }

  getHtmlBodyForAzure() {
    var html = `
      <p>Reason: ${searchForValue(this.healthStatus, ["properties", "reasonChronicity"])}</p>
      <p>Occured Time: ${formatDate(searchForValue(this.healthStatus, ["properties", "occuredTime"]))}</p>
      <p>Reported Time: ${formatDate(searchForValue(this.healthStatus, ["properties", "reportedTime"]))}</p>
      <p>Summary: ${searchForValue(this.healthStatus, ["properties", "summary"])}</p>
    `;

    var recommendedActions = searchForValue(this.healthStatus, ["properties", "recommendedActions"]);
    if (recommendedActions && recommendedActions.length > 0) {
      var list = ""
      recommendedActions.forEach(action => {
        list += `<li>${action.action}</li>`
      });
      html += `<p>Recommended Actions: </p><ul>${list}</ul`;
    }
    return html;
  }

  getHtmlBodyForAWS() {
    var html = "";
    if (this.healthStatus) {
      html += this.getHtmlBodyForAwsSubSection("InstanceStatus");
      html += this.getHtmlBodyForAwsSubSection("SystemStatus");
    }
    return html;
  }

  getHtmlBodyForAwsSubSection(sectionName: string) {
    var section = this.healthStatus[sectionName];
    if (!section)
      return null;
    var html =  `
      <p>${sectionName}: ${section.Status}</p>
    `

    if (section.Details) {
      var list = ""
      section.Details.forEach(detail => {
        list += `<li>${detail.Name}: ${detail.Status}</li>`;
      })
      html += `<ul>${list}</ul>`;
    }

    return html;
  }
}
