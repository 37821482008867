/**
 * The TabInfo object is used to pass data to the content
 * within a tab. The TabInfo object is extendable and each
 * specific tab uses a different type of TabInfo object.
 */

import { AWSAccountInfo } from 'src/app/aws/aws-auth/aws-auth';
import { CloudType } from 'src/app/constants';

// Base object
export class TabInfo {
    title: string;

    constructor(title: string) {
        this.title = title;
    }
}

// Main Azure VM list tab
export class VMListTabInfoAzure extends TabInfo {
    subscriptions: object[];

    constructor(title: string, subscriptions: object[]) {
        super(title);

        this.subscriptions = subscriptions;
    }
}

// Main AWS VM list tab
export class VMListTabInfoAWS extends TabInfo {
    accountInfo: AWSAccountInfo;
    noContentMessage: string;
    includeLoginLink: boolean = false;

    constructor(title: string, accountInfo: AWSAccountInfo, noContentMessage?: string, includeLoginLink?: boolean ) {
        super(title);

        this.accountInfo = accountInfo;
        this.noContentMessage = noContentMessage;
        this.includeLoginLink = includeLoginLink;
    }
}

// Network tab on the VM details modal dialog
export class VMVetworkTabInfo extends TabInfo {
    cloudType: CloudType;
    networkInfo: any;
    awsAccountID: string;

    constructor(title: string, cloudType: CloudType, networkInfo: any, awsAccountID: string = null) {
        super(title);
        
        this.cloudType = cloudType;
        this.networkInfo = networkInfo;
        this.awsAccountID = awsAccountID;
    }
}

// VM Info tab on the VM details dialog
export class VMDetailsTabInfo extends TabInfo {
    cloudType: CloudType;
    vmDetails: object;
    vmSize: string;
    awsAccountID: string;

    constructor(title: string, cloudType: CloudType, vmDetails: object, vmSize: string, awsAccountID: string = null) {
        super(title);

        this.cloudType = cloudType;
        this.vmDetails = vmDetails;
        this.vmSize = vmSize;
        this.awsAccountID = awsAccountID;
    }
}

// Security Groups tab on the VM details dialog
export class VMSecurityGroupsTabInfo extends TabInfo {
    cloudType: CloudType;
    securityGroups: string[];
    awsAccountID: string;

    constructor(title: string, cloudType: CloudType, securityGroups: string[], awsAccountID: string = null) {
        super(title);

        this.cloudType = cloudType;
        this.securityGroups = securityGroups;
        this.awsAccountID = awsAccountID;
    }
}