/**
 * This Angular service is responsible for all communication
 * between the application and AWS. A singleton instance
 * of this class is available to all components via
 * dependency injection. Each function is responsible for
 * pulling the temporary credentials for a current account
 * out of local storage and using it to authenticate. 
 * 
*/
import { Injectable } from '@angular/core';
import * as AWS from 'aws-sdk';

import { AlertBroadcastService } from 'src/app/common-components/alert-modal/alert-broadcast.service';
import { environment } from 'src/environments/environment';
import { getAWSSession, hasValidAWSSession, clearAllAWSSessions } from 'src/utils/storageHelper'
import { parseAssertionFromResponse } from 'src/utils/misc';

@Injectable({
  providedIn: 'root'
})
export class AwsApiService {

  constructor(private alertBroadcast: AlertBroadcastService) { }

  // Retrieve the SAML assertion from the SSO 
  // provider (F5) if it exists. If not, return an error.
  getSAMLAssertion(): Promise<string> {
    return new Promise((resolve, reject) => {
      var xhr = new XMLHttpRequest();
      xhr.open('GET', environment.AWS.AUTH_ENDPOINT, true);
      xhr.withCredentials = true;
      xhr.send(null);
  
      xhr.onreadystatechange = () => {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          if (xhr.status == 404)
            reject({ status: "networkError", message: "Error: No response from SSO provider."});
          else {
            var assertion = parseAssertionFromResponse(xhr.responseText);
            if (assertion)
              resolve(assertion);
            else 
              reject({ status: "sessionError", message: "User does not have an existing session" });
          }          
        }
      }
    })
  }

  // Retrieve temporary credentials for a given role and SAMLAssertion. If the assertion is invalid
  // or expired an error will be returned and the user will be notified.
  getCredentialsForRole(accountID: string, principalArn: string, roleArn: string, SAMLAssersion: string): Promise<any> {
    var params = {
      PrincipalArn: principalArn,
      RoleArn: roleArn,
      SAMLAssertion: SAMLAssersion
    }
   
    return new Promise((resolve, reject) => {
      var sts = new AWS.STS();
      sts.assumeRoleWithSAML(params, (err, resp) => {
        if (err) {
          this.handleError("getCredentialsForRole", err, accountID);
          reject(err);
        } else {
          resolve(resp);
        }
      })
    });
  }
  
  getVMList(accountID: string, currentRole: string): Promise<any[]> {
    var params = null;
    if (environment.AWS.FILTER_ON_TEAM_NAME) {
      params = {
        Filters: [{
         Name: "tag:RBACTeam", 
         Values: [
           this.getTeamNameFromRole(currentRole)
         ]
        }]
      };
    }
    return this.describeInstance(accountID, this.getAccountCredentialsForAccount(accountID), params);
  }

  async getVMDetails(accountID: string, instanceId: string): Promise<any> {
    var params = {
      InstanceIds: [
        instanceId
      ]
    }
    var details = await this.describeInstance(accountID, this.getAccountCredentialsForAccount(accountID), params)
    if (details && details.length > 0)
      return details[0];
    return null;
  }

  detachVolume(accountID: string, volumeId: string): Promise<any> {
    var params = {
      VolumeId: volumeId
    }
    return new Promise((resolve, reject) => {
      var ec2 = new AWS.EC2(this.getAccountCredentialsForAccount(accountID));
      ec2.detachVolume(params, (err, resp) => {
        if (err) {
          this.handleError("detachVolume", err, accountID);
          reject(err)
        } else {
          resolve()
        }
      });
    });
  }

  attachVolume(accountID: string, device: string, instanceId: string, volumeId: string): Promise<any> {
    var params = {
      Device: device,
      InstanceId: instanceId,
      VolumeId: volumeId
    }
    return new Promise((resolve, reject) => {
      var ec2 = new AWS.EC2(this.getAccountCredentialsForAccount(accountID));
      ec2.attachVolume(params, (err, resp) => {
        if (err) {
          this.handleError("attachVolume", err, accountID);
          reject(err)
        } else {
          resolve()
        }
      });
    });
  }

  getVolumes(accountID: string, volumeIds: string[]): Promise<any[]> {
    var params = {
      VolumeIds: volumeIds
    }

    return new Promise((resolve, reject) => {
      var ec2 = new AWS.EC2(this.getAccountCredentialsForAccount(accountID));
      ec2.describeVolumes(params, (err, resp) => {
        if (err) {
          this.handleError("getVolumes", err, accountID);
          resolve(null)
        } else {
          resolve(resp && resp.Volumes ? resp.Volumes : null)
        }
      });
    });
  }

  getSecurityGroups(accountID: string, securityGroups: string[]): Promise<any[]> {
    var params = {
      GroupIds: securityGroups
    }

    return new Promise((resolve, reject) => {
      var ec2 = new AWS.EC2(this.getAccountCredentialsForAccount(accountID));
      ec2.describeSecurityGroups(params, (err, resp) => {
        if (err) {
          this.handleError("getSecurityGroups", err, accountID);
          resolve(null)
        } else {
          resolve(resp && resp.SecurityGroups ? resp.SecurityGroups : null);
        }
      });
    });
  }

  getVpc(accountID: string, vpcId: string): Promise<any> {
    var params = {
      VpcIds: [
        vpcId
      ]
    }

    return new Promise((resolve, reject) => {
      var ec2 = new AWS.EC2(this.getAccountCredentialsForAccount(accountID));
      ec2.describeVpcs(params, (err, resp) => {
        if (err) {
          this.handleError("getVpc", err, accountID);
          resolve(null);
        } else {
          resolve(resp && resp.Vpcs && resp.Vpcs.length > 0 ? resp.Vpcs[0] : null)
        }
      });
    });
  }

  getSubnet(accountID: string, subnetId: string): Promise<any> {
    var params = {
      SubnetIds: [
        subnetId
      ]
    }

    return new Promise((resolve, reject) => {
      var ec2 = new AWS.EC2(this.getAccountCredentialsForAccount(accountID));
      ec2.describeSubnets(params, (err, resp) => {
        if (err) {
          this.handleError("getSubnet", err, accountID);
          resolve(null);
        } else {
          resolve(resp && resp.Subnets && resp.Subnets.length > 0 ? resp.Subnets[0] : null)
        }
      });
    });
  }

  stopVM(accountID: string, instanceId: string, dryRun: boolean = false): Promise<any> {
    return this.performVMAction("stopInstances", this.getAccountCredentialsForAccount(accountID), accountID, instanceId, dryRun);
  }

  startVM(accountID: string, instanceId: string, dryRun: boolean = false): Promise<any> {
    return this.performVMAction("startInstances", this.getAccountCredentialsForAccount(accountID), accountID, instanceId, dryRun);
  }

  resizeVM(accountID: string, instanceId: string, InstanceType: string, dryRun: boolean = false): Promise<any> {
    var params = {
      "InstanceId": instanceId,
      "InstanceType": {
        "Value": InstanceType
      },
      "DryRun": dryRun
    }

    return new Promise((resolve, reject) => {
      var ec2 = new AWS.EC2(this.getAccountCredentialsForAccount(accountID));
      ec2.modifyInstanceAttribute(params, (err, resp) => {
        if (err) {
          if (dryRun) {
            resolve(err.code)
          } else {
            this.handleError("resizeVM", err, accountID);
            reject(err);
          }
        } else {
          resolve(resp);
        }
      });
    });
  }

  createSnapshot(accountID: string, volumeId: string, volumeTags: any[], snapshotDescription: string, dryRun: boolean = false): Promise<any> {
    var params = {
      "Description": snapshotDescription,
      "VolumeId": volumeId,
      "TagSpecifications": [{
        "ResourceType": "snapshot",
        "Tags": volumeTags
      }],
      "DryRun": dryRun
    }

    return new Promise((resolve, reject) => {
      var ec2 = new AWS.EC2(this.getAccountCredentialsForAccount(accountID));
      ec2.createSnapshot(params, (err, resp) => {
        if (err) {
          if (dryRun) {
            resolve(err.code)
          } else {
            this.handleError("createSnapshot", err, accountID);
            reject(err);
          }
        } else {
          resolve(resp);
        }
      });
    });    
  }

  getSnapshots(accountID: string, volumeIds: string[]) {
    var params = {
      Filters: [{
       Name: "volume-id", 
       Values: volumeIds
      }]
    }

    return new Promise((resolve, reject) => {
      var ec2 = new AWS.EC2(this.getAccountCredentialsForAccount(accountID));
      ec2.describeSnapshots(params, (err, resp) => {
        if (err)
          this.handleError("getSnapshots", err, accountID);
        resolve(resp);
      })
    });
  }

  deleteSnapshot(accountID: string, snapshotId: string) {
    var params = {
      "SnapshotId": snapshotId
    }

    return new Promise((resolve, reject) => {
      var ec2 = new AWS.EC2(this.getAccountCredentialsForAccount(accountID));
      ec2.deleteSnapshot(params, (err, resp) => {
        if (err)
          this.handleError("deleteSnapshot", err, accountID);
        resolve(resp);
      })
    });
  }

  createVolume(accountID: string, availabilityZone: string, snapshotId: string, nameTag: any[]) {
    var params = {
      "AvailabilityZone": availabilityZone,
      "SnapshotId": snapshotId,
      "TagSpecifications": [{
        "ResourceType": "volume",
        "Tags": nameTag
      }]
    }

    return new Promise((resolve, reject) => {
      var ec2 = new AWS.EC2(this.getAccountCredentialsForAccount(accountID));
      ec2.createVolume(params, (err, resp) => {
        if (err) {
          this.handleError("createVolume", err, accountID);
          reject(err)
        } else {
          resolve(resp);
        }
      })
    });
  }

  deleteVolume(accountID: string, volumeID) {
    var params = {
      "VolumeId": volumeID
    }

    return new Promise((resolve, reject) => {
      var ec2 = new AWS.EC2(this.getAccountCredentialsForAccount(accountID));
      ec2.deleteVolume(params, (err, resp) => {
        if (err) {
          this.handleError("deleteVolume", err, accountID);
          reject(err)
        } else {
          resolve();
        }
      })
    });
  }

  getHealthForVMs(accountID: string, instanceIds: string[]) {
    var params = {
      InstanceIds: instanceIds
    }
    return new Promise((resolve, reject) => {
      var ec2 = new AWS.EC2(this.getAccountCredentialsForAccount(accountID));
      ec2.describeInstanceStatus(params, (err, resp) => {
        if (err)
          this.handleError("getHealthForVMs", err, accountID);
        resolve(resp);
      })
    });
  }

  private performVMAction(action: string, creds: object, accountID: string, instanceId: string, dryRun: boolean = false): Promise<any> {
    var params = {
      InstanceIds: [
        instanceId
      ],
      DryRun: dryRun
    }
    var ec2 = new AWS.EC2(creds);
    return new Promise((resolve, reject) => {
      ec2[action](params, (err, resp) => {
        if (err) {
          if (dryRun) {
            resolve(err.code)
          } else {
            this.handleError("performVMAction", err, accountID)
            reject(err)
          }
        } else {
          resolve(resp)
        }
      });
    });
  }

  private describeInstance(accountID: string, creds: object, params?: object): Promise<any> {
    var ec2 = new AWS.EC2(creds);
    var instances = []

    return new Promise((resolve, reject) => {
      ec2.describeInstances(params, (err, res) => {
        if (err) {
          this.handleError("describeInstance", err, accountID);          
        } else {
          if (res && res.Reservations) {
            res.Reservations.forEach(r => {
              if (r.Instances)
                instances = instances.concat(r.Instances);
            });
          }
        }
        resolve(instances);
      });
    });
  }

  // Helper function to retrieve credentials from the local storage
  // for a given account id.
  private getAccountCredentialsForAccount(accountID) {
    var session = getAWSSession(accountID);
    if (session)
      return session.credentials;
    else 
      return { region: "us-west-2" }
  }

  // Parses the team name from the current role
  // so that it can be used to filter instances
  private getTeamNameFromRole(roleName: string) {
    if (roleName) {
      var parts = roleName.split('-');
      if (parts && parts.length >= 2)
        return parts[1];
    }
    return null;
  }

  // Error handler for all AWS API calls. If the user's session is invalid
  // it sends an alert broadcast and refreshes the page. It will also inform
  // the user if they try to perform an action that they are not
  // authorized to perform.
  private handleError(operation: string, error: any, accountID: string) {
    console.error(`Error performing: ${operation}`)
    console.error(error);

    if (!hasValidAWSSession(accountID) || error.code == "AuthFailure" || error.code == "CredentialsError") {
      this.alertBroadcast.broadcastFail("Your AWS session has expired. Please log in again.", () => {
        clearAllAWSSessions();
        location.reload();
      });
    } else if (error.code == "UnauthorizedOperation") {
      this.alertBroadcast.broadcastFail("You are not authorized to perform this action.");
    }
  }
}
