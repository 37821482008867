import { Output, EventEmitter, Component, ViewChild } from '@angular/core';

import { AzureApiService } from 'src/app/azure/azure-api/azure-api.service';
import { AwsApiService } from 'src/app/aws/aws-api/aws-api.service';
import { AlertBroadcastService } from 'src/app/common-components/alert-modal/alert-broadcast.service';
import { AzureOpenDialogEvent, AWSOpenDialogEvent, VMStatusUpdateEvent, ReloadVMEvent } from 'src/app/events';
import { TabInfo } from 'src/app/common-components/tabs/TabInfo';
import { VMDetailsBase, VMDetailsAzure, VMDetailsAWS } from 'src/app/vm-details/details-modal/data-models';
import { VMPermissions } from 'src/app/dashboard/VMPermissions';
import { VMStatus } from 'src/app/dashboard/VMStatus';
import { VMState } from 'src/app/constants';

@Component({
  selector: 'app-details-modal',
  templateUrl: './details-modal.component.html',
  styleUrls: ['./details-modal.component.css']
})
export class DetailsModalComponent {
  @Output() onStatusChanged = new EventEmitter<VMStatusUpdateEvent>();
  @Output() requestMonitorStatusChange = new EventEmitter<VMStatusUpdateEvent>();

  @ViewChild('content') public contentModal;
  @ViewChild('tabGroup') public tabGroup;
  @ViewChild('scheduler') public scheduler;
  @ViewChild('snapshots') public snapshots;

  vmDetails: VMDetailsBase;

  vmName: string;
  vmStatus: VMStatus;
  vmInfo: TabInfo;
  networkInterfacesTabs: TabInfo[] = [];
  securityGroupsTab: TabInfo; 
  statusText: string;

  isLoading: boolean = true;
  isPending: boolean;
  isRunning: boolean;
  isDeallocated: boolean;
  isAzure: boolean;

  allowedActions: VMPermissions = VMPermissions.init(false);

  constructor(
    private azureAPI: AzureApiService,
    private awsAPI: AwsApiService,
    private alertBroadcast: AlertBroadcastService) {
      this.vmInfo = new TabInfo("VM Details");
      this.securityGroupsTab = new TabInfo("Security Groups");

      this.onStatusChanged.subscribe((evt: VMStatusUpdateEvent) => {
        this.updateStatus(evt);
      })
  }

  async onReloadVMEvent(evt: ReloadVMEvent) {
    // Load new details
    await this.vmDetails.loadDetails();

    // Update snapshots dialog with new details
    if (this.snapshots.isShown())
      this.snapshots.show(this.vmDetails);
  }

  getStatusTextColor() {
    if (this.vmDetails) {
      switch(this.vmDetails.getVMState())
      {
        case VMState.DEALLOCATED:
        case VMState.STOPPED:
          return"red";

        case VMState.RUNNING:
          return "darkgreen";

        case VMState.PENDING:
          return "orange";
      }
    }
    return "";    
  }

  startVM() { this.vmDetails.startVM(); }
  stopVM() { this.vmDetails.stopVM(); }
  deallocateVM() { this.vmDetails.deallocateVM(); }
  restartVM() { this.vmDetails.restartVM(); }

  async loadContent() {
    this.statusText = null;
    this.vmName = null;
    this.isLoading = true;

    this.tabGroup.selectFirstTab();
    this.contentModal.show();
    
    await this.vmDetails.loadDetails();
    this.updateModal();
  }
  
  updateModal() {
    this.vmName = this.vmDetails.getVMName();
    this.vmInfo = this.vmDetails.getVmInfoTab();
    this.securityGroupsTab = this.vmDetails.getSecurityGroupsTab();
    this.networkInterfacesTabs = this.vmDetails.getNetworkInterfaceTabs();

    this.isAzure = this.vmDetails.isAzure();
    this.isPending = this.vmDetails.isPending();
    this.isRunning = this.vmDetails.isRunning();
    this.isLoading = false;

    this.allowedActions = this.vmDetails.getAllowedActions();
  }

  updateStatus(evt: VMStatusUpdateEvent) {
    this.vmStatus = evt.vmStatus;
    this.statusText = evt.vmStatus.statusText;
    this.isDeallocated = evt.vmStatus.vmState == VMState.DEALLOCATED;
    this.vmDetails.updateStatus(evt.vmStatus);
    this.updateModal();
    this.tabGroup.selectFirstTab();
  }

  showAzureDetails(evt: AzureOpenDialogEvent) {
    this.vmDetails = new VMDetailsAzure(this.azureAPI, this.alertBroadcast, evt, this.onStatusChanged, this.requestMonitorStatusChange);
    this.loadContent();    
  }
  
  showAWSDetails(evt: AWSOpenDialogEvent, accountID: string) {
    this.vmDetails = new VMDetailsAWS(this.awsAPI, this.alertBroadcast, evt, accountID, this.onStatusChanged, this.requestMonitorStatusChange);
    this.loadContent();
  }

  showScheduler() {
    this.scheduler.show(this.vmDetails, this.allowedActions)
  }
}
