import { Component, ViewChild, EventEmitter, Input, Output } from '@angular/core';
import { VMDetailsBase, VMDetailsAzure, VMDetailsAWS } from 'src/app/vm-details/details-modal/data-models';
import { AwsApiService } from 'src/app/aws/aws-api/aws-api.service';
import { CloudType, VMState } from 'src/app/constants';
import { searchForValue } from 'src/utils/misc';
import { VMStatusUpdateEvent, ReloadVMEvent } from 'src/app/events';

@Component({
  selector: 'app-snapshots',
  templateUrl: './snapshots.component.html',
  styleUrls: ['./snapshots.component.css'],
  exportAs: 'snapshots'
})
export class SnapshotsComponent {
  @Input() onStatusChanged: EventEmitter<VMStatusUpdateEvent>;
  @Input() requestMonitorStatusChange: EventEmitter<VMStatusUpdateEvent>; 
  @Output() onReloadVMEvent = new EventEmitter<ReloadVMEvent>();

  @ViewChild('snapshots') public snapshots;
  @ViewChild('createSnapshot') public createSnapshot;
  @ViewChild('viewSnapshots') public viewSnapshots;
    
  isLoading: boolean = false;
  cloudType: CloudType;
  vmState: VMState;  
  vmID: string;
  vmName: string;
  subscriptionID: string;
  resourceGroup: string;
  location: string;
  awsAccountID: string;
  public diskList: any[] = [];
  selectedDisks: any[] = [];
  awsAvailabilityZone: string;

  constructor(private awsAPI: AwsApiService) { }

  show(vmDetails: VMDetailsBase) {
  	this.cloudType = vmDetails.cloudType;
    this.vmState = vmDetails.vmState;
    this.clearForm();
    if (vmDetails instanceof VMDetailsAzure)
      this.showAzureSnapshotModal(vmDetails as VMDetailsAzure)
    else if (vmDetails instanceof VMDetailsAWS)
      this.showAWSSnapshotModal(vmDetails as VMDetailsAWS)
  }

  clearForm() {
    this.diskList = [];
    this.selectedDisks = [];
  }

  isShown() {
    return this.snapshots.isShown;
  }

  showAzureSnapshotModal(vmDetails: VMDetailsAzure) {
    this.vmName = vmDetails.vmName;
    this.vmID = vmDetails.vmID;
    this.subscriptionID = vmDetails.getSubscriptionID();
    this.resourceGroup = vmDetails.getResourceGroup();
    this.location = vmDetails.getLocaction();

    this.snapshots.show();

    var osDisk = searchForValue(vmDetails, ["vmDetails", "properties", "storageProfile", "osDisk"]);
    this.diskList.push({ id: searchForValue(osDisk, ["managedDisk", "id"]), name: osDisk.name });

    var dataDisks = searchForValue(vmDetails, ["vmDetails", "properties", "storageProfile", "dataDisks"]);
    for (let disk of dataDisks) {
      this.diskList.push({ id: searchForValue(disk, ["managedDisk", "id"]), name: disk.name });
    }
  }

  onDiskSelected(evt: any, disk: any) {
    disk.checked = evt.checked;
    if (disk.checked) {
      this.selectedDisks.push(disk)
    } else {
      this.selectedDisks = this.selectedDisks.filter(d => d.id != disk.id);
    }
  }

  // vmDetails is an array of objects passed in from vmd-details/details-modal.  This is done for Azure as well
  async showAWSSnapshotModal(vmDetails: VMDetailsAWS) {
    this.isLoading = true;
    this.awsAccountID = vmDetails.accountID;
    this.awsAvailabilityZone = searchForValue(vmDetails, ["vmDetails", "Placement", "AvailabilityZone"]);    
    var disks = await this.awsAPI.getVolumes(this.awsAccountID, vmDetails.attachedDisks.map(d => d.id));
    
    vmDetails.attachedDisks.forEach(d => {
      if (d.snapshotAllowed) {
        var name = this.getNameForAWSDisk(disks, d.id);
        if (name) 
          d.descriptiveName = `${name} - ${d.name}`;
        this.diskList.push(d);
      }
    });

    this.isLoading = false;
    this.vmID = vmDetails["vmID"];
    this.snapshots.show();
  }

  getNameForAWSDisk(disks: any[], volId: string) {
    if (disks && disks.length > 0) {
      var disk = disks.find(d => d.VolumeId == volId);
      if (disk) {
        var nameTag = disk.Tags.find(t => t.Key.toLowerCase() == "name");
        if (nameTag)
          return nameTag.Value;
      }
    }
    return null;
  }
  
  showCreateSnapshotDialog() {
    if (this.selectedDisks.length == 0)
      return;

    if (this.cloudType == CloudType.AZURE)
      this.createSnapshot.showAzure(this.selectedDisks, this.vmState, this.vmID, this.subscriptionID, this.resourceGroup, this.vmName, this.location);
    else if (this.cloudType == CloudType.AWS)
      this.createSnapshot.showAWS(this.selectedDisks, this.vmState, this.awsAccountID, this.vmID);
  }

  viewSnapshotsForDisk(diskList: any, vmName: string) {
    if (this.selectedDisks.length != 1)
      return;

    if (this.cloudType == CloudType.AZURE)
      this.viewSnapshots.showAzure(this.selectedDisks[0], this.subscriptionID, this.resourceGroup, diskList, vmName, this.vmState);
    else if (this.cloudType == CloudType.AWS)
      this.viewSnapshots.showAWS(this.selectedDisks[0], this.awsAccountID, diskList, this.vmID, this.awsAvailabilityZone, this.vmState);
  }
}
