import { Component, OnInit, Host, ViewChild, ViewChildren, Output, EventEmitter} from '@angular/core';

import { AzureOpenDialogEvent, AWSOpenDialogEvent, VMStatusUpdateEvent, AzureRequestMonitorStatusEvent, 
  AWSRequestMonitorStatusEvent, AWSRoleSelectedEvent, CloseTabEvent } from 'src/app/events';
import { AzureApiService } from 'src/app/azure/azure-api/azure-api.service';
import { AwsApiService } from 'src/app/aws/aws-api/aws-api.service';
import { TabComponent } from 'src/app/common-components/tabs/tab/tab.component';
import { VMListTabInfoAzure, VMListTabInfoAWS } from 'src/app/common-components/tabs/TabInfo';
import { getAWSSession } from 'src/utils/storageHelper'
import { AWSRoleInfo, setTempCredentialsFromRole } from 'src/app/aws/aws-auth/aws-auth';
import { VMStatus } from 'src/app/dashboard/VMStatus';
import { searchForValue } from 'src/utils/misc';
import { environment } from 'src/environments/environment'
import { CloudType } from 'src/app/constants';
import * as AzureUtils from 'src/app/azure/azure-misc/azure-utils';
import * as AWSUtils from 'src/app/aws/aws-mic/aws-utils';

@Component({
  selector: 'app-dashboard-tab',
  templateUrl: './dashboard-tab.component.html',
  styleUrls: ['./dashboard-tab.component.css']
})
export class DashboardTabComponent implements OnInit {
  @ViewChild('dialog') public dialog;
  @ViewChildren('gridItems') public gridItems

  @Output() onCloseTab = new EventEmitter<CloseTabEvent>();

  noContentMessage: string = "There are no virtual machines to display"; 
  showLoginLink: boolean = false;
  isLoading: boolean = true;

  tabWrapper: TabComponent;
  currentAWSRole: AWSRoleInfo;
  subscriptions: object[];
  isAzure: boolean = false;
  vmList: any[] = [];
  filteredVmList: any = [];
  cloudType: CloudType;
  
  // The VM that is currently being viewed in the VM Details modal
  currentDialogVMId: string;

  // Object containing VMs whose state is currently pending and being monitored
  // Key: VM ID, value: interval subscription
  pendingVMs = {};
  
  // Key/Value pairs of status for each VM
  // Key: VM ID, value: status
  healthStatuses: any = {};

  constructor(
      @Host() parent: TabComponent,
      private azureAPI: AzureApiService,
      private awsAPI: AwsApiService) { 
    this.tabWrapper = parent;
  }
  ngOnInit() {    
    this.getVMList();
  }

  filterVMs(filteredVmList: any[]) {
    this.filteredVmList = filteredVmList;
  }

  openDialogAzure(evt: AzureOpenDialogEvent) {
    this.currentDialogVMId = evt.vmID;
    this.dialog.showAzureDetails(evt)
  }

  openDialogAWS(evt: AWSOpenDialogEvent) {
    var tabInfo = this.tabWrapper.tabInfo as VMListTabInfoAWS
    this.currentDialogVMId = evt.instanceID;
    this.dialog.showAWSDetails(evt, tabInfo.accountInfo.accountID);
  }

  onAWSRoleSelected(evt: AWSRoleSelectedEvent) {
    this.currentAWSRole = evt.role;
    this.getAWSVMList(this.tabWrapper.tabInfo as VMListTabInfoAWS);
  }

  getVMList() {     
    var tabInfo = this.tabWrapper.tabInfo;
    if (tabInfo instanceof VMListTabInfoAzure) {
      this.getAzureVMList((tabInfo as VMListTabInfoAzure).subscriptions);
    } else if (tabInfo instanceof VMListTabInfoAWS) {
      this.loadTabForAWS(tabInfo as VMListTabInfoAWS);
    }
  }  

  /**
   * 
   * @param uri String URL of api endpoint for next page of response
   * 
   * This is a recursive function, that calls itself with a new api endpoint until a next page no longer exists.
   * Once it hits that point it begins to return a list of VMs found on that page, adding them to an array 
   * consisting of vms from the current page, combined with the list of vms from the later pages
   *
   */
  async getPage(uri){
    var result = []
    return new Promise((resolve,reject) => {
      this.azureAPI.getNextVMPage(uri)                    //call the next page api
        .subscribe(
          page => {
              if(page['nextLink'] == null)                //if this is the final page, we can stop here
                resolve(page['value'])
              else {  
                new Promise((resolve,reject) => {         
                  resolve(this.getPage(page['nextLink'])),//recursive call on the current pages next link
                  error => {                              
                    if (error.status == 401 || error.status == 403) { 
                      resolve([])                         //we shouldnt ever resolve these codes, since this is a nested call...
                    } else {
                      reject(error)
                    }
                  } 
                }).then(function(res: Object[]) {
                  page['value'].forEach(element => {      //iterate over each resolved entry and add it to our result
                    result.push(element)
                  });
                  res.forEach(element => {
                    result.push(element)
                  });
                  resolve(result)                         //resolve the combined list
                });
              }
            },
            error => {
              if (error.status == 401 || error.status == 403) {
                resolve([])
              } else {
                reject(error)
              }
            }
        )
    })
  }

  async getAzureVMList(subscriptions: object[]) {
    this.cloudType = CloudType.AZURE;
    this.subscriptions = subscriptions;
    this.isAzure = true;
    var notAuthorized: boolean = false;
    var hasError: boolean = false;

    var promises = subscriptions.map(sub => {
      return new Promise((resolve, reject) => {
        this.azureAPI.getVMList(sub["id"])
          .subscribe(
            vms => {
              if(vms['nextLink'] != null)
              {
                new Promise((resolve,reject) =>{          //set up a promise so we wait for the response of our nextpage call
                  resolve(this.getPage(vms['nextLink'])),
                  error => {
                    if (error.status == 401 || error.status == 403) {
                      notAuthorized = true;
                      resolve([])
                    } else {
                      reject(error)
                    }
                  }
                }).then(function(values: Object[]) {
                    values.forEach(element => {           //add the machines from the new pages to our master list
                      vms['value'].push(element)
                    });
                    resolve(vms['value'])                 //resolve our final list
                  })
              }
              else {                                      //if there is only one page of results, then we can just return
                resolve(vms['value'])
              }
            },
            error => {
              if (error.status == 401 || error.status == 403) {
                notAuthorized = true;
                resolve([])
              } else {
                reject(error)
              }
            }
          )
      });
    });

    await Promise.all(promises)
      .then(vmsForSub => {
        vmsForSub.forEach(vms => {
          if (vms) {
            this.vmList = this.vmList.concat(vms);
          }
        })      
      })
      .catch(e => {
        hasError = true;
        console.error(e);
      })

    if (this.vmList.length == 0) {
      if (notAuthorized)
        this.onCloseTab.emit(new CloseTabEvent(this.tabWrapper));
      else
        this.noContentMessage = hasError ? "Error: Could not get virtual machines" : this.noContentMessage;
    } else
      this.getAzureVMHealthStatuses();

    this.isLoading = false;
  }

  getAzureVMHealthStatuses() {
    this.subscriptions.forEach(sub => {
      this.azureAPI.getHealthForVMsInSubscription(sub["id"])
      .subscribe(resp => {
        if (resp && resp["value"]) {
          resp["value"].forEach(status => {
            var id = status["id"] || "";
            this.healthStatuses[(id.substr(0, id.indexOf("/providers/Microsoft.ResourceHealth"))).toLowerCase()] = status;
          })
        }
      })
    })
  }

  async loadTabForAWS(tabInfo: VMListTabInfoAWS) {
    if (!this.loadExistingRole(tabInfo))
      await this.checkLoadOnlyRole(tabInfo);    
    this.getAWSVMList(tabInfo);
  }

  getAWSVMList(tabInfo: VMListTabInfoAWS) {
    this.cloudType = CloudType.AWS;
    this.showLoginLink = tabInfo.includeLoginLink;

    this.vmList = [];
    if (tabInfo.accountInfo && this.currentAWSRole) {
      this.isLoading = true;
      this.awsAPI.getVMList(tabInfo.accountInfo.accountID, this.currentAWSRole.roleName)
        .then(vms => {
          if (vms)
            this.vmList = vms || [];
          
          if (this.vmList.length == 0)
            this.noContentMessage = "There are no virtual machines to display";
          
          this.getAWSVMHealthStatuses(tabInfo.accountInfo.accountID);

          this.isLoading = false;
        })
        .catch(e => {
          console.error(e);
          this.noContentMessage = "Error: Could not get virtual machines"
          this.isLoading = false;
        })
    }
    else {
      this.isLoading = false;
    }
  }

  getAWSVMHealthStatuses(accountID: string) {
    var instanceIDs = this.vmList.map(vm => vm.InstanceId);
    this.awsAPI.getHealthForVMs(accountID, instanceIDs)
      .then(resp => {
        if (resp && resp["InstanceStatuses"]) {
          resp["InstanceStatuses"].forEach(status => {
            this.healthStatuses[status.InstanceId] = status;
          });
        }
      });
  }

  loadExistingRole(tabInfo: VMListTabInfoAWS) {
    if (tabInfo && tabInfo.accountInfo) {
      var session = getAWSSession(tabInfo.accountInfo.accountID);
      if (session) {
        this.currentAWSRole = session.roleInfo;
        return true;
      }
      else
        this.noContentMessage = "No AWS Role selected. Please choose a role to continue.";
    }
    return false;
  }

  // Checks if there is only 1 role available and automatically selects it
  async checkLoadOnlyRole(tabInfo: VMListTabInfoAWS) {
    try {
      var roles = searchForValue(tabInfo, ["accountInfo", "roles"]);
        if (roles && roles.length == 1) {
          // Automatically select only role
          var success = await setTempCredentialsFromRole(this.awsAPI, tabInfo.accountInfo.accountID, roles[0]);
          if (success)
            this.currentAWSRole = roles[0];
        }
    } catch(e) { console.error(e); }
  }

  login() {
    if (this.cloudType == CloudType.AWS)
      window.open(environment.AWS.AUTH_ENDPOINT, "_blank");
  }

  getStatusForVM(vmInfo: any) {
    var id = "";

    if (this.cloudType == CloudType.AZURE)
      id = vmInfo.id || "";
    else if (this.cloudType == CloudType.AWS)
      id = vmInfo.InstanceId || "";

    return this.healthStatuses[id.toLowerCase()]
  }

  /************** STATUS MANAGEMENT **************/

  // Notifiy all components of new status change
  onStatusChanged(evt: VMStatusUpdateEvent) {
    var vm = this.gridItems.toArray().find(item => item.vmID == evt.vmStatus.vmID);
    if (vm)
      vm.onRecieveNewStatus(evt.vmStatus)

    if (evt.vmStatus.vmID == this.currentDialogVMId) {
      this.dialog.updateStatus(evt);
    }
  }

  onRequestMonitorStatusChange(evt: VMStatusUpdateEvent) {
    this.onStatusChanged(evt);
    // Check if VM is already pending and being monitored
    if (this.pendingVMs[evt.vmStatus.vmID])
      return;

    if (evt instanceof AzureRequestMonitorStatusEvent)
      this.onMonitorAzureStatusChange(evt as AzureRequestMonitorStatusEvent);
    else if (evt instanceof AWSRequestMonitorStatusEvent)
      this.onMonitorAWSStatusChange(evt as AWSRequestMonitorStatusEvent);
  }

  async onMonitorAzureStatusChange(azureEvent: AzureRequestMonitorStatusEvent) {
    try {
      var details;
      if (azureEvent.asyncUrl) {
        await AzureUtils.monitorAzureStatusWithAsyncUrl(this.azureAPI, azureEvent.vmStatus.vmID, azureEvent.asyncUrl, azureEvent.retrySeconds, this.pendingVMs);
        details = await this.azureAPI.getVMDetails(azureEvent.subscriptionID, azureEvent.resourceGroup, azureEvent.vmName).toPromise()
      } else {
        details = await AzureUtils.monitorAzureStatus(this.azureAPI, azureEvent.vmStatus.vmID, azureEvent.subscriptionID, azureEvent.resourceGroup, azureEvent.vmName, this.pendingVMs, azureEvent.retrySeconds);
      }

      if (details)
        this.onStatusChanged(new VMStatusUpdateEvent(VMStatus.initFromFullAzureDetails(details)));
  
    } catch (e) {
      console.error(e);
    }
  }

  async onMonitorAWSStatusChange(awsEvent: AWSRequestMonitorStatusEvent) {
    try {
      var details = await AWSUtils.monitorAWSStatusChange(this.awsAPI, awsEvent.accountID, awsEvent.vmStatus.vmID, this.pendingVMs, awsEvent.retrySeconds);
      if (details)
        this.onStatusChanged(new VMStatusUpdateEvent(VMStatus.initFromAWSDetails(details)));

    } catch (e) {
      console.error(e);
    }
  }
}
