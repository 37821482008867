import {  EventEmitter} from '@angular/core';

import { VMDetailsBase } from './vm-details-base';
import { AwsApiService } from 'src/app/aws/aws-api/aws-api.service';
import { AlertBroadcastService } from 'src/app/common-components/alert-modal/alert-broadcast.service';
import { AWSOpenDialogEvent, VMStatusUpdateEvent, AWSRequestMonitorStatusEvent } from 'src/app/events';
import { VMDetailsTabInfo, VMVetworkTabInfo, VMSecurityGroupsTabInfo } from 'src/app/common-components/tabs/TabInfo';
import { getVMPermissions } from 'src/app/aws/aws-mic/aws-utils';
import { CloudType, VMState } from 'src/app/constants';
import { VMStatus } from 'src/app/dashboard/VMStatus';
import { searchForValue } from 'src/utils/misc';

export class VMDetailsAWS extends VMDetailsBase {
    awsAPI: AwsApiService;
    openDialogEvent: AWSOpenDialogEvent;
    accountID: string;
    attachedDisks: any[] = [];

    constructor(awsAPI: AwsApiService, alertBroadcast: AlertBroadcastService, openDialogEvent: AWSOpenDialogEvent, accountID: string, onStatusChanged: EventEmitter<VMStatusUpdateEvent>, requestMonitorStatusChange: EventEmitter<VMStatusUpdateEvent>) {        
        super(openDialogEvent.instanceID, openDialogEvent.vmName, openDialogEvent.vmSize, CloudType.AWS, alertBroadcast, onStatusChanged, requestMonitorStatusChange);

        this.awsAPI = awsAPI;
        this.openDialogEvent = openDialogEvent;
        this.accountID = accountID;
        this.cloudType = CloudType.AWS;
    }

    async loadDetails() {
        this.vmDetails = await this.awsAPI.getVMDetails(this.accountID, this.openDialogEvent.instanceID);
        this.getAttachedDisks();
        this.allowedActions = await getVMPermissions(this.awsAPI, this.accountID, this.vmID, this.attachedDisks);
        
        this.updateTabs();
        
        var vmStatus = VMStatus.initFromAWSDetails(this.vmDetails);
        if (vmStatus.vmState == VMState.PENDING) // Wait for status change and update UI
            this.requestMonitorStatusChange.emit(new AWSRequestMonitorStatusEvent(vmStatus, this.accountID));
        else
            this.onStatusChanged.emit(new VMStatusUpdateEvent(vmStatus));
    }

    getAttachedDisks() {
        if (this.vmDetails.BlockDeviceMappings) {
            this.attachedDisks = this.vmDetails.BlockDeviceMappings.map(disk => {
                return { id: searchForValue(disk, ["Ebs", "VolumeId"]), name: disk.DeviceName, snapshotAllowed: false }
            })
        }
    }

    updateTabs() {
        this.vmInfo = new VMDetailsTabInfo(
            "VM Details",
            this.cloudType,
            this.vmDetails,
            this.vmSize,
            this.accountID
        );
        this.updateNetworkTabs(this.vmDetails);
        this.updateSecurityGroupTab(this.vmDetails);
    }

    updateNetworkTabs(vmDetails) {
        if (vmDetails.NetworkInterfaces) {
            this.networkInterfacesTabs = vmDetails.NetworkInterfaces.map((nic, i) => {
                return new VMVetworkTabInfo(
                    "Network" + (vmDetails.NetworkInterfaces.length > 1 ? " " + (i + 1) : ""),
                    this.cloudType,
                    nic,
                    this.accountID
                );
            });
        }
    }

    updateSecurityGroupTab(vmDetails: object) {
        this.securityGroupsTab = new VMSecurityGroupsTabInfo(
            `Security Groups`,
            this.cloudType,
            vmDetails["SecurityGroups"].map(group => group.GroupId),
            this.accountID
        );
    }

    isAzure() { return false; }

    startVM() { this.performAction("startVM", "start", "StartingInstances"); }
    stopVM() { this.performAction("stopVM", "stop", "StoppingInstances"); }

    performAction(func: string, action: string, responseKey: string) {
        if (!this.allowedActions[action])
            return;

        this.alertBroadcast.broadcastConfirm(`Are you sure that you want to ${action} "${this.openDialogEvent.vmName}"?`, () => {
            this.awsAPI[func](this.accountID, this.vmID)
                .then(response => {
                    if (response[responseKey] && response[responseKey].length > 0) {
                        this.requestMonitorStatusChange.emit(new AWSRequestMonitorStatusEvent(
                            VMStatus.init(this.vmID, VMState.PENDING, this.vmSize),
                            this.accountID));
                    }
                })
        })
    }

    getAccountID() { return this.accountID; }
}