export enum CloudType {
    AZURE,
    AWS
}

export enum VMState {
    RUNNING,
    PENDING,
    STOPPED,
    DEALLOCATED
}

export const AWSStatusCodes = {
    Pending: 0,
    Running: 16,
    ShuttingDown: 32,
    Terminated: 48,
    Stopping: 64,
    Stopped: 80
}