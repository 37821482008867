/**
 * This Angular service is responsible for all communication
 * between the application and Azure. A singleton instance
 * of this class is available to all components via
 * dependency injection. Each function is responsible for
 * pulling the user's authentication token out of
 * local storage and passing it along with the API
 * in the headers. 
 * 
*/
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AlertBroadcastService } from 'src/app/common-components/alert-modal/alert-broadcast.service';
import { getAzureToken, getAzureUserID, hasValidAzureToken, clearAzureSession } from "src/utils/storageHelper";
import { VMConfig } from 'src/app/dashboard/vm-create-modal/VMConfig';

@Injectable({
  providedIn: 'root'
})
export class AzureApiService {

  constructor(
    private http: HttpClient,
    private alertBroadcast: AlertBroadcastService) { }

  getVMList(subscriptionID: string) {
    return this.http.get(`https://management.azure.com/subscriptions/${subscriptionID}/providers/Microsoft.Compute/virtualMachines?api-version=2017-12-01`,
      this.setAuthTokenHeader())
        .pipe(catchError(this.handleError("getVMList", [], false)));
  }

  getNextVMPage(uri: string) {
    return this.http.get(uri,
      this.setAuthTokenHeader())
        .pipe(catchError(this.handleError("getNextVMPage", [], false)));
  }

  getAvailableActionsForVM(subscriptionID: string, resourceGroup: string, vmName: string) {
    return this.http.get(`https://management.azure.com/subscriptions/${subscriptionID}/resourcegroups/${resourceGroup}/providers/Microsoft.Compute/virtualMachines/${vmName}/providers/Microsoft.Authorization/permissions?api-version=2015-07-01 `,
      this.setAuthTokenHeader())
        .pipe(catchError(this.handleError("getAvailableActionsForVM", {}))); 
  }

  getVMInstanceView(subscriptionID: string, resourceGroup: string, vmName: string) {
    return this.http.get(`https://management.azure.com/subscriptions/${subscriptionID}/resourceGroups/${resourceGroup}/providers/Microsoft.Compute/virtualMachines/${vmName}/instanceView?api-version=2018-06-01`,
      this.setAuthTokenHeader())
        .pipe(catchError(this.handleError("getVMInstanceView", {})));
  }

  getVMDetails(subscriptionID: string, resourceGroup: string, vmName: string) {
    return this.http.get(`https://management.azure.com/subscriptions/${subscriptionID}/resourceGroups/${resourceGroup}/providers/Microsoft.Compute/virtualMachines/${vmName}?$expand=instanceView&api-version=2017-12-01`,
      this.setAuthTokenHeader())
        .pipe(catchError(this.handleError("getVMDetails", {})));
  }

  getOperationStatus(operationStatusURL) {
    return this.http.get(operationStatusURL, this.setAuthTokenHeader())
      .pipe(catchError(this.handleError("getOperationStatus", {})));
  }

  startVM(subscriptionID: string, resourceGroup: string, vmName: string): Observable<any> {
    return this.performVMAction(subscriptionID, resourceGroup, vmName, "start")
  }

  stopVM(subscriptionID: string, resourceGroup: string, vmName: string) {
    return this.performVMAction(subscriptionID, resourceGroup, vmName, "powerOff")
  }

  deallocateVM(subscriptionID: string, resourceGroup: string, vmName: string) {
    return this.performVMAction(subscriptionID, resourceGroup, vmName, "deallocate")
  }

  restartVM(subscriptionID: string, resourceGroup: string, vmName: string) {
    return this.performVMAction(subscriptionID, resourceGroup, vmName, "restart")
  }

  getPropertyForIdUrl(idUrl: string, apiVersion: string, expand: string = null) {
    return this.http.get(`https://management.azure.com${idUrl}?api-version=${apiVersion}${expand ? "&$expand=" + expand : ""}`, this.setAuthTokenHeader())
      .pipe(catchError(this.handleError("getPropertyForIdUrl", {})));
  }

  deleteSnapshot(subscriptionID: string, resourceGroup: string, snapshotName: string) {
    return this.http.delete(`https://management.azure.com/subscriptions/${subscriptionID}/resourceGroups/${resourceGroup}/providers/Microsoft.Compute/snapshots/${snapshotName}?api-version=2018-06-01`, this.setAuthTokenHeader())
      .pipe(catchError(this.handleError("deleteSnapshot", {})));
  }

  createSnapshot (subscriptionID: string, resourceGroup: string, diskName: string, location: string, snapshotName: string) {
    var body = {
      "properties": {
        "creationData": {
          "createOption": "Copy",
          "sourceResourceId": `/subscriptions/${subscriptionID}/resourceGroups/${resourceGroup}/providers/Microsoft.Compute/disks/${diskName}`
        }
      },
      "type": "Microsoft.Compute/snapshots",
      "location": location
    }
    return this.http.put(`https://management.azure.com/subscriptions/${subscriptionID}/resourceGroups/${resourceGroup}/providers/Microsoft.Compute/snapshots/${snapshotName}?api-version=2018-06-01`, body,
      this.createHttpOptions({ 'Content-Type': 'application/json', ...this.getAuthTokenHeader() }, true))
        .pipe(catchError(this.handleError("createSnapshot", {})));
  }

  getSubnet(subscriptionID: string, resourceGroup: string, vnetName: string, subnetName: string) {
    return this.http.get(`https://management.azure.com/subscriptions/${subscriptionID}/resourceGroups/${resourceGroup}/providers/Microsoft.Network/virtualNetworks/${vnetName}/subnets/${subnetName}?api-version=2018-08-01`, this.setAuthTokenHeader())
      .pipe(catchError(this.handleError("getSubnet", {})));
  }
  
  resizeVM(subscriptionID: string, resourceGroup: string, vmName: string, vmSize: string) {
    var body = {
       "properties": {
         "hardwareProfile": {
           "vmSize": vmSize
         }
       },
       "id": `/subscriptions/${subscriptionID}/resourceGroups/${resourceGroup}/providers/Microsoft.Compute/virtualMachines/${vmName}`
    }
    return this.http.patch(`https://management.azure.com/subscriptions/${subscriptionID}/resourceGroups/${resourceGroup}/providers/Microsoft.Compute/virtualMachines/${vmName}?api-version=2017-12-01`, body,
      this.createHttpOptions({ 'Content-Type': 'application/json', ...this.getAuthTokenHeader() }, true))
        .pipe(catchError(this.handleError("resizeVM", {})));
  }

  listVMSizes(subscriptionID: string, location: string) {
    return this.http.get(`https://management.azure.com/subscriptions/${subscriptionID}/providers/Microsoft.Compute/locations/${location}/vmSizes?api-version=2018-06-01`,
      this.setAuthTokenHeader())
      .pipe(catchError(this.handleError("listVMSizes", [])));
  }

  getVM(subscriptionID: string, resourceGroup: string, vmName: string) {
    return this.http.get(`https://management.azure.com/subscriptions/${subscriptionID}/resourceGroups/${resourceGroup}/providers/Microsoft.Compute/virtualMachines/${vmName}?api-version=2018-06-01`,
      this.setAuthTokenHeader())
      .pipe(catchError(this.handleError("getVM", [])));
  }

  getDisk(subscriptionID: string, resourceGroup: string, diskName: string) {
    return this.http.get(`https://management.azure.com/subscriptions/${subscriptionID}/resourceGroups/${resourceGroup}/providers/Microsoft.Compute/disks/${diskName}?api-version=2018-06-01`,
      this.setAuthTokenHeader())
      .pipe(catchError(this.handleError("getDisk", [])));
  }

  detachDisk(subscriptionID: string, resourceGroup: string, vmName: string, dataDisks: any) {
    var body = {
      "properties": {
        "storageProfile": {
          "dataDisks": dataDisks
        }
      },
      "id": `/subscriptions/${subscriptionID}/resourceGroups/${resourceGroup}/providers/Microsoft.Compute/virtualMachines/${vmName}`
    }

    return this.http.patch(`https://management.azure.com/subscriptions/${subscriptionID}/resourceGroups/${resourceGroup}/providers/Microsoft.Compute/virtualMachines/${vmName}?api-version=2018-06-01`, body,
      this.createHttpOptions({ 'Content-Type': 'application/json', ...this.getAuthTokenHeader() }, true))
        .pipe(catchError(this.handleError("detachDisk", {})));
  }

  attachOSDisk(subscriptionID: string, resourceGroup: string, vmName: string, diskName: string) {
    var osDisk = {
      "createOption": "FromImage",
      "managedDisk": {
        "id": `/subscriptions/${subscriptionID}/resourceGroups/${resourceGroup}/providers/Microsoft.Compute/disks/${diskName}`
      }
    }

    var body = {
      "properties": {
        "storageProfile": {
          "osDisk": osDisk
        }
      },
      "id": `/subscriptions/${subscriptionID}/resourceGroups/${resourceGroup}/providers/Microsoft.Compute/virtualMachines/${vmName}`
    }

    return this.http.patch(`https://management.azure.com/subscriptions/${subscriptionID}/resourceGroups/${resourceGroup}/providers/Microsoft.Compute/virtualMachines/${vmName}?api-version=2018-06-01`, body,
      this.createHttpOptions({ 'Content-Type': 'application/json', ...this.getAuthTokenHeader() }, true))
        .pipe(catchError(this.handleError("attachDisk", {})));
  }

  attachDisk(subscriptionID: string, resourceGroup: string, vmName: string, lun: string, diskName: string) {
    var dataDisks = [
      {
        "lun": lun,
        "createOption": "Attach",
        "managedDisk": {
          "id": `/subscriptions/${subscriptionID}/resourceGroups/${resourceGroup}/providers/Microsoft.Compute/disks/${diskName}`
        }
      }
    ]

    var body = {
      "properties": {
        "storageProfile": {
          "dataDisks": dataDisks
        }
      },
      "id": `/subscriptions/${subscriptionID}/resourceGroups/${resourceGroup}/providers/Microsoft.Compute/virtualMachines/${vmName}`
    }

    return this.http.patch(`https://management.azure.com/subscriptions/${subscriptionID}/resourceGroups/${resourceGroup}/providers/Microsoft.Compute/virtualMachines/${vmName}?api-version=2018-06-01`, body,
      this.createHttpOptions({ 'Content-Type': 'application/json', ...this.getAuthTokenHeader() }, true))
        .pipe(catchError(this.handleError("attachDisk", {})));
  }

  createVM(vmConfig: VMConfig) {
    var publisher;
    var offer;
    var version = 'latest';

    if(vmConfig.sku.includes("Datacenter") )
    {
      publisher = 'MicrosoftWindowsServer';
      offer = 'WindowsServer';
    }else{
      publisher = 'MicrosoftWindowsDesktop';
      offer = 'Windows-10';
    }

    var dataDisks = [];
    var count = 1;
    if (vmConfig.dataDisksArray.length > 0) {
      for(let disk of vmConfig.dataDisksArray) {
        var diskJson = 
        {
          "lun": count,
          "name": disk["fullName"],
          "createOption": "Attach",
          "caching": disk["diskCachingType"],
          "writeAcceleratorEnabled": false,
          "managedDisk": {
            "storageAccountType": disk["diskType"],
            "id": `/subscriptions/${vmConfig.selectedSubscriptionID}/resourceGroups/${vmConfig.vmResourceGroup}/providers/Microsoft.Compute/disks/${disk["fullName"]}`
          },
          "diskSizeGB": disk["diskSize"]
        }
        dataDisks.push(diskJson);
        count++;
      }
    }

    var body = {
      "location": vmConfig.region,
      "name": vmConfig.generatedVMName,
      "properties": {
        "hardwareProfile": {
          "vmSize": vmConfig.vmSize
        },
        "storageProfile": {
          "imageReference": {
            "sku": vmConfig.sku,
            "publisher": publisher,
            "version": version,
            "offer": offer
          },
          "osDisk": {
            "caching": "ReadWrite",
            "managedDisk": {
              "storageAccountType": vmConfig.osDiskType
            },
            "name": `${vmConfig.generatedVMName}-osDisk`,
            "createOption": "FromImage"
          },
            "dataDisks": dataDisks
        },
        "osProfile": {
          "adminUsername": vmConfig.username,
          "adminPassword": vmConfig.password,
          "computerName": vmConfig.generatedVMName,
          "windowsConfiguration": {
            "provisionVMAgent": true,
            "enableAutomaticUpdates": true
          },
          "allowExtensionOperations": true
        },
        "networkProfile": {
          "networkInterfaces": [
            {
              "id": `/subscriptions/${vmConfig.selectedSubscriptionID}/resourceGroups/${vmConfig.vmResourceGroup}/providers/Microsoft.Network/networkInterfaces/${vmConfig.nicName}`,
              "properties": {
                "primary": true
              }
            }
          ]
        },
        "securityProfile": {
          "encryptionAtHost": true
        }
      },
      "tags": {
        "Sector": vmConfig.tagSector,
        "Business": vmConfig.tagBusiness,
        "Project": vmConfig.tagProject,
        "CostCenter": vmConfig.tagCostCenter,
        "Environment": vmConfig.tagEnvironment,
        "ExpirationDate": vmConfig.tagExpirationDate,
        "Role": vmConfig.tagRole,
        "ApplicationName": vmConfig.tagApplicationName,
        "PrimaryContact": vmConfig.tagPrimaryContact,
        "Schedule": vmConfig.tagSchedule,
        "ComplianceRequirement": vmConfig.tagComplianceRequirement,
        "Confidentialitylevel": vmConfig.tagConfidentialityLevel,
        "DRTier": vmConfig.tagDRTier,
        "Workgroup": vmConfig.tagWorkgroup
      }
    }
    return this.http.put(`https://management.azure.com/subscriptions/${vmConfig.selectedSubscriptionID}/resourceGroups/${vmConfig.vmResourceGroup}/providers/Microsoft.Compute/virtualMachines/${vmConfig.generatedVMName}?api-version=2024-07-01`, body, this.createHttpOptions({ 'Content-Type': 'application/json', ...this.getAuthTokenHeader() }, true))
      .pipe(catchError(this.handleError("createVM", {})));
  }

  deleteDisk(subscriptionID: string, resourceGroup: string, diskName) {
    return this.http.delete(`https://management.azure.com/subscriptions/${subscriptionID}/resourceGroups/${resourceGroup}/providers/Microsoft.Compute/disks/${diskName}?api-version=2018-06-01`, this.setAuthTokenHeader())
      .pipe(catchError(this.handleError("deleteDisk", {})));
  }

  createDataDisk(subscriptionID: string, resourceGroup: string, diskName: string, location: string, diskSize: string) {
    var body = {
      "name": diskName,
      "location": location,
      "properties": {
        "creationData": {
          "createOption": "Empty"
        },
        "diskSizeGB": diskSize
      }
    }
    return this.http.put(`https://management.azure.com/subscriptions/${subscriptionID}/resourceGroups/${resourceGroup}/providers/Microsoft.Compute/disks/${diskName}?api-version=2018-06-01`, body, this.createHttpOptions({ 'Content-Type': 'application/json', ...this.getAuthTokenHeader() }, true))
      .pipe(catchError(this.handleError("createDataDisk", {})));
  }

  createDataDiskFromSnapshot(subscriptionID: string, resourceGroup: string, snapshotName: string, diskName: string, location: string) {
    var body = {
      "name": diskName,
      "location": location,
      "properties": {
        "creationData": {
          "createOption": "Copy",
          "sourceResourceId": `subscriptions/${subscriptionID}/resourceGroups/${resourceGroup}/providers/Microsoft.Compute/snapshots/${snapshotName}`
        }
      }
    }
    return this.http.put(`https://management.azure.com/subscriptions/${subscriptionID}/resourceGroups/${resourceGroup}/providers/Microsoft.Compute/disks/${diskName}?api-version=2018-06-01`, body, this.createHttpOptions({ 'Content-Type': 'application/json', ...this.getAuthTokenHeader() }, true))
      .pipe(catchError(this.handleError("createDataDiskFromSnapshot", {})));
  }

  createNic(subscriptionID: string, resourceGroup: string, nicName: string, virtualNetwork: string, subnet: string, location: string, vnetResourceGroup: string) {
    var body = {
      "location": location,
      "properties": {
        "enableAcceleratedNetworking": false,
        "ipConfigurations": [
          {
            "name": nicName,
            "properties": {
              "privateIPAllocationMethod": "Dynamic",
              "subnet": {
                "id": `/subscriptions/${subscriptionID}/resourceGroups/${vnetResourceGroup}/providers/Microsoft.Network/virtualNetworks/${virtualNetwork}/subnets/${subnet}`
              }
            }
          }
        ]
      }
    }
    return this.http.put(`https://management.azure.com/subscriptions/${subscriptionID}/resourceGroups/${resourceGroup}/providers/Microsoft.Network/networkInterfaces/${nicName}?api-version=2018-08-01`, body,
      this.createHttpOptions({ 'Content-Type': 'application/json', ...this.getAuthTokenHeader() }, true))
        .pipe(catchError(this.handleError("createNic", {})));
  }

  createResourceGroup(subscriptionID: string, resourceGroup: string, location: string) {
    var body = {
      location: location
    }
    return this.http.put(`https://management.azure.com/subscriptions/${subscriptionID}/resourcegroups/${resourceGroup}?api-version=2018-05-01`, body, this.createHttpOptions({ 'Content-Type': 'application/json', ...this.getAuthTokenHeader() }, true))
      .pipe(catchError(this.handleError("createResourceGroup", {})));
  }

  createVirtualNetwork(subscriptionID: string, resourceGroup: string, vnetName: string, location: string, vnetCidr: string) {
    var body = {
      "name": vnetName,
      "type": "Microsoft.Network/virtualNetworks",
      "location": location,
      "tags": {},
      "properties": {
        "addressSpace": {
          "addressPrefixes": [
            vnetCidr
          ]
        },
        "subnets": [],
      }
    }
    return this.http.put(`https://management.azure.com/subscriptions/${subscriptionID}/resourceGroups/${resourceGroup}/providers/Microsoft.Network/virtualNetworks/${vnetName}?api-version=2018-08-01`, body, this.createHttpOptions({ 'Content-Type': 'application/json', ...this.getAuthTokenHeader() }, true))
      .pipe(catchError(this.handleError("createVirtualNetwork", {})));
  }

  createSubnet(subscriptionID: string, subnetName: string, resourceGroup: string, vnetName: string, subnetCidr: string) {
    var body = {
      "name": subnetName,
      "properties": {
        "addressPrefix": subnetCidr,
        "ipConfigurations": [],
      },
      "type": "Microsoft.Network/virtualNetworks/subnets"
    }
    return this.http.put(`https://management.azure.com/subscriptions/${subscriptionID}/resourceGroups/${resourceGroup}/providers/Microsoft.Network/virtualNetworks/${vnetName}/subnets/${subnetName}?api-version=2018-08-01`, body, this.createHttpOptions({ 'Content-Type': 'application/json', ...this.getAuthTokenHeader() }, true))
      .pipe(catchError(this.handleError("createSubnet", {})));
  }  

  listResourceGroups(subscriptionID: string) {
    return this.http.get(`https://management.azure.com/subscriptions/${subscriptionID}/resourcegroups?api-version=2018-05-01`,this.setAuthTokenHeader())
      .pipe(catchError(this.handleError("listResourceGroups", [])))
  }

  listVirtualNetworks(subscriptionID: string, resourceGroup: string, location: string) {
    return this.http.get(`https://management.azure.com/subscriptions/${subscriptionID}/resources?$filter=resourceType%20eq%20'Microsoft.Network/virtualnetworks'%20and%20location eq%20'${location}'%20and%20resourceGroup eq%20'${resourceGroup}'&api-version=2018-08-01`, this.setAuthTokenHeader())
      .pipe(catchError(this.handleError("listVirtualNetworks", [])));
  }

  listSubnets(subscriptionID: string, resourceGroup: string, vnetName: string) {
    return this.http.get(`https://management.azure.com/subscriptions/${subscriptionID}/resourceGroups/${resourceGroup}/providers/Microsoft.Network/virtualNetworks/${vnetName}/subnets?api-version=2018-08-01`,
    this.setAuthTokenHeader())
      .pipe(catchError(this.handleError("listSubnets", [])));
  }

  getVirtualNetwork(subscriptionID: string, resourceGroup: string, vnetName: string) {
    return this.http.get(`https://management.azure.com/subscriptions/${subscriptionID}/resourceGroups/${resourceGroup}/providers/Microsoft.Network/virtualNetworks/${vnetName}?api-version=2018-08-01`,
    this.setAuthTokenHeader())
      .pipe(catchError(this.handleError("getVirtualNetwork", {})));
  }

  getHealthForVMsInSubscription(subscriptionID: string) {
    return this.http.get(`https://management.azure.com/subscriptions/${subscriptionID}/providers/Microsoft.ResourceHealth/availabilityStatuses?api-version=2015-01-01&%24expand=recommendedactions&%24filter=resourceType%20eq%20%27Microsoft.Compute%2FvirtualMachines%27`,
    this.setAuthTokenHeader())
      .pipe(catchError(this.handleError("getHealthForVMsInSubscription", {}, false)));
  }

  getSnapshots(subscriptionID: string, resourceGroup: string) {
    return this.http.get(`https://management.azure.com/subscriptions/${subscriptionID}/resourceGroups/${resourceGroup}/providers/Microsoft.Compute/snapshots?api-version=2018-06-01`, 
      this.setAuthTokenHeader())
        .pipe(catchError(this.handleError("getSnapshots", [])));
  }

  private performVMAction(subscriptionID: string, resourceGroup: string, vmName: string, action: string) {
    return this.http.post(`https://management.azure.com/subscriptions/${subscriptionID}/resourceGroups/${resourceGroup}/providers/Microsoft.Compute/virtualMachines/${vmName}/${action}?api-version=2017-12-01`, null,
      this.createHttpOptions({ 'Content-Type': 'application/json', ...this.getAuthTokenHeader() }, true))
        .pipe(catchError(this.handleError("performVMAction", {})));
  }

  // Gets the user's auth token out of local storage
  // and sets as a header
  private setAuthTokenHeader() {
    return this.createHttpOptions(this.getAuthTokenHeader(), false);
  }

  private getAuthTokenHeader() {
    return { 'Authorization': 'Bearer ' + getAzureToken() }
  }
  
  private createHttpOptions(headers, observeResponse) {
    var httpOptions = { 
      headers: new HttpHeaders(headers)
    };

    if (observeResponse)
      httpOptions['observe'] = 'response';

    return httpOptions;
  }

  // Error handler for all Azure API calls. If the user's session is invalid
  // it sends an alert broadcast and refreshes the page. It will also inform
  // the user if they try to perform an action that they are not
  // authorized to perform.
  private handleError<T> (operation:string, result?: T, broadcastUnauthorized: boolean = true) {
    return (error: any): Observable<T> => {

      console.error(`Error performing: ${operation}`)
      console.error(error);
   
      if (error.status == 401 || !hasValidAzureToken()) {
        this.alertBroadcast.broadcastFail("Your Azure session has expired. Please log in again.", () => {
          clearAzureSession();
          location.reload();
        });
      } else if (error.status == 403) {
        if (broadcastUnauthorized)
          this.alertBroadcast.broadcastFail("You are not authorized to perform this action.");
        else
          throw error;
      }
      
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
