import config from "../config";
import { AWSAccountCredentials } from 'src/app/aws/aws-auth/aws-auth';
import { environment } from 'src/environments/environment';

export function setAzureToken(token: string, expiration: string) {
    var expirationDate = new Date();
    expirationDate.setSeconds(expirationDate.getSeconds() + (+expiration || 0));

    localStorage.setItem(config.storageConstants.AZURE_TOKEN, token);
    localStorage.setItem(config.storageConstants.AZURE_TOKEN_EXP, expirationDate.toISOString());
}

export function getAzureTokenExpirationDate() {
    var expDate = localStorage.getItem(config.storageConstants.AZURE_TOKEN_EXP);
    if (expDate)
        return new Date(expDate);
    return new Date();
}

export function getAzureToken() {
    return localStorage.getItem(config.storageConstants.AZURE_TOKEN);
}

export function getAzureUserID() {
    var id = getAttributesFromClaim(["oid"]);
    if (id && id.length > 0)
        return id[0];
    return null;
}

export function getAzureUserEmail() {
    return getAttributesFromClaim(["email", "unique_name", "upn"]);
}

function getAttributesFromClaim(attribute: string[]) {
    try {
        var token = getAzureToken();
        if (token) {
            var parts = token.split('.');
            if (parts && parts.length == 3) {
                var claim = JSON.parse(atob(parts[1]));
                if (claim)
                    return attribute.map(attr => claim[attr])
            }
        }
    }
    catch (e) { console.error(e); }
    return null;
}

export function hasValidAzureToken() {
    return getAzureToken() && getAzureTokenExpirationDate() > new Date();
}

export function clearAzureSession() {
    localStorage.removeItem(config.storageConstants.AZURE_TOKEN);
    localStorage.removeItem(config.storageConstants.AZURE_TOKEN_EXP);
}

export function setAWSAssertion(assertion: string) {
    localStorage.setItem(config.storageConstants.AWS_ASSERTION, assertion);
}

export function getAWSAssertion() {
    return localStorage.getItem(config.storageConstants.AWS_ASSERTION);
}

export function setAWSSession(accountCredentials: AWSAccountCredentials) {
    localStorage.setItem(`${config.storageConstants.AWS_SESSION_FOR_ACCOUNT}.${accountCredentials.accountID}`, btoa(JSON.stringify(accountCredentials)));
}

export function getAWSSession(accountID: string): AWSAccountCredentials {
    var session = localStorage.getItem(`${config.storageConstants.AWS_SESSION_FOR_ACCOUNT}.${accountID}`)
    if (session)
        return JSON.parse(atob(session)) as AWSAccountCredentials;
    return null;
}

export function hasValidAWSSession(accountID: string) {
    var session = getAWSSession(accountID);
    return session && new Date(session.expirationDate) > new Date();
}

export function clearAWSSessionForAccount(accountID: string) {
    localStorage.removeItem(`${config.storageConstants.AWS_SESSION_FOR_ACCOUNT}.${accountID}`);
}

export function clearAllAWSSessions() {
    var accounts = Object.keys(environment.AWS.ACCOUNTS);
    accounts.forEach(accountID => clearAWSSessionForAccount(accountID));
    localStorage.removeItem(config.storageConstants.AWS_ASSERTION);
}
