import { Component, ViewChild } from '@angular/core';

import { StargateApiService } from 'src/app/stargate-api/stargate-api.service';
import { VMDetailsBase, VMDetailsAzure, VMDetailsAWS } from 'src/app/vm-details/details-modal/data-models';
import { AlertBroadcastService } from 'src/app/common-components/alert-modal/alert-broadcast.service';
import { AddScheduleEvent } from 'src/app/events';

@Component({
  selector: 'app-vm-scheduler-modal',
  templateUrl: './vm-scheduler-modal.component.html',
  styleUrls: ['./vm-scheduler-modal.component.css']
})

export class VmSchedulerModalComponent {
  @ViewChild('content') public contentModal;

  vmDetails: VMDetailsBase;
  allowedActions: object = {};
  tableHeaders = ['Weekday', 'Action', 'Time', 'Timezone', ''];
  scheduledItems: any = [];

  isLoading: boolean = true;

  constructor(
    private startgateAPI: StargateApiService,
    private alertBroadcast: AlertBroadcastService) { }

  show(vmDetails: VMDetailsBase, allowedActions: object) {
    this.vmDetails = vmDetails;
    this.allowedActions = allowedActions;
    this.contentModal.show();
    this.isLoading = true;
    
    this.startgateAPI.getSchedule(this.vmDetails.vmID, this.vmDetails.cloudType, this.vmDetails["accountID"])
      .subscribe(resp => {
        this.scheduledItems = this.formatSchedules(resp["schedules"]);
        this.isLoading = false;
      })
  }

  onScheduleAdded(evt: AddScheduleEvent) {
    if (!this.allowedActions["start"] && !this.allowedActions["stop"])
      return;
    this.isLoading = true;
    this.startgateAPI.setSchedule(this.vmDetails.vmID, this.vmDetails.cloudType, this.createRequestBody(evt.schedules), this.vmDetails["accountID"])
      .subscribe(resp => {
        if (resp["updatedSchedules"])
          this.scheduledItems = this.formatSchedules(resp["schedules"]);
        this.isLoading = false;
      });
  }

  createRequestBody(schedules: object[]) {
    var body = {
      schedules,
      vmDetails: {}
    };

    if (this.vmDetails instanceof VMDetailsAzure) {
      var azureDetails = this.vmDetails as VMDetailsAzure;

      body.vmDetails["subscriptionId"] = azureDetails.getSubscriptionID();
      body.vmDetails["resourceGroup"] = azureDetails.getResourceGroup();
      body.vmDetails["vmName"] = azureDetails.getVMName();
    } else if (this.vmDetails instanceof VMDetailsAWS) {
      var awsDetails = this.vmDetails as VMDetailsAWS

      body.vmDetails["accountId"] = awsDetails.getAccountID();
    }

    return body;
  }

  formatSchedules(schedules: object[]) {
    if (!schedules)
      return [];
    return schedules.map(schedule => {
      var time = schedule["timeReadable"] || {};
      return {
        actionId: schedule["actionId"],
        dayOfWeek: time["day"],
        time: time["timeOfDay"],
        action: schedule["action"],
        timeZone: time["timeZone"]
      };
    })
  }

  deleteSchedule(schedule: object) {
    if (!this.canDeleteSchedule(schedule))
      return;
    this.alertBroadcast.broadcastConfirm(`Are you sure that you want to delete this item?`, () => {
        this.isLoading = true;
        this.startgateAPI.deleteSchedule(this.vmDetails.vmID, this.vmDetails.cloudType, schedule["actionId"], this.vmDetails["accountID"])
          .subscribe(resp => {
            this.isLoading = false;
            this.scheduledItems = this.formatSchedules(resp["schedules"]);              
          });
    })
  }  

  canDeleteSchedule(schedule: any) {
    return this.allowedActions[schedule.action.toLowerCase()];
  }

  scheduleColor(schedule: any){
    if(schedule.action.toLowerCase() == 'stop')
    {
      return 'red'
    }
    return 'darkgreen'
  }
}
