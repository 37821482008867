/**
 * Collection of tabs. The TabGroup is responsible for
 * determining what happens when a tab is clicked.
 */

import { Component, AfterContentInit, ContentChildren, QueryList } from '@angular/core';

import { TabComponent } from '../tab/tab.component';

@Component({
  selector: 'app-tab-group',
  templateUrl: './tab-group.component.html',
  styleUrls: ['./tab-group.component.css']
})
export class TabGroupComponent implements AfterContentInit {
  
  @ContentChildren(TabComponent) tabs: QueryList<TabComponent>;

  // contentChildren are set
  ngAfterContentInit() {
    // Wait for next verification loop
    Promise.resolve(null).then(() =>{
      // get all active tabs
      let activeTabs = this.tabs.filter((tab)=>tab.active);
      
      // if there is no active tab set, activate the first
      if(activeTabs.length === 0) {
        this.selectFirstTab();
      }
    });
  }

  selectFirstTab() {
    this.selectTab(this.tabs.first);
  }
  
  selectTab(tab: TabComponent) {
    // deactivate all tabs
    this.tabs.toArray().forEach(t => t.active = false);
    
    // activate the tab the user has clicked on.
    tab.active = true;
  }
}
