import { VMState, AWSStatusCodes } from 'src/app/constants';
import { searchForValue } from 'src/utils/misc';

export class VMStatus {
    vmID: string;
    vmState: VMState;
    statusText: string;
    vmSize: string;

    private constructor(vmID: string, vmState: VMState, statusText: string, vmSize: string) {
        this.vmID = vmID;
        this.vmState = vmState;
        this.statusText = statusText;
        this.vmSize = vmSize;
    }

    updateState(vmState: VMState) {
        this.vmState = vmState;
        this.statusText = VMStatus.getTextForState(vmState);
    }

    /************** AZURE **************/
    static initFromAzureInstanceView(vmID: string, instanceView: object, vmSize: string) {
        var state = VMStatus.getAzureVMState(instanceView);
        var statusText = VMStatus.getTextForState(state);
        return new VMStatus(vmID.toLowerCase(), state, statusText, vmSize);
    }

    static initFromFullAzureDetails(details: object) {
        var vmID = details["id"];
        var instanceView = searchForValue(details, ["properties", "instanceView"]);
        var vmSize = searchForValue(details, ["properties", "hardwareProfile", "vmSize"]);
        return VMStatus.initFromAzureInstanceView(vmID, instanceView, vmSize);
    }

    static getAzureVMState(instanceView: object): VMState {
        if (instanceView) {
            var statuses = instanceView["statuses"];
            if (statuses) {
                var powerState = statuses.find(status => status.code.indexOf("PowerState") != -1);
                powerState = powerState ? powerState.code : "PowerState/stopped";

                switch (powerState) {
                    case "PowerState/deallocating":
                    case "PowerState/starting":
                    case "PowerState/stopping":
                        return VMState.PENDING;

                    case "PowerState/stopped":
                    case "PowerState/unknown":
                        return VMState.STOPPED;

                    case "PowerState/deallocated":
                        return VMState.DEALLOCATED;

                    case "PowerState/running":
                        return VMState.RUNNING;
                }
            }
        }
        return VMState.STOPPED;
    }

    /************** AWS **************/
    static initFromAWSDetails(vmDetails: object) {
        var vmID = vmDetails["InstanceId"];
        var statusCode = searchForValue(vmDetails, ["State", "Code"])
        if (statusCode == null)
            statusCode = AWSStatusCodes.Stopped; // Default to stopped
        var vmState = this.getAWSVMState(statusCode);
        var statusText = this.getTextForState(vmState);
        var vmSize = vmDetails["InstanceType"];
        return new VMStatus(vmID, vmState, statusText, vmSize);
    }

    static getAWSVMState(statusCode: number) {
        switch (statusCode) {
            case AWSStatusCodes.Pending:
            case AWSStatusCodes.ShuttingDown:
            case AWSStatusCodes.Stopping:
              return VMState.PENDING;
      
            case AWSStatusCodes.Terminated:
            case AWSStatusCodes.Stopped:
              return VMState.STOPPED;
            
            case AWSStatusCodes.Running:
              return VMState.RUNNING;
          }
          return VMState.STOPPED;
    }

    /************** Shared **************/
    static init(vmID: string, vmState: VMState, vmSize: string = null) {
        return new VMStatus(vmID.toLowerCase(), vmState, VMStatus.getTextForState(vmState), vmSize);
    }

    private static getTextForState(vmState: VMState) {
        switch (vmState) {
            case VMState.RUNNING:
                return "Running";
            case VMState.PENDING:
                return "Pending...";
            case VMState.DEALLOCATED:
                return "Stopped (deallocated)";
            default:
                return "Stopped";
        }
    }
}

