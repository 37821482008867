import { Component, ViewChild, EventEmitter, Output } from '@angular/core';
import { AlertBroadcastService } from 'src/app/common-components/alert-modal/alert-broadcast.service';

@Component({
  selector: 'app-vm-create-data-disks',
  templateUrl: './vm-create-data-disks.component.html',
  styleUrls: ['./vm-create-data-disks.component.css'],
  exportAs: 'datadisks'
})
export class VmCreateDataDisksComponent {

  diskName: string = '';
  diskSize: string = '';
  diskType: string = 'StandardSSD_LRS';
  diskCachingType: string = 'ReadOnly';
  disksArray: any[] = [];
  notValidatedForm: boolean = true;

  @ViewChild('datadisks') public datadisks;
  @Output() disksChange = new EventEmitter();

  constructor(private alertBroadcast: AlertBroadcastService) { }

  // disks may deleted in the parent modal, which means we have to pass the updated disk list here, to the child
  show(updatedDisks: any[]) {
    this.clearForm();
  	this.datadisks.show();
    this.disksArray = updatedDisks;
  }

  clearForm() {
    this.diskName = '';
    this.diskSize = '';
    this.diskType = 'StandardSSD_LRS';
    this.diskCachingType = 'ReadOnly';
    this.notValidatedForm = true;
  }

  addDisk() {
    if (!this.diskNameExists()) {
      var disk = {diskName:this.diskName, diskSize:this.diskSize, diskType:this.diskType, diskCachingType:this.diskCachingType};
      this.disksArray.push(disk);
      this.disksChange.emit(this.disksArray);
      this.datadisks.hide();
    }
  }

  validateForm() {
    // regular expression for numbers only for disk size
    var patt = new RegExp('^[0-9]+$');
    var res = patt.test(this.diskSize);

    // disable add disk button if anything is empty or undefined
    if ((this.diskName != undefined && this.diskName != '') &&
    (this.diskSize != undefined && this.diskSize != '') &&
    (this.diskType != undefined && this.diskType != '') &&
    (this.diskCachingType != undefined && this.diskCachingType != '') &&
    (res == true)) {
      this.notValidatedForm = false;
    }
    else {
      this.notValidatedForm = true;
    }
  }

  diskNameExists() {
    var existingDisk = this.disksArray.find(d => d.diskName == this.diskName);
    if (existingDisk) {
      this.alertBroadcast.broadcastFail(`A disk with the name "${this.diskName}" already exists. Please enter a different name.`);
      return true;
    }
    return false;
  }
}
