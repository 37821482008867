import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { searchForValue, extractValueFromUrl } from 'src/utils/misc';
import { CloudType } from 'src/app/constants';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {
  @Output() onSearchVMs = new EventEmitter<any[]>();
  @Input() vmList: any[];
  @Input() cloudType: CloudType;

  // Unique id for search component to bind label to the correct input box
  searchComponentId: number = -1;

  searchText: string = "";
  searchType: string = "name";

  constructor() { 
    this.searchComponentId = Math.floor((Math.random() * 9999999) + 1);
  }

  ngOnInit() {
    // Emit original unfiltered list
    this.clear();
  }

  clear() {
    this.searchText = "";
    this.searchType = "name"
    this.onSearchVMs.emit(this.vmList);
  }

  onKeydown(event) {
    if (event.key === "Enter") {
      this.onSearchPressed();
    }
  }

  onSearchPressed() {
    if (this.vmList && this.searchText) {
      this.onSearchVMs.emit(this.getSearchResults());
    } else {
      this.onSearchVMs.emit(this.vmList);
    }
  }

  getSearchResults(): any[] {
    switch (this.searchType) {
      case "name":
        return this.searchName(this.searchText.toLowerCase());
      case "instanceId":
        return this.searchInstanceId(this.searchText.toLowerCase());
      case "imageType":
        return this.searchImageType(this.searchText.toLowerCase());
      case "osType":
        return this.searchOSType(this.searchText.toLowerCase());
      case "resourceGroup":
        return this.searchResourceGroup(this.searchText.toLowerCase());
      case "vmSize":
        return this.searchVMSize(this.searchText.toLowerCase());
    }
    return [];
  }

  searchName(name: string) {
    return this.vmList.filter(vm => {
      var prop = "";
      if (this.cloudType == CloudType.AZURE) {
        prop = vm.name;
      } else if (this.cloudType == CloudType.AWS) {
        if (vm.Tags) {
          var nameTag = vm.Tags.find(t => t.Key == "Name");
          if (nameTag)
          prop = nameTag.Value;
        }
      }
      return prop.toLowerCase().indexOf(name) != -1;
    })
  }

  // Note: AWS Only
  searchInstanceId(instanceId: string) {
    return this.vmList.filter(vm => {
      return vm.InstanceId.toLowerCase().indexOf(instanceId) != -1;
    });
  }

  searchImageType(imageType: string) {
    return this.vmList.filter(vm => {
      var prop = "";
      if (this.cloudType == CloudType.AZURE) {
        prop = searchForValue(vm, ["properties", "storageProfile", "imageReference", "sku"]);
      } else if (this.cloudType == CloudType.AWS) {
        prop = vm.ImageId;
      }
      return prop.toLowerCase().indexOf(imageType) != -1;
    })
  }

  searchOSType(osType: string) {
    return this.vmList.filter(vm => {
      var prop = "";
      if (this.cloudType == CloudType.AZURE) {
        prop = searchForValue(vm, ["properties", "storageProfile", "osDisk", "osType"]);
      } else if (this.cloudType == CloudType.AWS) {
        prop = vm.Architecture;
      }
      return prop.toLowerCase().indexOf(osType) != -1;
    })
  }

  searchResourceGroup(resourceGroup: string) {
    return this.vmList.filter(vm => {
      var prop = "";
      if (this.cloudType == CloudType.AZURE) {
        prop = extractValueFromUrl(vm["id"], "resourceGroups");
      }
      return prop.toLowerCase().indexOf(resourceGroup) != -1;
    })
  }

  searchVMSize(vmSize: string) {
    return this.vmList.filter(vm => {
      var prop = "";
      if (this.cloudType == CloudType.AZURE) {
        prop = searchForValue(vm, ["properties", "hardwareProfile", "vmSize"]);
      } else if (this.cloudType == CloudType.AWS) {
        prop = vm.InstanceType;
      }
      return prop.toLowerCase().indexOf(vmSize) != -1;
    })
  }
}
