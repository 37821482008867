/**
 * The alert dialog has serval different flavors:
 * Success, Info, Warning, Danger, or Confirm. Each
 * flavor contains a message and a possible action to
 * take when the user presses "OK".
 */

import { Component, ViewChild } from '@angular/core';

import { AlertEvent, AlertType } from 'src/app/common-components/alert-modal/alert-broadcast.service';

@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.css'],
  exportAs: 'alertChild'
})
export class AlertModalComponent {
  @ViewChild('alertSuccess') public alertSuccess;
  @ViewChild('alertFail') public alertFail;
  @ViewChild('alertInfo') public alertInfo;
  @ViewChild('alertWarning') public alertWarning;
  @ViewChild('alertConfirm') public alertConfirm;

  message: string = null;
  list: string[] = null;
  action: any = null;
  dialog: any = null;
  keepSnapshots: boolean = false;
  event: any;

  constructor() { }

  show(event: AlertEvent) {
    switch (event.alertType) {
      case AlertType.SUCCESS:
        this.success(event.message, event.list, event.onClose);
        break;

      case AlertType.FAIL:
        this.fail(event.message, event.list, event.onClose);
        break;

      case AlertType.WARNING:
        this.warning(event.message, event.list, event.onClose);
        break;

      case AlertType.CONFIRM:
        this.confirm(event.message, event.list, event.onClose);
        break;

      case AlertType.INFO:
      default:
        this.info(event.message, event.list, event.onClose);
        break;      
    }
  }

  success(message: string, list: string[], onClose: any = null) {
    this.showDialog(this.alertSuccess, list, message, onClose);
  }

  fail(message: string, list: string[], onClose: any = null) {
    this.showDialog(this.alertFail, list, message, onClose);
  }

  info(message: string, list: string[], onClose: any = null) {
    this.showDialog(this.alertInfo, list, message, onClose);
  }

  warning(message: string, list: string[], onClose: any = null) {
    this.showDialog(this.alertWarning, list, message, onClose);
  }

  confirm(message: string, list: string[], onClose: any = null) {
    this.showDialog(this.alertConfirm, list, message, onClose);
  }

  private showDialog(dialog: any, list: string[], message: string, onClose: any) {
    // Make sure old dialog is closed
    if (this.dialog)
      this.dialog.hide();
    
    this.dialog = dialog;
    this.action = onClose;
    this.message = message;
    this.list = list;

    this.dialog.show();
  }

  close() {
    if (this.dialog) {
      this.dialog.hide();
      this.dialog = null;
    }

    // reset the retain snapshots checkbox if it was checked
    if (this.event != null){
      this.keepSnapshots = (this.event.target.checked ? true:false);
      this.event.target.checked = false
    }

    //special case if we want to keep old snaps when restoring
    if (this.action && this.keepSnapshots == true){
      this.action(this.keepSnapshots);
    }
    
    else if (this.action)
      this.action();
  }

  changed(evt){
    this.event = evt
    //grab the event when the keep snaps checkbox is toggled
  }
}
