import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';
import { CloudType } from "src/app/constants";
import { ScheduleOpenDialogEvent } from 'src/app/events';
import { SubscriptionMapService } from 'src/app/subscription-map/subscription-map.service';

@Component({
  selector: 'app-deprec-sched-grid-item',
  templateUrl: './deprec-sched-grid-item.component.html',
  styleUrls: ['./deprec-sched-grid-item.component.less']
})
export class DeprecSchedGridItemComponent implements OnInit, OnChanges {

  @Input() scheduleInfo: any;
  @Output() openDialogSchedules = new EventEmitter<ScheduleOpenDialogEvent>();

  scheduleID: string;
  vmId: string;
  cloudType: CloudType;
  vmDetails: any;
  subscriptionId: string;
  statusColor: string;

  constructor(private subMap: SubscriptionMapService) {
    this.statusColor = "darkred";
  }

  ngOnInit() {
    this.initializeScheduleGrid();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.scheduleInfo) {
      this.initializeScheduleGrid();
    }
  }

  viewDetails() {
    this.openDialogSchedules.emit(new ScheduleOpenDialogEvent(this.scheduleID, this.vmId, this.cloudType, this.vmDetails));
  }

  initializeScheduleGrid() {
    if (this.scheduleInfo) {
      this.scheduleID = this.scheduleInfo._id;
      this.vmId = this.scheduleInfo.instanceId;
      this.cloudType = this.scheduleInfo.cloudType;
      this.vmDetails = this.scheduleInfo.vmDetails;
      if (this.cloudType == CloudType.AZURE) {
        this.subscriptionId = this.vmDetails.subscriptionId;
      } else if (this.cloudType == CloudType.AWS) {
        this.subscriptionId = this.vmDetails.accountId;
      }
    }
  }
  getSubscriptionName(): string {
    if (this.subscriptionId) {
      return this.subMap.getSubscriptionName(this.subscriptionId);
    }
    return 'Unknown Subscription';
  }
}
