import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { AzureApiService } from 'src/app/azure/azure-api/azure-api.service';
import { AwsApiService } from 'src/app/aws/aws-api/aws-api.service';
import { VMSecurityGroupsTabInfo} from 'src/app/common-components/tabs/TabInfo';
import { searchForValue } from 'src/utils/misc';
import { CloudType } from 'src/app/constants';

@Component({
  selector: 'app-security-groups-tab',
  templateUrl: './security-groups-tab.component.html',
  styleUrls: ['./security-groups-tab.component.css']
})
export class SecurityGroupsTabComponent implements OnChanges {
  @Input() tabInfo: VMSecurityGroupsTabInfo;

  isLoading: boolean = true;
  securityGroups: object[] = [];
  cloudType: CloudType;

  constructor(
    private azureAPI: AzureApiService,
    private awsAPI: AwsApiService) { }

  ngOnChanges(changes: SimpleChanges) {
    this.securityGroups = [];
    if (this.tabInfo.cloudType == CloudType.AZURE) {
      this.getAzureSecurityGroups(this.tabInfo.securityGroups);
    } else if (this.tabInfo.cloudType == CloudType.AWS) {
      this.getAWSSecurityGroups(this.tabInfo.securityGroups);
    }
  }
  /************** AZURE **************/
    // 12-30-2024: need to use new API call to retrieve NSGs from a subnet association
    //             current API call has been changed, effectively breaking this.
  async getAzureSecurityGroups(nicList: string[]) {
    try {
      this.isLoading = true;
      var promises = nicList.map(nicUrl => this.getAzuzePropertyForUrl(nicUrl));
      var formattedSGs = await Promise.all(promises);
      // console.log("formattedSGs:\n", formattedSGs);
      this.securityGroups = formattedSGs.filter(sg => sg != null);
    }
    catch (e) { console.error(e) }
    finally { this.isLoading = false; }
  }

  // retain legacy code for now - works correctly if theres a valid NSG
  // directly applied to the NIC
  getAzuzePropertyForUrlLegacy(nicUrl) {
    return new Promise((resolve, reject) => {
      this.azureAPI.getPropertyForIdUrl(nicUrl, "2018-08-01", "networkSecurityGroup")
        .subscribe(networkDetails => {
          resolve(this.formatAzureSecurtiyGroupLegacy(networkDetails))
        })
    })
  }
  
  formatAzureSecurtiyGroupLegacy(networkDetails) {
    var rawSecurityGroup = searchForValue(networkDetails, ["properties", "networkSecurityGroup"]);
    if (rawSecurityGroup) {
      var rawSecurityRules = searchForValue(rawSecurityGroup, ["properties", "defaultSecurityRules"]);
      var securityRules = rawSecurityRules.map(rule => {
        return {
          name: rule.name,
          direction: this.getAzureSecurityGroupProperty(rule, ["properties", "direction"]),
          priority: this.getAzureSecurityGroupProperty(rule, ["properties", "priority"]),
          protocol: this.getAzureSecurityGroupProperty(rule, ["properties", "protocol"]),
          source: this.getAzureSecurityGroupProperty(rule, ["properties", "sourceAddressPrefix"]),
          sourcePortRange: this.getAzureSecurityGroupProperty(rule, ["properties", "sourcePortRange"]),
          destination: this.getAzureSecurityGroupProperty(rule, ["properties", "destinationAddressPrefix"]),
          destinationPortRange: this.getAzureSecurityGroupProperty(rule, ["properties", "destinationPortRange"]),
          action: this.getAzureSecurityGroupProperty(rule, ["properties", "access"])
        }
      })
      return {
        name: rawSecurityGroup.name,
        rules: securityRules
      }
    }
    
    return null;
  }
  
  async getAzuzePropertyForUrl(nicUrl) {
    // verify if we can use old logic first
    const originalAPI = await this.azureAPI.getPropertyForIdUrl(nicUrl, "2018-08-01", "networkSecurityGroup").toPromise();
    const field = searchForValue(originalAPI, ["properties", "networkSecurityGroup"]);
    if (field != undefined || field != null) {
      return this.getAzuzePropertyForUrlLegacy(nicUrl);
    } else {
      try {
        const networkDetails = await this.azureAPI.listEffectiveNSGs(nicUrl).toPromise() as HttpResponse<any>;
        const location = networkDetails.headers.get('Location');
        const retry_after = networkDetails.headers.get('Retry-After');
        const status = networkDetails.status;
        if (location && retry_after && status === 202) {
            await this.delay(parseInt(retry_after) * 1000);
            const nsgDetails = await this.azureAPI.accessSubnetNSG(location).toPromise();
            return this.formatAzureSecurtiyGroup(nsgDetails);
        }
      } catch (error) {
        console.error('Error running ListEffectiveNSGs - user does not have permission to view NSG from this VM');
        return null;
      }
      return null;
    }
  }

  formatAzureSecurtiyGroup(networkDetails) {
    // Check if the networkDetails JSON response has the specific property
    var rawSecurityGroup = searchForValue(networkDetails, ["body", "value", "0", "networkSecurityGroup"]);
    // console.log("rawSecurityGroup:\n", JSON.stringify(rawSecurityGroup, null, 2));
    if (rawSecurityGroup) {
      var rawSecurityRules = searchForValue(networkDetails, ["body", "value", "0", "effectiveSecurityRules"]);
      // console.log("rawSecurityRules:\n", JSON.stringify(rawSecurityRules, null, 2));
      var securityRules = rawSecurityRules.map(rule => {
        // console.log("rule type:\n", typeof rule);
        return {
          name: rule.name,
          direction: rule.direction,
          priority: rule.priority,
          protocol: rule.protocol,
          source: rule.sourceAddressPrefix,
          sourcePortRange: rule.sourcePortRange,
          destination: rule.destinationAddressPrefix,
          destinationPortRange: rule.destinationPortRange,
          action: rule.access
        }
      });
      var nsgName = rawSecurityGroup.id.split("/")[rawSecurityGroup.id.split("/").length - 1];
      return {
        name: nsgName,
        rules: securityRules
      }
    }
    
    return null;
  }

  // delays need to be manually defined here for the
  // nested API call 
  private delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  
  getAzureSecurityGroupProperty(rule: object, properties: string[]) {
    var value = searchForValue(rule, properties);
    return value == "*" ? "Any" : value;
  }

  
  /************** AWS **************/
  async getAWSSecurityGroups(securityGroupsIds: string[]) {
    try {
      this.isLoading = true;
      var rawSecurityGroups = await this.awsAPI.getSecurityGroups(this.tabInfo.awsAccountID, securityGroupsIds);
      if (rawSecurityGroups) {
        var formattedSecurityGroups = rawSecurityGroups.map(sg => this.formatAWSecurityGroup(sg));
        this.securityGroups = formattedSecurityGroups.filter(sg => sg != null);
      }
    }
    catch (e) { console.error(e) }
    finally { this.isLoading = false; }
  }

  formatAWSecurityGroup(rawSecurityGroup: object) {
    if (rawSecurityGroup) {
      var rules = [];

      this.formatIPPermissions(rawSecurityGroup["IpPermissions"], "Inbound", rules);
      this.formatIPPermissions(rawSecurityGroup["IpPermissionsEgress"], "Outbound", rules);

      return {
        name: rawSecurityGroup["Description"],
        rules: rules
      }
    }
    return null;
  }

  formatIPPermissions(ipPermissions: object[], direction: string, rules: object[]) { 
    var number = 0;   
    ipPermissions.forEach(permission => {
        permission["IpRanges"].forEach(range => {
          number++;
          var label = `${direction} ${number}`
          rules.push(this.formatIPPermissionsForRange(range, permission, "CidrIp", label));
        });

        permission["Ipv6Ranges"].forEach(range => {
          number++;
          var label = `${direction} ${number}`
          rules.push(this.formatIPPermissionsForRange(range, permission, "CidrIpv6", label));
        });
    })
  }

  formatIPPermissionsForRange(ipRange: object, permission: object, ipType: string, label: string) {
    return {
      name: label,
      protocol: permission["IpProtocol"] != -1 ? permission["IpProtocol"] : "Any",
      portRange: this.getAWSPortRange(permission["FromPort"], permission["ToPort"]),
      source: ipRange[ipType],
      description: ipRange["Description"]
    };
  }

  getAWSPortRange(fromPort: string, toPort: string) {
    if (!toPort && !fromPort) return "Any"
    if (!toPort && fromPort) return fromPort;
    if (toPort && !fromPort) return toPort;
    if (toPort == fromPort) return toPort;    
    return `${fromPort} - ${toPort}`    
  }
}
