import { Component, ViewChild, Input, EventEmitter } from '@angular/core';

import { AzureApiService } from 'src/app/azure/azure-api/azure-api.service';
import { AwsApiService } from 'src/app/aws/aws-api/aws-api.service';
import { StargateApiService } from 'src/app/stargate-api/stargate-api.service';
import { AlertBroadcastService } from 'src/app/common-components/alert-modal/alert-broadcast.service';
import { VMStatusUpdateEvent, AzureRequestMonitorStatusEvent, AWSRequestMonitorStatusEvent } from 'src/app/events';
import { VMDetailsBase, VMDetailsAzure, VMDetailsAWS } from 'src/app/vm-details/details-modal/data-models';
import { searchForValue } from 'src/utils/misc';
import { monitorAWSStatusChange, stopAWSVMAndPerformAction } from 'src/app/aws/aws-mic/aws-utils';
import { CloudType, AWSStatusCodes, VMState } from 'src/app/constants';
import { VMStatus } from 'src/app/dashboard/VMStatus';

@Component({
  selector: 'app-vm-resize-modal',
  templateUrl: './vm-resize-modal.component.html',
  styleUrls: ['./vm-resize-modal.component.css'],
  exportAs: 'child'
})
export class VmResizeModalComponent {
  @Input() onStatusChanged: EventEmitter<VMStatusUpdateEvent>;
  @Input() requestMonitorStatusChange: EventEmitter<VMStatusUpdateEvent>; 
  isLoading: boolean = false;
  resizing: boolean = false;

  vmName: string;
  subscriptionID: string;
  resourceGroup: string;
  initialVMSize: string;
  location: string;
  availabilityZone: string;
  vmID: string;
  awsAccountID: string;
  awsVMSizes: any;
  vmState: VMState;

  cloudType: CloudType;

  public vmList: any[] = [];
  public currentVMSize: string;
  public currentAttributes: object[];
  public resizeResp: any;

  // search html for 'basicModal' tag, and refer to it as contentModal in this file
  @ViewChild('basicModal') public contentModal;

  constructor(
    private azureService: AzureApiService, 
    private awsAPI: AwsApiService,
    private stargateAPI: StargateApiService,
    private alertBroadcast: AlertBroadcastService) { }

  // when this modal is brought up, we need to know the vmDetails, which contains all the details about a VM gathered in the parent modal
  show(vmDetails: VMDetailsBase) {
    this.cloudType = vmDetails.cloudType;
    this.vmState = vmDetails.vmState;
    if (vmDetails instanceof VMDetailsAzure)
      this.showAzureResizeModal(vmDetails as VMDetailsAzure)
    else if (vmDetails instanceof VMDetailsAWS)
      this.showAWSResizeModal(vmDetails as VMDetailsAWS)
  }

  // AWS and Azure need different flows, since different API calls are used and AWS requires stopping and starting, while Azure has that built-in
  showAzureResizeModal(vmDetails: VMDetailsAzure) {
    this.currentAttributes = null;
    this.currentVMSize = vmDetails.vmSize;
    this.initialVMSize = vmDetails.vmSize;
    this.vmID = vmDetails.vmID;
    this.vmName = vmDetails.vmName;
    this.subscriptionID = vmDetails.getSubscriptionID();
    this.resourceGroup = vmDetails.getResourceGroup();
    this.location = vmDetails.getLocaction();

    this.populateVMSizes("AZURE_RESIZE_INSTANCE_TYPES");
    this.contentModal.show(); 
  }

  showAWSResizeModal(vmDetails: VMDetailsAWS) {
    this.currentAttributes = null;
    this.awsAccountID = vmDetails["accountID"];
    this.currentVMSize = vmDetails["vmSize"];
    this.initialVMSize = vmDetails["vmSize"];
    this.vmID = vmDetails["vmID"];
    this.vmName = vmDetails["vmName"];
    this.availabilityZone = searchForValue(vmDetails, ["vmInfo", "vmDetails", "Placement", "AvailabilityZone"]);

    this.populateVMSizes("AWS_RESIZE_INSTANCE_TYPES");
    this.contentModal.show(); 
  }

  resizeVM() {
    this.alertBroadcast.broadcastConfirm(`Are you sure that you want to resize "${this.vmName}" to "${this.currentVMSize}"?${(this.vmState == VMState.RUNNING ? "  The VM will be rebooted." : "")}`, () => {
      if (this.cloudType == CloudType.AZURE)
        this.resizeAzureVM();
      else if (this.cloudType == CloudType.AWS)
        this.resizeAWSVM();
    });
  }

  resizeAzureVM() {
    this.isLoading = true;
    this.resizing = true;
    this.azureService.resizeVM(this.subscriptionID, this.resourceGroup, this.vmName, this.currentVMSize)
      .subscribe(resp => {
        this.resizeResp = resp;
        if (this.resizeResp.status == 200) {
          this.alertBroadcast.broadcastSuccess(`Success! ${this.vmName} has been resized to "${this.currentVMSize}".`, () => {
            this.contentModal.hide();
          })

          this.isLoading = false;
          this.resizing = false;

          // Emit resize event to update parent components
          this.emitOnAzureResizeEvent(resp);
        }
        else {
          this.alertBroadcast.broadcastFail(`Something went wrong. No action has been taken on the VM.`, () => {
            this.contentModal.hide();
          })          
        }
      });
  }

  // unlike Azure, we need asynchronous calls to stop a VM, resize, then start for AWS
  async resizeAWSVM() {
    this.isLoading = true;
    this.resizing = true;

    try {
      var resizeVMAction = async () => {
        // Resize
        await this.awsAPI.resizeVM(this.awsAccountID, this.vmID, this.currentVMSize);
        // Emit event to update parent components
        this.emitOnAWSResizeEvent();
      };
      
      await stopAWSVMAndPerformAction(this.awsAPI, this.awsAccountID, this.vmID, this.vmState, this.onStatusChanged, this.requestMonitorStatusChange, resizeVMAction);      

      this.alertBroadcast.broadcastSuccess(`Success! "${this.vmName}" has been resized to "${this.currentVMSize}".`, () => {
        this.contentModal.hide();
      })    
    }
    catch (err) {
      console.error(err);
    }
    finally {
      this.isLoading = false;
      this.resizing = false;
    }
  }
 
  updateNewVMSize(newVmSize: object) {
    this.currentVMSize = newVmSize["value"];
    this.currentAttributes = newVmSize["additionalAttributes"];
  }

  populateVMSizes(dropdownName: string) {
    this.vmList = [];
    this.isLoading = true;
    this.stargateAPI.getDropdowns(dropdownName, this.cloudType, this.awsAccountID)
      .subscribe(resp => {
        this.vmList = resp as Object[] || [];
        this.vmList = this.vmList.filter(vm => {
          return vm.value != this.initialVMSize;
        }, error => console.error(error))
        this.isLoading = false;
      });
  }

  emitOnAzureResizeEvent(resp: any) {
    var asyncUrl = resp.headers.get('Azure-AsyncOperation')
    var retrySeconds = resp.headers.get('Retry-After') || 5

    this.requestMonitorStatusChange.emit(new AzureRequestMonitorStatusEvent(
      VMStatus.init(this.vmID, VMState.PENDING, this.currentVMSize), 
      this.subscriptionID, 
      this.resourceGroup, 
      this.vmName,
      asyncUrl,
      retrySeconds));
  }

  emitOnAWSResizeEvent() {
    this.onStatusChanged.emit(new VMStatusUpdateEvent(VMStatus.init(this.vmID, this.vmState == VMState.STOPPED ? VMState.STOPPED : VMState.PENDING, this.currentVMSize)));
  }
}
