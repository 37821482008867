import { Component, ViewChild } from '@angular/core';

import { AzureApiService } from 'src/app/azure/azure-api/azure-api.service';
import { StargateApiService } from 'src/app/stargate-api/stargate-api.service';
import { AlertBroadcastService, AlertType } from 'src/app/common-components/alert-modal/alert-broadcast.service';
import { userCanCreateVM } from 'src/app/azure/azure-misc/azure-utils';
import { VMConfig } from 'src/app/dashboard/vm-create-modal/VMConfig';
import { downloadJSONFile, importJSONFile } from "src/utils/misc";
import { CloudType } from 'src/app/constants';

@Component({
  selector: 'app-vm-create-modal',
  templateUrl: './vm-create-modal.component.html',
  styleUrls: ['./vm-create-modal.component.css'],
  exportAs: 'vmCreateChild'
})
export class VmCreateModalComponent {
  vmConfig: VMConfig = new VMConfig(null);
  
  vnetList: any[] = [];
  subnetList: any[] = [];
  rgList: any[] = [];
  vnetRgList: any[] = [];
  instanceTypeList: any[];
  osSkuList: any[];

  isLoading: boolean = false;
  notValidatedForm: boolean = true;
  newResourceGroup: boolean = false;
  showRGFieldExisting: boolean = true;
  showRGFieldAuto: boolean = false;
  showRGFieldFreeForm: boolean = false;

  allowCreateVM: boolean = false;

  @ViewChild('vmCreate') public vmCreate;

  // search html for datadisks tag, show data-disks modal when appropriate
  @ViewChild('datadisks') public datadisks;

  constructor(
    private azureService: AzureApiService,
    private stargateAPI: StargateApiService,
    private alertBroadcast: AlertBroadcastService) { }

  show(subscriptions: object[]) {
    this.vmConfig = new VMConfig(subscriptions);

    userCanCreateVM(this.stargateAPI).then(allow => this.allowCreateVM = allow);
    this.showRGField();

    this.vmCreate.show();
    this.listResourceGroups();
    this.vnetListResourceGroups();
    this.listVirtualNetworks();
    this.listDropdowns();
  }

  createResourcesConfirm() {
    if (this.allowCreateVM) {
      this.alertBroadcast.broadcastConfirm(`Are you sure that you want to create VM ${this.vmConfig.generatedVMName} in Resource Group ${this.vmConfig.vmResourceGroup}?`, () => {
        this.createResources();
      });
    }
  }

  downloadFile() {
    downloadJSONFile(this.vmConfig.exportConfig(), `${this.vmConfig.clientDefinedName}.json`);
  }

  async onFileImport(event: any) {
    try {
      var file = await importJSONFile(event);
      
      this.vmConfig.loadFromConfigFile(file);

      this.showRGField(false);

      await this.listVirtualNetworks(false);
      this.vmConfig.virtualNetwork = file["virtualNetwork"];

      await this.listSubnets();
      this.vmConfig.subnet = file["subnet"];

      // Clear value
      document.getElementById("fileImport")["value"] = null;
      
    } catch (e) {
      this.alertBroadcast.broadcastFail(e.message);
    }    
  }

  showInvalidField() {
    if (this.vmConfig.passwordValidated == false) {
      document.getElementsByName("password")[0].style.border = "2px solid red";
    }
    else if (this.vmConfig.passwordValidated == true) {
      document.getElementsByName("password")[0].style.border = "1px solid #ced4da";
    }
  }

  addDataDisk() {
    this.datadisks.show(this.vmConfig.dataDisksArray);
  }

  showDisks(disksArray) {
    this.vmConfig.dataDisksArray = disksArray;
  }

  removeDisk(disk) {
    this.vmConfig.dataDisksArray = this.vmConfig.dataDisksArray.filter(obj => obj !== disk);
  }

  // asynchronous function to create new RG if necessary, then nic, then data disks if necessary, then a VM last
  async createResources() {
    this.isLoading = true;
    this.vmConfig.generateVmName();
    var createdResources: string[] = [];

    try {
      if (this.newResourceGroup == true) {
        var createResourceGroupResponse = await this.createResourceGroup();
        if (createResourceGroupResponse['status'] == 201) {
          createdResources.push(`Resource Group: ${this.vmConfig.vmResourceGroup}`);
        }
      }
      var createNicResponse = await this.createNic();
      if (createNicResponse['status'] == 201) {
        createdResources.push(`Network Interface: ${this.vmConfig.nicName}`);
      }

      if(this.vmConfig.dataDisksArray.length > 0) {
        for (let disk of this.vmConfig.dataDisksArray) {
          disk["fullName"] = `${this.getVMName()}-${disk["diskName"]}`;
          var createDiskResponse = await this.createDataDisk(disk);
          if (createDiskResponse['status'] == 202) {
            createdResources.push(`Disk: ${disk["fullName"]}`);
          }
        }
      }

      var createVMResponse = await this.createVM();
      if (createVMResponse['status'] == 201) {
        createdResources.push(`Virtual Machine: ${this.vmConfig.generatedVMName}`);

        this.alertBroadcast.broadcastWithList(`Success! VM ${this.vmConfig.generatedVMName} has been created in Resource Group ${this.vmConfig.vmResourceGroup}. If you added a data disk, please wait a couple minutes for it to attach. The following resources were created:`, createdResources, AlertType.SUCCESS);
        this.validateForm();
      }
      else {
        this.alertBroadcast.broadcastWithList(`Something went wrong. The following resources were created:`, createdResources, AlertType.FAIL);
      }
    }
    catch (err) {
      console.error(err);
      this.alertBroadcast.broadcastWithList(`Something went wrong: ${err.error.error.message}. The following resources were created:`, createdResources, AlertType.FAIL);
    }
    finally {
      this.isLoading = false;
    }
  }

  createDataDisk(disk: any) {
    return this.azureService.createDataDisk(this.vmConfig.selectedSubscriptionID, this.vmConfig.vmResourceGroup, disk["fullName"], this.vmConfig.region, disk["diskSize"]).toPromise();
  }

  createResourceGroup() {
    return this.azureService.createResourceGroup(this.vmConfig.selectedSubscriptionID, this.vmConfig.vmResourceGroup, this.vmConfig.region).toPromise();
  }

  createNic() {
    this.vmConfig.nicName = `${this.getVMName()}-nic`    
    return this.azureService.createNic(this.vmConfig.selectedSubscriptionID, this.vmConfig.vmResourceGroup, this.vmConfig.nicName, this.vmConfig.virtualNetwork, this.vmConfig.subnet, this.vmConfig.region, this.vmConfig.vnetResourceGroup).toPromise();
  }

  createVM() {
    return this.azureService.createVM(this.vmConfig).toPromise();
  }

  getVMName() {
    return this.vmConfig.generatedVMName;
  }

  // retrieve vNets associated with selected vNet RG, then validate the form if empty or list subnets if not
  async listVirtualNetworks(loadSubnet: boolean = true) {
    var resp = await this.azureService.listVirtualNetworks(this.vmConfig.selectedSubscriptionID, this.vmConfig.vnetResourceGroup, this.vmConfig.region).toPromise();

    if (resp["value"])
      this.vnetList = resp["value"].map(v => v.name);

    if ((this.vnetList.length == 0)) {
      this.vmConfig.virtualNetwork = '';
      this.vmConfig.subnet = '';
      //this.validateForm();
    } else if (loadSubnet) {
      this.vmConfig.virtualNetwork = this.vnetList[0];
      await this.listSubnets();
    }
  }

  async listSubnets() {
    if (this.vmConfig.vnetResourceGroup == '' || this.vmConfig.virtualNetwork == '') { return; }
    var resp = await this.azureService.listSubnets(this.vmConfig.selectedSubscriptionID, this.vmConfig.vnetResourceGroup, this.vmConfig.virtualNetwork).toPromise();

    if (resp["value"])
      this.subnetList = resp["value"].map(v => v.name);

    if (this.subnetList && this.subnetList.length > 0)
      this.vmConfig.subnet = this.subnetList[0];
   // this.validateForm();
  }

  listResourceGroups() {
    return this.azureService.listResourceGroups(this.vmConfig.selectedSubscriptionID)
      .subscribe(resp => {
        this.rgList = resp["value"];
      });
  }

  // separate list needed to differentiate between vNet RGs and VM RGs
  vnetListResourceGroups() {
    return this.azureService.listResourceGroups(this.vmConfig.selectedSubscriptionID)
      .subscribe(resp => {
        this.vnetRgList = resp["value"];
      });
  }

  validateForm() {
    this.notValidatedForm = !this.vmConfig.isValid();
  }

  listDropdowns() {
    this.stargateAPI.getDropdowns("AZURE_CREATE_VM_INSTANCE_TYPES", CloudType.AZURE)
        .subscribe(dropDowns => this.instanceTypeList = dropDowns as object[]);

    this.stargateAPI.getDropdowns("AZURE_CREATE_VM_OS_SKUS", CloudType.AZURE)
        .subscribe(dropDowns => this.osSkuList = dropDowns as object[]);
  }

  // handles which form field to show for 'VM Resource Group' field
  showRGField(clearField: boolean = true) {
    if (this.vmConfig.rgField == 'Existing') {
      if (clearField)
        this.vmConfig.vmResourceGroup = '';
      this.listResourceGroups();
      this.showRGFieldExisting = true;
      this.showRGFieldAuto = false;
      this.showRGFieldFreeForm = false;
      this.newResourceGroup = false;
    }
    else if (this.vmConfig.rgField == 'Auto') {
      this.vmConfig.generateRGName();
      this.showRGFieldExisting = false;
      this.showRGFieldAuto = true;
      this.showRGFieldFreeForm = false;
      this.newResourceGroup = true;
    }
    else if (this.vmConfig.rgField == 'Free Form') {
      if (clearField)
        this.vmConfig.vmResourceGroup = '';
      this.showRGFieldExisting = false;
      this.showRGFieldAuto = false;
      this.showRGFieldFreeForm = true;
      this.newResourceGroup = true;
    }
    this.validateForm();
  }
}

