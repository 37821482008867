import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { NgModule, NO_ERRORS_SCHEMA  } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { NgxLoadingModule, ngxLoadingAnimationTypes  } from 'ngx-loading';

import { AppComponent } from './app.component';
import { DashboardPageComponent } from './dashboard/dashboard-page/dashboard-page.component';
import { AppRoutingModule } from './app-routing/app-routing.module';
import { AzureAuthComponent } from './azure/azure-auth/azure-auth.component';

import { DashboardTabComponent } from './dashboard/dashboard-tab/dashboard-tab.component';
import { VmGridItemComponent } from './dashboard/vm-grid-item/vm-grid-item.component';
import { DetailsModalComponent } from './vm-details/details-modal/details-modal.component';
import { NetworkInfoTabComponent } from './vm-details/network-info-tab/network-info-tab.component';
import { VmInfoTabComponent } from './vm-details/vm-info-tab/vm-info-tab.component';
import { TabComponent } from './common-components/tabs/tab/tab.component';
import { TabGroupComponent } from './common-components/tabs/tab-group/tab-group.component';
import { CollapsibleDivComponent } from './common-components/collapsible-div/collapsible-div.component'
import { VmResizeModalComponent } from './dashboard/vm-resize-modal/vm-resize-modal.component';
import { AlertModalComponent } from './common-components/alert-modal/alert-modal.component';
import { VmCreateModalComponent } from './dashboard/vm-create-modal/vm-create-modal.component';
import { VmSchedulerModalComponent } from './dashboard/vm-scheduler-modal/vm-scheduler-modal.component';
import { TimePickerComponent } from './common-components/time-picker/time-picker.component';
import { AddScheduleComponent } from './dashboard/vm-scheduler-modal/add-schedule/add-schedule.component';
import { VmCreateDataDisksComponent } from './dashboard/vm-create-data-disks/vm-create-data-disks.component';
import { SecurityGroupsTabComponent } from './vm-details/security-groups-tab/security-groups-tab.component';
import { RolePickerModalComponent } from './dashboard/role-picker-modal/role-picker-modal.component';
import { SnapshotsComponent } from './dashboard/snapshots/snapshots.component';
import { VmHealthStatusComponent } from './dashboard/vm-health-status/vm-health-status.component';
import { SearchComponent } from './dashboard/search/search.component';
import { ViewSnapshotsComponent } from './dashboard/snapshots/view-snapshots/view-snapshots.component';
import { CreateSnapshotComponent } from './dashboard/snapshots/create-snapshot/create-snapshot.component';
import { DeprecSchedGridItemComponent } from './dashboard/deprec-sched-grid-item/deprec-sched-grid-item.component';
import { SearchSchedComponent } from './dashboard/search-sched/search-sched.component';
import { SchedDetailsModalComponent } from './sched-details/sched-details-modal/sched-details-modal.component';
import { SchedInfoTabComponent } from './sched-details/sched-info-tab/sched-info-tab.component';

@NgModule({
  declarations: [
    AppComponent,
    DashboardPageComponent,
    AzureAuthComponent,
    DashboardTabComponent,
    VmGridItemComponent,
    DetailsModalComponent,
    NetworkInfoTabComponent,
    VmInfoTabComponent,
    TabComponent,
    TabGroupComponent,
    CollapsibleDivComponent,
    VmResizeModalComponent,
    AlertModalComponent,
    VmCreateModalComponent,  
    VmSchedulerModalComponent,
    TimePickerComponent,
    AddScheduleComponent,
    VmCreateDataDisksComponent,
    SecurityGroupsTabComponent,
    RolePickerModalComponent,
    SnapshotsComponent,
    VmHealthStatusComponent,
    SearchComponent,
    ViewSnapshotsComponent,
    CreateSnapshotComponent,
    DeprecSchedGridItemComponent,
    SearchSchedComponent,
    SchedDetailsModalComponent,
    SchedInfoTabComponent  
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    MDBBootstrapModule.forRoot(),
    NgxLoadingModule.forRoot({
        animationType: ngxLoadingAnimationTypes.circleSwish,
        primaryColour: '#0d47a1',
        backdropBackgroundColour: 'rgba(0,0,0,0)'
    })
  ],
  schemas: [ NO_ERRORS_SCHEMA ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
